import React, { useEffect, useState } from "react";
import TextInput from "../components/TextInput";
import ResetPasswordThanks from "./ResetPasswordThanks";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordSendLink } from "../redux/apiCalls";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { resetPasswordError } from "../redux/slices/ResetPassword";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../constants/routeConstants/Routes";

const ResetPassword = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pending = useSelector((state) => state.resetPassword.pending);
  const Error = useSelector((state) => state.resetPassword.error);
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const role = user?.data?.result?.role;
    const token = user?.data?.result?.access_token;
    if (user && token && role === "employer") {
      navigate(appRoutes.EmployerDashboard);
    }
    if (user && token && role === "employee") {
      navigate(appRoutes.EmployeeDashboard);
    }
  });

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  useEffect(() => {
    if (submitting) {
      if (!email) {
        setError(true);

        setEmailErr({ err: true, message: t("EmailErr") });
      } else {
        setError(false);
        setEmailErr();
      }
      if (email) {
        const res = validateEmail(email);
        if (!res) {
          setError(true);
          setEmailErr({ err: true, message: t("ValidEmailErr") });
        } else {
          setError(false);
          setEmailErr();
        }
      }
    }
  }, [email, submitting, t, Error]);

  const sendLink = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (!email) {
      setError(true);
      setEmailErr({ err: true, message: t("EmailErr") });
    } else {
      setError(false);
      setEmailErr();
    }
    if (email) {
      const res = validateEmail(email);
      if (!res) {
        setError(true);
        setEmailErr({ err: true, message: t("ValidEmailErr") });
      } else {
        const language_selected = Cookies.get("i18next");
        setError(false);
        setEmailErr();
        resetPasswordSendLink(email, language_selected, dispatch, setSubmitted);
      }
    }
  };

  useEffect(() => {
    if (!!Error) {
      MySwal.fire({
        title: <span className="capitalize">{Error?.email[0]}</span>,
        icon: "error",
        buttonsStyling: { backgroundColor: "#f44336" },
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetPasswordError(false));
        }
      });
    }
  }, [Error, MySwal, dispatch, t]);
  return (
    <>
      {submitted ? (
        <ResetPasswordThanks />
      ) : (
        <div className="w-full flex justify-center items-center h-screen bg-signUpBG">
          <form
            onSubmit={(e) => sendLink(e)}
            className="w-full flex flex-col justify-center items-center"
          >
            <div className="flex items-center justify-center w-1/3 sm_ss:w-full sm_s:w-full sm_s:text-center sm_s:px-6 sm:w-full md:w-1/3 ">
              <p className="text-sectext text tracking-wider">
                {t("PasswordResetText")}
              </p>
            </div>

            <div className="flex flex-col p-9 justify-center items-center sm_ss:w-full sm_s:w-full sm_s:bg-transparent bg-white rounded-lg smx:w-7/12 mt-5 sm:w-1/3 md:w-1/2 lg:w-1/2 xl:w-1/3 2xl:w-1/4">
              <div className="flex flex-col smx:w-full w-full relative">
                <TextInput
                  text={t("email")}
                  textType={"text"}
                  placeholder={t("Email")}
                  value={email}
                  handleChange={(e) => setEmail(e.target.value)}
                  id="email"
                  required={true}
                />
                {emailErr?.err && (
                  <span className="text-red-600 text-sm absolute bottom-1">
                    {emailErr?.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col items-center  w-full">
                <div className="flex items-center sm_ss:flex-start sm_s:flex-start w-full ">
                  <button
                    disabled={error}
                    type="submit"
                    className="bg-blue disabled:bg-gray disabled:cursor-not-allowed text-white font-bold text-sm h-10 w-36 px-2 mt-5 sm_ss:mt-0 sm_s:mt-0 rounded-md"
                  >
                    {pending ? <Loader /> : t("SendLink")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
