import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import TextInput from "../components/TextInput";
import Select from "react-select";
import { cardTypes } from "../data";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Canton,
  CheckUserSession,
  Countries,
  employerProfileUpdate,
  fetchUserProfile,
} from "../redux/apiCalls";
import Container from "../components/Container";
import ItemContainer from "../components/ItemContainer";
import SelectInput from "../components/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../components/BackButton";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../constants/routeConstants/Routes";
import { employerProfileNotUpdateReset } from "../redux/slices/EmployerProfileUpdate";
import Loader from "../components/Loader";
import DatePickerComp from "../components/DatePicker";
import AddChild from "../components/AddChild";
import { useTranslation } from "react-i18next";
import { EmployerAboutErrCheck } from "../utils.js/utils";
import { NextPageFormValidations } from "../utils.js/FormValidations";
import Cookies from "js-cookie";
import { titleOptions, titleOptionsfr } from "../constants/Common/Common";
import ImageModal from "../components/ImageModal";
import "react-phone-input-2/lib/style.css";
import MobilePhoneInput from "../components/MobilePhoneInput";

const EmployerSignUp = () => {
  const date = new Date();
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pending = useSelector((state) => state.employerProfileUpdate.pending);
  const success = useSelector((state) => state.employerProfileUpdate.success);
  const is_new_request_created = useSelector(
    (state) => state.employerProfileUpdate.is_new_request_created
  );
  const isAuthenticated = useSelector(
    (state) => state.userSession.isAuthenticated
  );
  const AuthError = useSelector((state) => state.userSession.error);
  const serverError = useSelector((state) => state.employerProfileUpdate.error);
  const userProfile = useSelector((state) => state.fetchUserProfile.userInfo);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [Nationality, SetNationality] = useState([]);
  const [addChildren, setAddChildren] = useState(0);
  const [showNextForm, setShowNextForm] = useState(false);
  const [title, setTitle] = useState("");
  const [countries, setCountries] = useState([]);
  const [legitimation_card_images, setLegitimation_card_images] = useState([]);
  const [legitimation_card, setLegitimation_card_type] = useState("");
  const [cantons, setCanton] = useState([]);
  const [cantonsData, setCantonData] = useState();
  const [blockedCountries, setBlocketCountries] = useState([]);
  const [blocked_countries, setblocked_countries] = useState([]);
  const [images, setImages] = useState([]);
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(false);
  const [child, setChild] = useState({
    child1: "",
    child2: "",
    child3: "",
    child4: "",
    child5: "",
    child6: "",
    child7: "",
    child8: "",
  });
  const [child_details, setChild_details] = useState([]);
  const [required_languages, setRequired_languages] = useState([]);
  const [work_types, setWorkType] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [confPasswordShown, setConfPasswordShown] = useState(false);
  const [dob, setDob] = useState();
  const [desiredDtartingDate, setDesiredStartingDate] = useState(date);
  const [otherRequiredLanguage, setOtherRequiredLanguage] = useState();
  const [otherWorkWorkType, setOtherWorkWorkType] = useState();
  const [errMsg, setErrMsg] = useState();
  const [scrollState, setScrollState] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [nextPageError, setNextPageError] = useState(false);
  const [nextPageErrorCheck, setNextPageErrorCheck] = useState(false);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    country_code: "+91",
    organization_name: "",
    official_function: "",
    work_email: "",
    old_password: "",
    password: "",
    confirmpassword: "",
    message_prefer_language: "",
    number_of_persons: "",
    legitimation_card_number: "",
    accommodation_provided: "",
    street_number: "",
    zip_code: "",
    city: "",
    desired_starting: "",
    country: "Switzerland",
    prefer_gender: "",
  });

  const scrollTo = useRef();
  const noScroll = useRef();
  const user_type = "employer";
  const language_selected = Cookies.get("i18next");

  useLayoutEffect(() => {
    fetchUserProfile(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);

  useEffect(() => {
    let canton = cantonsData?.id;
    let nationality = Nationality?.map((el) => el?.id);
    const date_of_birth = moment(dob).format("YYYY-MM-DD");
    let legitimation_card_type = legitimation_card?.value;
    const other_required_language = otherRequiredLanguage;
    const other_work_type = otherWorkWorkType;
    const desired_starting_date =
      moment(desiredDtartingDate).format("DD-MM-YYYY");
    setUserData({
      ...state,
      date_of_birth,
      user_type,
      child_details,
      title,
      legitimation_card_type,
      canton,
      blocked_countries,
      work_types,
      required_languages,
      nationality,
      legitimation_card_images,
      other_required_language,
      other_work_type,
      desired_starting_date,
    });
  }, [
    Nationality,
    blocked_countries,
    cantonsData,
    child_details,
    dob,
    required_languages,
    work_types,
    legitimation_card_images,
    legitimation_card,
    state,
    title,
    state.work_email,
    state.desired_starting_date,
    child,
    otherRequiredLanguage,
    otherWorkWorkType,
    desiredDtartingDate,
  ]);

  const cantonName = localStorage.getItem("canton_name");

  const cantonValue = cantonName
    ? cantonName
    : userInfo?.data?.result?.canton_id;

  useEffect(() => {
    if (isSubmitted) {
      let formErrors = EmployerAboutErrCheck(userData, images);
      if (Object.keys(formErrors).length) {
        setError(true);
        setErrMsg(formErrors);
      } else {
        setError(false);
      }
    }
  }, [
    isSubmitted,
    state,
    userData,
    dob,
    cantonsData,
    state.work_email,
    legitimation_card_images,
    images,
  ]);

  useEffect(() => {
    if (nextPageErrorCheck) {
      let NextPageformErrors = NextPageFormValidations(userData);
      if (Object.keys(NextPageformErrors).length) {
        setNextPageError(true);
        setErrMsg(NextPageformErrors);
        setScrollState(Object.keys(NextPageformErrors)[0]);
      } else {
        setNextPageError(false);
      }
    }
  }, [
    required_languages,
    work_types,
    state.accommodation_provided,
    userData,
    state,
    nextPageErrorCheck,
    state.desired_starting,
    state.desired_starting_date,
  ]);

  useEffect(() => {
    if (success === true) {
      navigate(appRoutes.pendingActivation);
    } else if (is_new_request_created === false) {
      setError(true);
    }
  }, [navigate, success, is_new_request_created]);

  useEffect(() => {
    if (is_new_request_created === false) {
      MySwal.fire({
        title: (
          <p style={{ fontFamily: "museo-sans, sans-serif", fontSize: "18px" }}>
            {t("NoUpdation")}
          </p>
        ),
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          navigate(appRoutes.EmployerDashboard);
        }
      });
      setError(false);
      dispatch(employerProfileNotUpdateReset());
    }
  }, [MySwal, dispatch, is_new_request_created, navigate, t]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, [showNextForm]);

  useEffect(() => {
    CheckUserSession(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (AuthError === true) {
      localStorage.clear();
    }
  }, [AuthError]);

  useEffect(() => {
    Countries(setCountries, language_selected);
    Canton(212, setCanton, language_selected);
  }, [cantonsData, language_selected]);

  useEffect(() => {
    const data = blockedCountries?.map((val) => {
      return val.id;
    });
    setblocked_countries(data);
  }, [blockedCountries]);

  useEffect(() => {
    localStorage.setItem("canton_name", userProfile?.canton_data?.name);
    setTitle(userProfile?.title);
    setDob(userProfile?.date_of_birth);
    setOtherRequiredLanguage(
      userProfile?.latest_request?.other_required_language
    );
    setOtherWorkWorkType(userProfile?.latest_request?.other_work_type);
    setDesiredStartingDate(userProfile?.latest_request?.desired_starting_date);
    if (userProfile?.last_request?.required_languages_data) {
      setRequired_languages(userProfile?.last_request?.required_languages_data);
    }
    if (userProfile?.last_request?.work_types_data) {
      setWorkType(userProfile?.last_request?.work_types_data);
    }
    setState({
      first_name: userProfile?.name ? userProfile?.name : "",
      last_name: userProfile?.last_name ? userProfile?.last_name : "",
      mobile_number: userProfile?.mobile_number
        ? userProfile?.mobile_number
        : "",
      email: userProfile?.email ? userProfile.email : "",
      country_code: "+91",
      organization_name: userProfile?.organization_name
        ? userProfile?.organization_name
        : "",
      official_function: userProfile?.official_function
        ? userProfile?.official_function
        : "",
      work_email: userProfile?.work_email ? userProfile?.work_email : "",
      message_prefer_language: userProfile?.user_details
        ?.message_prefer_language
        ? userProfile?.user_details?.message_prefer_language
        : "",
      number_of_persons: userProfile?.user_details?.number_of_persons
        ? userProfile?.user_details?.number_of_persons
        : "",
      legitimation_card_number: userProfile?.user_details
        ?.legitimation_card_number
        ? userProfile.user_details?.legitimation_card_number
        : "",
      accommodation_provided: userProfile?.latest_request
        ?.is_accommodation_provided
        ? userProfile?.latest_request?.is_accommodation_provided
        : "",
      street_number: userProfile?.user_details?.street_number
        ? userProfile.user_details?.street_number
        : "",
      zip_code: userProfile?.zip_code ? userProfile?.zip_code : "",
      city: userProfile?.city_id ? userProfile?.city_id : "",
      desired_starting: userProfile?.last_request?.desired_starting
        ? userProfile?.last_request?.desired_starting
        : "",
      desired_starting_date: userProfile?.last_request?.desired_starting_date
        ? userProfile?.last_request?.desired_starting_date
        : "",
      prefer_gender: userProfile?.latest_request?.prefer_gender
        ? userProfile?.latest_request?.prefer_gender
        : "",
      country: "Switzerland",
    });
  }, [userProfile]);

  useEffect(() => {
    const nationalityValue = userProfile?.nationality?.map((el) => {
      return {
        value: el?.name,
        label: el?.name,
        id: el?.id,
      };
    });
    const cantonValue = {
      value: userProfile?.canton_data?.name,
      label: userProfile?.canton_data?.name,
      id: userProfile?.canton_data?.id,
    };
    const legitimation_card_typeValue = {
      value: userProfile?.user_details?.legitimation_card_type,
      label: userProfile?.user_details?.legitimation_card_type,
    };
    const blockedCountriesData =
      userProfile?.latest_request?.blocked_countries_data.map((country) => {
        return { label: country.name, value: country.name, id: country.id };
      });

    cardTypes.forEach((element) => {
      if (element.value === legitimation_card_typeValue?.value) {
        setLegitimation_card_type(element);
      }
    });
    setCantonData(cantonValue);
    SetNationality(nationalityValue);
    setBlocketCountries(blockedCountriesData);
    const img = userProfile?.card_images_data?.map((img) => {
      return img;
    });
    setImages(img);
    setLegitimation_card_images(img);
    if (userProfile?.child_details_data?.length) {
      setChild({
        child1: userProfile?.child_details_data[0]
          ? userProfile?.child_details_data[0]
          : "",
        child2: userProfile?.child_details_data[1]
          ? userProfile?.child_details_data[1]
          : "",
        child3: userProfile?.child_details_data[2]
          ? userProfile?.child_details_data[2]
          : "",
        child4: userProfile?.child_details_data[3]
          ? userProfile?.child_details_data[3]
          : "",
        child5: userProfile?.child_details_data[4]
          ? userProfile?.child_details_data[4]
          : "",
        child6: userProfile?.child_details_data[5]
          ? userProfile?.child_details_data[5]
          : "",
        child7: userProfile?.child_details_data[6]
          ? userProfile?.child_details_data[6]
          : "",
        child8: userProfile?.child_details_data[7]
          ? userProfile?.child_details_data[7]
          : "",
      });
    }
  }, [userProfile]);

  const handleAddChildren = () => {
    if (addChildren > 4) {
      setAddChildren(...addChildren);
    } else {
      setAddChildren(addChildren + 1);
    }
  };

  const handleChild = (e) => {
    setChild({ ...child, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    const data = [
      child.child1 && child.child1,
      child.child2 && child.child2,
      child.child3 && child.child3,
      child.child4 && child.child4,
      child.child5 && child.child5,
      child.child6 && child.child6,
      child.child7 && child.child7,
      child.child8 && child.child8,
    ];
    setChild_details(data);
  }, [child]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleOldPassword = () => {
    setOldPasswordShown(!oldPasswordShown);
  };
  const toggleCongPassword = () => {
    setConfPasswordShown(!confPasswordShown);
  };

  const handleRemoveChildren = () => {
    setAddChildren(addChildren - 1);
    if (addChildren === 1) {
      setChild({ ...child, child4: "" });
    }
    if (addChildren === 2) {
      setChild({ ...child, child5: "" });
    }
    if (addChildren === 3) {
      setChild({ ...child, child6: "" });
    }
    if (addChildren === 4) {
      setChild({ ...child, child7: "" });
    }
    if (addChildren === 5) {
      setChild({ ...child, child8: "" });
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const RequiredLanguages = (e) => {
    if (e.target.checked) {
      setRequired_languages([...required_languages, e.target.value]);
    } else {
      const Array = [...required_languages];
      Array.splice(required_languages.indexOf(e.target.value), 1);
      setRequired_languages(Array);
    }
  };

  const WorkType = (e) => {
    if (e.target.checked) {
      setWorkType([...work_types, e.target.value]);
    } else {
      const Array = [...work_types];
      Array.splice(work_types.indexOf(e.target.value), 1);
      setWorkType(Array);
    }
  };

  const handlePageError = (e) => {
    e.preventDefault();
    const formErrors = EmployerAboutErrCheck(userData, cantonsData);
    if (Object.keys(formErrors).length) {
      setError(true);
      setErrMsg(formErrors);
      setScrollState(Object.keys(formErrors)[0]);
    } else {
      setError(false);
    }
    setIsSubmitted(true);
    if (Object.keys(formErrors).length === 0) {
      setShowNextForm(true);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isAuthenticated === true) {
      setNextPageErrorCheck(true);
      localStorage.setItem("canton_name", cantonsData?.label);
      let NextPageformErrors = NextPageFormValidations(userData);
      if (Object.keys(NextPageformErrors).length) {
        setNextPageError(true);
        setErrMsg(NextPageformErrors);
        setScrollState(Object.keys(NextPageformErrors)[0]);
      } else {
        setNextPageError(false);
      }
      if (!Object.keys(NextPageformErrors).length) {
        employerProfileUpdate(dispatch, userData);
      }
    }
  };

  const Options = language_selected === "en" ? titleOptions : titleOptionsfr;

  return (
    <Container delAcc={true} success={success} role="employer">
      <form className="flex w-full flex-col relative" onSubmit={onSubmit}>
        {showNextForm === false && (
          <ItemContainer>
            <span className="text-textGray py-2 text-lg sm_ss:mb-6 sm_s:mb-6 font-bold sm_ss:text-center sm_s:text-center sm_s:text-lg">
              {t("EMPLOYER_DETAILS")}
            </span>

            <div
              ref={scrollState === "title" ? scrollTo : noScroll}
              className="w-1/4 pl-0 py-3 flex flex-col relative mb-2 sm_s:w-1/2 sm_ss:w-1/2"
            >
              <p className="text-sectext py-1">{t("Title")}</p>
              <select
                className="bg-white outline-none p-2 border border-textGray rounded-md"
                name="title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              >
                <option value="">{t("PleaseSelect")}</option>
                {Options?.map((val, index) => {
                  return (
                    <option
                      selected={val.value === title}
                      value={val?.value}
                      key={index}
                    >
                      {val?.label}
                    </option>
                  );
                })}
              </select>
              {error && (
                <span className="text-red-600 absolute top-20 left-3 text-xs py-1">
                  {errMsg?.title}
                </span>
              )}
            </div>
            <div className="w-full flex justify-between sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <div
                ref={scrollState === "first_name" ? scrollTo : noScroll}
                className="flex justify-center w-full relative flex-1 sm_s:w-full sm_ss:w-full sm_ss:justify-start sm_s:justify-start"
              >
                <TextInput
                  text={t("FName")}
                  textType={"text"}
                  placeholder={t("Fname")}
                  name="first_name"
                  value={state.first_name}
                  handleChange={handleChange}
                  id="first_name"
                />
                <span className="text-red-600 absolute top-18 left-3 text-xs">
                  {error && errMsg?.first_name}
                </span>
              </div>
              <div className="w-10" />
              <div
                ref={scrollState === "last_name" ? scrollTo : noScroll}
                className="flex justify-center sm_ss:justify-start sm_s:justify-start relative flex-1 sm_s:w-full sm_ss:w-full"
              >
                <TextInput
                  text={t("LName")}
                  textType={"text"}
                  placeholder={t("Lname")}
                  name="last_name"
                  value={state.last_name}
                  handleChange={handleChange}
                  id="last_name"
                />
                <span className="text-red-600 absolute top-18 left-3 text-xs">
                  {error && errMsg?.last_name}
                </span>
              </div>
            </div>
            <div className="w-full flex items-center sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <div className="flex justify-start w-full sm_ss:w-full sm_s:w-full">
                <div
                  ref={scrollState === "nationality" ? scrollTo : noScroll}
                  className="w-full flex flex-col h-24 flex-1 relative sm_s:w-full sm_ss:w-full sm_ss:justify-start sm_s:justify-start"
                >
                  <p className="text-sectext pb-2">{t("Nationality")}</p>
                  <Select
                    onChange={(e) => SetNationality(e)}
                    options={countries?.map((val) => {
                      return { value: val.name, label: val.name, id: val.id };
                    })}
                    value={Nationality}
                    id="nationality"
                    placeholder={t("Select Nationality")}
                    isMulti={true}
                  />
                  <span className="text-red-600 absolute top-18 left-3 text-xs ">
                    {error && errMsg?.nationality}
                  </span>
                </div>
              </div>
              <div className="w-10" />

              <div className="flex w-full sm_ss:w-full sm_s:w-full md:pl-3 lg:pl-3">
                <div
                  ref={scrollState === "date_of_birth" ? scrollTo : noScroll}
                  className="flex w-full flex-col flex-1 h-24 relative sm_s:w-full sm_ss:w-full"
                >
                  <div className="w-full">
                    <DatePickerComp
                      handleDate={(e) => setDob(e)}
                      label={t("DOB")}
                      value={dob}
                      name="date_of_birth"
                      disableFuture={true}
                    />
                  </div>
                  <span className="text-red-600 absolute top-18 left-3 text-xs">
                    {error && errMsg?.date_of_birth}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full mb-2 flex items-center justify-between sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <div className="flex justify-center sm_ss:justify-start sm_s:justify-start flex-1 sm_s:w-full sm_ss:w-full ">
                <MobilePhoneInput
                  text={t("Mobile")}
                  placeholder={t("Mobile")}
                  value={state.mobile_number}
                  onChange={(e) => setState({ ...state, mobile_number: e })}
                />
              </div>
              <div className="w-10" />
              <div className="flex justify-center sm_ss:justify-start sm_s:justify-start flex-1 relative sm_s:w-full sm_ss:w-full">
                <TextInput
                  text={t("PrivateEmail")}
                  textType={"email"}
                  placeholder={t("PrivateEmail")}
                  value={state.email}
                  handleChange={handleChange}
                  id="email"
                  disable={true}
                />
              </div>
            </div>
            <div className="w-full flex justify-center sm_ss:justify-start sm_s:justify-start">
              <div
                ref={scrollState === "organization_name" ? scrollTo : noScroll}
                className="flex w-full flex-col relative"
              >
                <TextInput
                  text={t("OrganizationEmpl")}
                  textType={"text"}
                  placeholder={t("OrganizationEmp")}
                  value={state.organization_name}
                  handleChange={handleChange}
                  id="organization_name"
                />
                <span className="text-red-600 absolute top-18 left-3 text-xs">
                  {error && errMsg?.organization_name}
                </span>
              </div>
              <div className="w-20" />
              <div
                ref={scrollState === "official_function" ? scrollTo : noScroll}
                className="flex justify-center sm_ss:justify-start sm_s:justify-start w-full relative sm_s:w-full sm_ss:w-full"
              >
                <TextInput
                  text={t("Officialfunc")}
                  textType={"text"}
                  placeholder={t("OfficialFunc")}
                  value={state.official_function}
                  handleChange={handleChange}
                  id="official_function"
                />
                <span className="text-red-600 absolute top-18 left-3 text-xs">
                  {error && errMsg?.official_function}
                </span>
              </div>
            </div>
          </ItemContainer>
        )}
        {showNextForm === false && (
          <ItemContainer bg={true}>
            <span className="text-textGray text-lg py-3 font-bold sm_ss:text-center sm_s:text-center sm_s:text-lg">
              {t("HOUSEHOLD")}
            </span>
            <div
              ref={scrollState === "number_of_person" ? scrollTo : noScroll}
              className="flex sm_ss:flex-col sm_s:flex-col relative "
            >
              <div className="flex-1 justify-start relative ">
                <p className="text-sectext pb-2">{t("NumberOfPersons")}</p>
                <div className="w-8/12">
                  <TextInput
                    textType={"text"}
                    name="number_of_persons"
                    value={state.number_of_persons}
                    handleChange={handleChange}
                    id="number_of_persons"
                  />
                </div>
                <span className="text-red-600 absolute top-20 mb-2 left-1 text-xs">
                  {error && errMsg?.number_of_persons}
                </span>
              </div>
              <div className="flex-1 sm_s:mt-6 ml-8 sm_ss:ml-0 sm_s:ml-0">
                <div className="flex flex-col">
                  <p className="text-sectext mb-1">{t("AgeOfChildren")}</p>
                  <AddChild
                    number={"child1"}
                    value={child.child1}
                    handleChild={handleChild}
                    title="child1"
                    className="mt-4"
                  />
                  <AddChild
                    number={"child2"}
                    value={child.child2}
                    handleChild={handleChild}
                    title="child2"
                    className="mt-2"
                  />
                  <AddChild
                    number={"child3"}
                    value={child.child3}
                    handleChild={handleChild}
                    title="child3"
                    className="mt-2"
                  />
                  <div className="relative">
                    {Array.from(Array(addChildren)).map((c, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full mt-1 flex flex-col items-start justify-start relative"
                        >
                          <AddChild
                            number={`child${index + 4}`}
                            value={child[`child${index + 4}`]}
                            handleChild={handleChild}
                            title={`child${index + 4}`}
                            className="mt-2"
                            addChildren={addChildren}
                            handleRemoveChildren={handleRemoveChildren}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {addChildren === 5 ? (
                    ""
                  ) : (
                    <span
                      className="text-blue underline cursor-pointer pt-5 max-w-fit"
                      onClick={handleAddChildren}
                    >
                      + Add children
                    </span>
                  )}
                </div>
              </div>
            </div>
          </ItemContainer>
        )}
        {showNextForm === false && (
          <ItemContainer>
            <p className="text-textGray font-bold text-lg py-3 sm_ss:text-center sm_s:text-center sm_s:text-lg">
              {t("AccountPassword")}
            </p>
            <div className="w-full flex flex-col items-start justify-between sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <div
                ref={scrollState === "oldPassword" ? scrollTo : noScroll}
                className="flex justify-start flex-1 relative w-1/2 sm_s:w-full sm_ss:w-full"
              >
                <TextInput
                  text={t("OldPassword")}
                  textType={`${oldPasswordShown ? "text" : "password"}`}
                  placeholder={"*********"}
                  value={state.old_password}
                  handleChange={handleChange}
                  id="old_password"
                  showEyeButton={true}
                  togglePassword={toggleOldPassword}
                  passwordShown={oldPasswordShown}
                />
                {error && (
                  <span className="text-red-600 absolute top-18 left-3 text-xs py-1">
                    {errMsg?.oldPassword}
                  </span>
                )}
              </div>
              <div
                ref={scrollState === "password" ? scrollTo : noScroll}
                className="flex justify-start flex-1 relative w-1/2 sm_s:w-full sm_ss:w-full"
              >
                <TextInput
                  text={t("password")}
                  textType={`${passwordShown ? "text" : "password"}`}
                  placeholder={"*********"}
                  value={state.password}
                  handleChange={handleChange}
                  id="password"
                  showEyeButton={true}
                  togglePassword={togglePassword}
                  passwordShown={passwordShown}
                />
                {error && (
                  <span className="text-red-600 absolute top-18 left-3 text-xs py-1">
                    {errMsg?.password}
                  </span>
                )}
              </div>
              <div className="w-10" />
              <div
                ref={scrollState === "passwordConfirm" ? scrollTo : noScroll}
                className="flex justify-end sm_ss:justify-start sm_s:justify-start flex-1 relative w-1/2 sm_s:w-full sm_ss:w-full"
              >
                <TextInput
                  text={t("repeatPassword")}
                  textType={`${confPasswordShown ? "text" : "password"}`}
                  placeholder={"*********"}
                  value={state.confirmpassword}
                  handleChange={handleChange}
                  id="confirmpassword"
                  showEyeButton={true}
                  togglePassword={toggleCongPassword}
                  passwordShown={confPasswordShown}
                />
                {error && (
                  <span className="text-red-600 absolute top-18 left-3 text-xs py-1">
                    {errMsg?.passwordConfirm}
                  </span>
                )}
              </div>
            </div>
          </ItemContainer>
        )}
        {showNextForm === false && (
          <ItemContainer bg={true}>
            <p className="text-textGray font-bold text-lg py-3 sm_ss:text-center sm_s:text-center sm_s:text-lg">
              {t("Messages")}
            </p>

            <p className="text-sectext">{t("PrefferMessageLang")}</p>
            <div className="w-full flex flex-col justify-between py-2 relative sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <div className="flex items-center my-4">
                <input
                  type="radio"
                  name="preferLanguage"
                  className="h-6 w-6"
                  value={"English"}
                  checked={state.message_prefer_language === "English"}
                  id="message_prefer_language"
                  onChange={(e) => handleChange(e)}
                />
                <span className="text-sectext ml-1">{t("English")}</span>
              </div>
              <div className="flex items-center my-1 ">
                <input
                  type="radio"
                  name="preferLanguage"
                  className="h-6 w-6"
                  value={"French"}
                  checked={state.message_prefer_language === "French"}
                  onChange={(e) => handleChange(e)}
                  id="message_prefer_language"
                />
                <span className="text-sectext ml-1">{t("French")}</span>
              </div>
            </div>
          </ItemContainer>
        )}
        {showNextForm === false && (
          <ItemContainer>
            <p className="text-textGray font-bold text-lg py-1 pb-6 sm_ss:text-center sm_s:text-center sm_s:text-lg">
              {t("LEGITIMATIONCARD")}
            </p>
            <div className="flex flex-col sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <div className="flex flex-row items-center justify-between  sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
                <div className="flex w-full flex-col h-24 relative sm_s:w-full sm_ss:w-full sm_s:pl-2">
                  <p className="text-sectext pb-2">
                    {t("LEGITIMATIONCARDTYPE")}
                  </p>
                  <div className="w-[200px]">
                    <Select
                      isDisabled={true}
                      options={cardTypes}
                      value={legitimation_card}
                      onChange={(e) => setLegitimation_card_type(e)}
                      placeholder={t("Select")}
                    />
                  </div>
                </div>
                <div className=" flex w-full flex-col relative sm_s:w-full sm_ss:w-full">
                  <TextInput
                    disable={true}
                    text={t("LEGITIMATIONCARD")}
                    textType={"number"}
                    placeholder={t("LEGITIMATIONCARD")}
                    value={state.legitimation_card_number}
                    handleChange={handleChange}
                    id="legitimation_card_number"
                  />
                </div>
              </div>
              <p className="text-sectext">{t("UploadedImages")}</p>

              <div className="flex flex-wrap justify-start w-full p-2">
                {images &&
                  images?.map((val, index) => (
                    <div key={index} className="flex flex-row rounded-sm mt-6">
                      {/* <div className="flex item-center h-28 pr-3 sm_ss:pr-4 sm_s:pr-4">
                        <img
                          src={val}
                          alt="card"
                          width={"176px"}
                          height={"96px"}
                          className="rounded-md object-cover bg-black"
                        />
                      </div> */}

                      <ImageModal url={val} />
                    </div>
                  ))}
              </div>
            </div>
          </ItemContainer>
        )}
        {showNextForm === false && (
          <ItemContainer bg={true}>
            <span className="text-textGray font-bold text-lg py-2 uppercase sm_ss:text-center sm_s:text-center sm_s:text-lg">
              {t("PRIVATE_ADDRESS")}
            </span>

            <div className="w-full flex relative sm_s:w-full sm_ss:w-full">
              <TextInput
                text={t("Street")}
                textType={"text"}
                placeholder={t("Street")}
                value={state.street_number}
                handleChange={handleChange}
                id="street_number"
              />
            </div>
            <div className="w-full flex items-center justify-between relative sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <div className="w-1/3 pr-8 relative sm_s:w-full sm_ss:w-full sm_ss:pr-0 sm_s:pr-0">
                <TextInput
                  text={t("ZipCode")}
                  textType={"number"}
                  placeholder={t("ZipCode")}
                  value={state.zip_code}
                  handleChange={handleChange}
                  id="zip_code"
                />
              </div>
              <div className="w-10/12 sm_s:w-full sm_ss:w-full">
                <TextInput
                  text={t("City")}
                  textType={"text"}
                  placeholder={t("City")}
                  value={state.city}
                  handleChange={handleChange}
                  id="city"
                />
              </div>
            </div>
            <div className="w-full py-3 flex sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <div className="flex flex-1 flex-col h-24">
                <TextInput
                  disable={true}
                  text={t("Country")}
                  value={state.country}
                />
              </div>
              <div className="w-5"></div>
              <div className="flex flex-1 flex-col h-24 relative pl-5 sm_ss:w-full sm_s:w-full sm_ss:pl-0 sm_s:pl-0 sm_ss:pr-0 sm_s:pr-2">
                <SelectInput
                  title={t("Canton")}
                  options={cantons?.map((val) => {
                    return {
                      label: val.name,
                      value: val.name,
                      id: val.id,
                    };
                  })}
                  value={cantonsData}
                  defaultvalue={cantonValue}
                  handleselect={(e, value) => setCantonData(value)}
                  placeholder={t("Select canton")}
                />
                <span className="text-red-600 absolute top-18 left-6 text-xs">
                  {error && errMsg?.cantons}
                </span>
              </div>
            </div>
          </ItemContainer>
        )}
        {showNextForm && (
          <ItemContainer>
            <BackButton
              className={
                "left-0 -top-10 sm_s:left-0 sm_s:right-0 sm_s:m-auto sm_s:top-0 sm_ss:left-0 sm_ss:right-0 sm_ss:m-auto sm_ss:top-0 cursor-pointer absolute"
              }
              handlechangle={() => setShowNextForm(false)}
            />
            <p className="text-textGray font-bold text-lg py-4 pb-8 sm_ss:text-center sm_s:text-center sm_s:text-lg">
              {t("EMPLOYEE_DETAILS")}
            </p>
            <div
              ref={scrollState === "required_languages" ? scrollTo : noScroll}
              className="flex flex-col relative overflow-hidden"
            >
              <p className="text-sectext my-3">{t("RequiredLang")}</p>
              <div className="flex flex-row flex-wrap sm_ss:h-36 sm_s:h-36 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
                <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"English"}
                    onChange={(e) => RequiredLanguages(e)}
                    checked={required_languages.includes("English")}
                    id="required_languages_English"
                    name="English"
                  />
                  <p className="pl-2 text-sectext">{t("english")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"French"}
                    checked={required_languages.includes("French")}
                    id="required_languages_French"
                    onChange={(e) => RequiredLanguages(e)}
                    name="French"
                  />
                  <p className="pl-2 text-sectext">{t("french")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"German"}
                    checked={required_languages.includes("German")}
                    id="required_languages_German"
                    onChange={(e) => RequiredLanguages(e)}
                    name="German"
                  />
                  <p className="pl-2 text-sectext">{t("German")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2 sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"Italian"}
                    checked={required_languages.includes("Italian")}
                    id="required_languages_Italian"
                    onChange={(e) => RequiredLanguages(e)}
                    name="Italian"
                  />
                  <p className="pl-2 text-sectext">{t("Italian")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"Portuguese"}
                    checked={required_languages.includes("Portuguese")}
                    id="required_languages_Portuguese"
                    onChange={(e) => RequiredLanguages(e)}
                    name="Portuguese"
                  />
                  <p className="pl-2 text-sectext">{t("Portuguese")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"Spanish"}
                    checked={required_languages.includes("Spanish")}
                    id="required_languages_Spanish"
                    onChange={(e) => RequiredLanguages(e)}
                    name="Spanish"
                  />
                  <p className="pl-2 text-sectext">{t("Spanish")}</p>
                </span>
              </div>
              <div className="w-5/12 mt-5">
                <TextInput
                  textType={"text"}
                  text={t("others")}
                  value={otherRequiredLanguage}
                  handleChange={(e) => setOtherRequiredLanguage(e.target.value)}
                  id="others"
                />
              </div>
              <span className="text-red-600 absolute top-56 sm_ss:top-44 sm_s:top-44 left-1 text-xs">
                {nextPageError && errMsg?.required_languages}
              </span>
            </div>
            <div className="flex flex-col relative">
              <p className="text-sectext my-3">{t("TypeOFWork")}</p>
              <div
                ref={scrollState === "work_types" ? scrollTo : noScroll}
                className="flex flex-row flex-wrap sm_ss:h-36 sm_s:h-36 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap sm_ss:flex-wrap"
              >
                <span className="w-1/4 flex flex-row items-center my-2 sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={work_types.includes("Housework")}
                    className="h-6 w-6"
                    value={"Housework"}
                    name="Housework"
                    id="work_type_Housework"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Housework")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={work_types.includes("Childcare")}
                    className="h-6 w-6"
                    value={"Childcare"}
                    name="Childcare"
                    id="work_type_Childcare"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Childcare")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    checked={work_types.includes("Gardening")}
                    value={"Gardening"}
                    name="Gardening"
                    id="work_type_Gardening"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Gardening")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2 sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"TableService"}
                    name="TabelService"
                    checked={work_types.includes("TableService")}
                    id="work_type_Tabelservice"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("TableService")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"Cooking"}
                    name="Cooking"
                    checked={work_types.includes("Cooking")}
                    id="work_type_Cooking"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Cooking")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"Laundry"}
                    name="Laundry"
                    checked={work_types.includes("Laundry")}
                    id="work_type_Laundry"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Laundry")}</p>
                </span>
              </div>
              <div className="w-5/12 mt-5">
                <TextInput
                  textType={"text"}
                  text={t("others")}
                  value={otherWorkWorkType}
                  handleChange={(e) => setOtherWorkWorkType(e.target.value)}
                  id="work_types_others"
                />
              </div>
              <span className="text-red-600 absolute top-56 sm_ss:top-44 sm_s:top-44 left-1 text-xs">
                {nextPageError && errMsg?.work_type}
              </span>
            </div>
            <div className="flex flex-col relative mt-3">
              <p className="text-sectext pb-4">{t("AccommodationProvided")}</p>
              <div className="flex flex-row justify-between sm_ss:h-24 sm_s:h-24 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
                <div className=" flex w-9/12 justify-between sm_ss:flex-col sm_s:flex-col">
                  <div className="flex flex-row items-center py-2 w-44 ">
                    <input
                      type={"radio"}
                      className="h-5 w-5"
                      name="Accommodation"
                      id="accommodation_provided"
                      onChange={(e) => handleChange(e)}
                      value={"yes"}
                      checked={state.accommodation_provided === "yes"}
                    />
                    <span className="pl-1 text-sectext">{t("Yes")}</span>
                  </div>
                  <div className="flex flex-row items-center w-96">
                    <input
                      type={"radio"}
                      className="h-5 w-5"
                      name="Accommodation"
                      id="accommodation_provided"
                      onChange={(e) => handleChange(e)}
                      value={"no"}
                      checked={state.accommodation_provided === "no"}
                    />
                    <span className="pl-1 text-sectext sm_ss:text-sm sm_s:text-sm">
                      {t("No")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col py-8 mt-3">
              <p className="text-sectext pb-4">{t("DesiredStartingDate")}</p>
              <div className="flex flex-row justify-start sm_ss:h-24 sm_s:h-18 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
                <div className="flex flex-row">
                  <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44">
                    <input
                      type="radio"
                      value={"Immediately"}
                      checked={state.desired_starting === "Immediately"}
                      name="Desired starting date"
                      id="desired_starting"
                      className="h-5 w-5"
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="pl-1 text-sectext">
                      {t("Immediately")}
                    </span>
                  </div>
                  <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                    <input
                      type="radio"
                      value={"To be agreed"}
                      checked={state.desired_starting === "To be agreed"}
                      name="Desired starting date"
                      id="desired_starting"
                      className="h-5 w-5"
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="pl-1 text-sectext">{t("ToBeAgreed")}</span>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-col relative">
                    <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                      <input
                        type="radio"
                        value={"Before"}
                        checked={state.desired_starting === "Before"}
                        name="Desired starting date"
                        id="desired_starting"
                        className="h-5 w-5"
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="pl-1 text-sectext">{t("Before")}</span>

                      {state.desired_starting === "Before" && (
                        <DatePickerComp
                          handleDate={(e) => setDesiredStartingDate(e)}
                          value={desiredDtartingDate}
                          name="desired_starting_date"
                          position={true}
                          disablePast={true}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col relative">
                    <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                      <input
                        type="radio"
                        value={"Not before"}
                        checked={state.desired_starting === "Not before"}
                        name="Desired starting date"
                        id="desired_starting"
                        className="h-5 w-5"
                        onChange={handleChange}
                      />
                      <span className="pl-1 text-sectext">
                        {t("NotBefore")}
                      </span>

                      {state.desired_starting === "Not before" && (
                        <DatePickerComp
                          handleDate={(e) => setDesiredStartingDate(e)}
                          value={desiredDtartingDate}
                          name="desired_starting_date"
                          position={true}
                          disablePast={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col py-3">
              <p className="text-sectext pb-4">{t("Preference")}</p>
              <div className="flex flex-row justify-start">
                <div className="flex  flex-row items-center w-44 ">
                  <input
                    type="radio"
                    value={"woman"}
                    checked={state.prefer_gender === "woman"}
                    name="Preference for a"
                    id="prefer_gender"
                    className="h-5 w-5"
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="pl-1 text-sectext">{t("Woman")}</span>
                </div>
                <div className="flex  flex-row items-center w-44">
                  <input
                    type="radio"
                    value={"man"}
                    name="Preference for a"
                    id="prefer_gender"
                    className="h-5 w-5"
                    checked={state.prefer_gender === "man"}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="pl-1 text-sectext">{t("Man")}</span>
                </div>
                <div className="flex  flex-row items-center w-44 ">
                  <input
                    type="radio"
                    value={"indifferent"}
                    checked={state.prefer_gender === "indifferent"}
                    name="Preference for a"
                    id="prefer_gender"
                    className="h-5 w-5"
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="pl-1 text-sectext">{t("Indifferent")}</span>
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col py-8 w-7/12 sm_ss:w-full sm_s:w-full">
              <p className="text-sectext py-3">{t("HideNationality")}</p>
              <Select
                isMulti={true}
                options={countries?.map((val) => {
                  return { value: val.name, label: val.name, id: val.id };
                })}
                defaultValue={blockedCountries}
                onChange={(e) => setBlocketCountries(e)}
                placeholder={t("Select blocked countries")}
              />
            </div> */}
          </ItemContainer>
        )}
        {showNextForm && (
          <div className="flex flex-col py-5 sm_ss:py-5 sm_s:py-4 justify-star sm_ss:w-full sm_s:w-full sm_s:justify-center sm_s:items-center sm_ss:justify-center sm_ss:items-center">
            {/* {!!serverError && (
              <span className="text-red-600">
                Something went wrong, please try again later
              </span>
            )} */}
            {!!serverError?.error?.email && (
              <span className="text-red-600">
                {serverError?.error?.email[0]}
              </span>
            )}
            {!!serverError?.error?.mobile_number && (
              <span className="text-red-600">
                {serverError?.error?.mobile_number[0]}
              </span>
            )}
            {!!serverError?.old_password && (
              <span className="text-red-600">
                {serverError?.old_password[0]}
              </span>
            )}
            <div className="my-5 sm_s:pb-2">
              <button
                type="submit"
                className=" uppercase bg-blue text-white font-bold text-sm h-12 w-24 rounded-md"
              >
                {pending ? <Loader /> : t("save")}
              </button>
            </div>
            <p className="text-sectext text-xs sm_ss:text-base sm_s:text-base sm_ss:text-center sm_s:text-center sm_ss:p-4 sm_s:px-10 sm_s:py-6">
              {t("EMPLOYER_RESPOSIBILITYTEXT")}
            </p>
          </div>
        )}
        <div>
          {showNextForm ? null : (
            <div className="w-full flex items-center justify-between pb-10">
              <div className="w-full flex justify-start items-center sm_ss:justify-center sm_s:justify-center sm:justify-center md:justify-start">
                <button
                  disabled={error}
                  onClick={(e) => handlePageError(e)}
                  className="bg-blue disabled:bg-textGray disabled:cursor-not-allowed uppercase text-white font-bold text-sm py-2 px-5 mt-5 rounded-md "
                >
                  {t("continue")}
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};

export default EmployerSignUp;
