import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Container from "../components/Container";
import DateInput from "../components/DateInput";
import ItemContainer from "../components/ItemContainer";
import TextInput from "../components/TextInput";
import {
  newEmployementRequestError,
  newEmployementRequestStart,
  newEmployementRequestSuccess,
} from "../redux/slices/NewEmployementRequest";
import { NextPageFormValidations } from "../utils.js/FormValidations";
import Axios from "axios";
import { API } from "../constants/apiConstants/API";
import { CircularProgress } from "@mui/material";
import { Countries } from "../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../constants/routeConstants/Routes";
import { useTranslation } from "react-i18next";
import DatePickerComp from "../components/DatePicker";
import moment from "moment";

function NewRequestFormEmployer() {
  const { t } = useTranslation();
  const user_type = "employer";
  const scrollTo = useRef();
  const noScroll = useRef();
  const dispatch = useDispatch();
  const pending = useSelector((state) => state.newEmployementRequest.pending);
  const success = useSelector((state) => state.newEmployementRequest.success);
  const serverError = useSelector((state) => state.newEmployementRequest.error);
  const [required_languages, setRequired_languages] = useState([]);
  const [work_types, setWorkType] = useState([]);
  const [blockedCountries, setBlocketCountries] = useState([]);
  const [blocked_countries, setblocked_countries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [scrollState, setScrollState] = useState();
  const [desiredDtartingDate, setDesiredStartingDate] = useState();
  const [nextPageErrorCheck, setNextPageErrorCheck] = useState(false);
  const [userData, setUserData] = useState({});
  const [otherRequiredLanguage, setOtherRequiredLanguage] = useState();
  const [otherWorkWorkType, setOtherWorkWorkType] = useState();
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [agree, setAgree] = useState(true);
  const [state, setState] = useState({
    accommodation_provided: "",
    desired_starting: "",
    desired_starting_date: "",
    prefer_gender: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    Countries(setCountries);
  }, []);

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const RequiredLanguages = (e) => {
    if (e.target.checked) {
      setRequired_languages([...required_languages, e.target.value]);
    } else {
      const Array = [...required_languages];
      Array.splice(required_languages.indexOf(e.target.value), 1);
      setRequired_languages(Array);
    }
  };

  const WorkType = (e) => {
    if (e.target.checked) {
      setWorkType([...work_types, e.target.value]);
    } else {
      const Array = [...work_types];
      Array.splice(work_types.indexOf(e.target.value), 1);
      setWorkType(Array);
    }
  };

  useEffect(() => {
    if (nextPageErrorCheck) {
      let NextPageformErrors = NextPageFormValidations(userData);
      if (Object.keys(NextPageformErrors).length) {
        setError(true);
        setErrMsg(NextPageformErrors);
        setScrollState(Object.keys(NextPageformErrors)[0]);
      } else {
        setError(false);
      }
    }
  }, [
    required_languages,
    work_types,
    state.accommodation_provided,
    userData,
    state,
    nextPageErrorCheck,
    state.desired_starting,
    state.desired_starting_date,
  ]);

  useEffect(() => {
    const data = blockedCountries.map((val) => {
      return val.id;
    });
    setblocked_countries(data);
  }, [blockedCountries]);

  useEffect(() => {
    const desired_starting_date =
      moment(desiredDtartingDate).format("DD-MM-YYYY");
    const other_required_language = otherRequiredLanguage;
    const other_work_type = otherWorkWorkType;
    setUserData({
      ...state,
      user_type,
      blocked_countries,
      work_types,
      required_languages,
      desired_starting_date,
      other_required_language,
      other_work_type,
    });
  }, [
    blocked_countries,
    required_languages,
    work_types,
    state,
    desiredDtartingDate,
    otherRequiredLanguage,
    otherWorkWorkType,
  ]);

  const onSubmit = async (event) => {
    setNextPageErrorCheck(true);
    const userToken = JSON.parse(localStorage.getItem("userInfo"));
    const token = `Bearer ${userToken?.data?.result?.access_token}`;
    event.preventDefault();
    let NextPageformErrors = NextPageFormValidations(userData);
    if (Object.keys(NextPageformErrors).length) {
      setError(true);
      setErrMsg(NextPageformErrors);
      setScrollState(Object.keys(NextPageformErrors)[0]);
    } else {
      setError(false);
    }
    if (!Object.keys(NextPageformErrors).length) {
      dispatch(newEmployementRequestStart());
      await Axios({
        method: "post",
        url: API.BASE_URL + API.NEW_EMPLOYEMENT_REQUEST,
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        data: {
          ...userData,
        },
      })
        .then((res) => {
          dispatch(newEmployementRequestSuccess());
        })
        .catch((err) => {
          let error = err.response.data.message;
          dispatch(newEmployementRequestError({ error }));
        });
    }
  };

  useEffect(() => {
    if (success) {
      navigate(appRoutes.pendingActivation);
    }
  }, [success, navigate]);

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);

  return (
    <Container>
      <form onSubmit={onSubmit} className="flex flex-col w-full">
        <ItemContainer>
          <p className="text-textGray font-bold text-lg py-4 pb-8 sm_ss:text-center sm_s:text-center sm_s:text-lg">
            {t("EMPLOYEE_DETAILS")}
          </p>
          <div
            ref={scrollState === "required_languages" ? scrollTo : noScroll}
            className="flex flex-col relative overflow-hidden"
          >
            <p className="text-sectext my-3 font-semibold">
              {t("RequiredLang")}
            </p>
            <div className="flex flex-row flex-wrap sm_ss:h-36 sm_s:h-36 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
              <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  value={"English"}
                  onChange={(e) => RequiredLanguages(e)}
                  checked={required_languages.includes("English")}
                  id="required_languages_English"
                  name="English"
                />
                <p className="pl-2 text-sectext">{t("english")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  value={"French"}
                  checked={required_languages.includes("French")}
                  id="required_languages_French"
                  onChange={(e) => RequiredLanguages(e)}
                  name="French"
                />
                <p className="pl-2 text-sectext">{t("french")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  value={"German"}
                  checked={required_languages.includes("German")}
                  id="required_languages_German"
                  onChange={(e) => RequiredLanguages(e)}
                  name="German"
                />
                <p className="pl-2 text-sectext">{t("German")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2 sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  value={"Italian"}
                  checked={required_languages.includes("Italian")}
                  id="required_languages_Italian"
                  onChange={(e) => RequiredLanguages(e)}
                  name="Italian"
                />
                <p className="pl-2 text-sectext">{t("Italian")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  value={"Portuguese"}
                  checked={required_languages.includes("Portuguese")}
                  id="required_languages_Portuguese"
                  onChange={(e) => RequiredLanguages(e)}
                  name="Portuguese"
                />
                <p className="pl-2 text-sectext">{t("Portuguese")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  value={"Spanish"}
                  checked={required_languages.includes("Spanish")}
                  id="required_languages_Spanish"
                  onChange={(e) => RequiredLanguages(e)}
                  name="Spanish"
                />
                <p className="pl-2 text-sectext">{t("Spanish")}</p>
              </span>
            </div>
            <div className="w-5/12 mt-5">
              <TextInput
                textType={"text"}
                text={t("others")}
                value={otherRequiredLanguage}
                handleChange={(e) => setOtherRequiredLanguage(e.target.value)}
                id="others"
              />
            </div>
            <span className="text-red-600 absolute top-56 sm_ss:top-36 sm_s:top-36 left-1 text-xs">
              {error && errMsg?.required_languages}
            </span>
          </div>
          <div
            ref={scrollState === "work_types" ? scrollTo : noScroll}
            className="flex flex-col relative"
          >
            <p className="text-sectext my-3 font-semibold">{t("TypeOFWork")}</p>
            <div className="flex flex-row flex-wrap sm_ss:h-36 sm_s:h-36 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap sm_ss:flex-wrap">
              <span className="w-1/4 flex flex-row items-center my-2 sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  checked={work_types.includes("Housework")}
                  className="h-6 w-6"
                  value={"Housework"}
                  name="Housework"
                  id="work_type_Housework"
                  onChange={(e) => WorkType(e)}
                />
                <p className="pl-2 text-sectext">{t("Housework")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  checked={work_types.includes("Childcare")}
                  className="h-6 w-6"
                  value={"Childcare"}
                  name="Childcare"
                  id="work_type_Childcare"
                  onChange={(e) => WorkType(e)}
                />
                <p className="pl-2 text-sectext">{t("Childcare")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  checked={work_types.includes("Gardening")}
                  value={"Gardening"}
                  name="Gardening"
                  id="work_type_Gardening"
                  onChange={(e) => WorkType(e)}
                />
                <p className="pl-2 text-sectext">{t("Gardening")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2 sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  value={"TableService"}
                  name="TabelService"
                  checked={work_types.includes("TableService")}
                  id="work_type_Tabelservice"
                  onChange={(e) => WorkType(e)}
                />
                <p className="pl-2 text-sectext">{t("TableService")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  value={"Cooking"}
                  name="Cooking"
                  checked={work_types.includes("Cooking")}
                  id="work_type_Cooking"
                  onChange={(e) => WorkType(e)}
                />
                <p className="pl-2 text-sectext">{t("Cooking")}</p>
              </span>
              <span className="w-1/4 flex flex-row items-center my-2  sm_s:w-1/2 sm_ss:w-1/2">
                <input
                  type="checkbox"
                  className="h-6 w-6"
                  value={"Laundry"}
                  name="Laundry"
                  checked={work_types.includes("Laundry")}
                  id="work_type_Laundry"
                  onChange={(e) => WorkType(e)}
                />
                <p className="pl-2 text-sectext">{t("Laundry")}</p>
              </span>
            </div>
            <div className="w-5/12 mt-5">
              <TextInput
                textType={"text"}
                text={t("others")}
                value={otherWorkWorkType}
                handleChange={(e) => setOtherWorkWorkType(e.target.value)}
                id="work_types_others"
              />
            </div>
            <span className="text-red-600 absolute top-56 sm_ss:top-36 sm_s:top-36 left-1 text-xs">
              {error && errMsg?.work_type}
            </span>
          </div>
          <div
            ref={scrollState === "accommodation_provided" ? scrollTo : noScroll}
            className="flex flex-col relative mt-3"
          >
            <p className="text-sectext pb-4 font-semibold">
              {t("AccommodationProvided")}
            </p>
            <div className="flex flex-row justify-between sm_ss:h-24 sm_s:h-24 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
              <div className=" flex w-9/12 justify-between sm_ss:flex-col sm_s:flex-col">
                <div className="flex flex-row items-center py-2 w-44 ">
                  <input
                    type={"radio"}
                    className="h-5 w-5"
                    name="Accommodation"
                    id="accommodation_provided"
                    onChange={(e) => handleChange(e)}
                    value={"yes"}
                    checked={state.accommodation_provided === "yes"}
                  />
                  <span className="pl-1 text-sectext">{t("Yes")}</span>
                </div>
                <div className="flex flex-row items-center w-96">
                  <input
                    type={"radio"}
                    className="h-5 w-5"
                    name="Accommodation"
                    id="accommodation_provided"
                    onChange={(e) => handleChange(e)}
                    value={"no"}
                    checked={state.accommodation_provided === "no"}
                  />
                  <span className="pl-1 text-sectext sm_ss:text-sm sm_s:text-sm">
                    {t("No")}
                  </span>
                </div>
              </div>
            </div>
            <span className="text-red-600 absolute top-18 2xl:top:24 sm_ss:top-36 sm_s:top-30 left-1 text-xs">
              {error && errMsg?.accommodation_provided}
            </span>
          </div>
          <div className="flex flex-col py-8 mt-3">
            <p className="text-sectext pb-4 font-semibold">
              {t("DesiredStartingDate")}
            </p>
            <div className="flex relative flex-row justify-start sm_ss:h-24 sm_s:h-18 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
              <div className="flex flex-row">
                <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44">
                  <input
                    type="radio"
                    value={"Immediately"}
                    checked={state.desired_starting === "Immediately"}
                    name="Desired starting date"
                    id="desired_starting"
                    className="h-5 w-5"
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="pl-1 text-sectext"> {t("Immediately")}</span>
                </div>
                <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                  <input
                    type="radio"
                    value={"To be agreed"}
                    checked={state.desired_starting === "To be agreed"}
                    name="Desired starting date"
                    id="desired_starting"
                    className="h-5 w-5"
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="pl-1 text-sectext"> {t("ToBeAgreed")}</span>
                </div>
              </div>
              <div className="flex flex-row">
                <div
                  ref={
                    scrollState === "desired_starting_date"
                      ? scrollTo
                      : noScroll
                  }
                  className="flex flex-col relative"
                >
                  <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                    <input
                      type="radio"
                      value={"Before"}
                      checked={state.desired_starting === "Before"}
                      name="Desired starting date"
                      id="desired_starting"
                      className="h-5 w-5"
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="pl-1 text-sectext">{t("Before")}</span>

                    {state.desired_starting === "Before" && (
                      <DatePickerComp
                        handleDate={(e) => setDesiredStartingDate(e._d)}
                        value={desiredDtartingDate}
                        name="desired_starting_date"
                        position={true}
                        required={true}
                        disablePast={true}
                        placeholder="Select before"
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-col relative">
                  <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                    <input
                      type="radio"
                      value={"Not before"}
                      checked={state.desired_starting === "Not before"}
                      name="Desired starting date"
                      id="desired_starting"
                      className="h-5 w-5"
                      onChange={handleChange}
                    />
                    <span className="pl-1 text-sectext">{t("NotBefore")}</span>

                    {state.desired_starting === "Not before" && (
                      <DatePickerComp
                        handleDate={(e) => setDesiredStartingDate(e._d)}
                        value={desiredDtartingDate}
                        name="desired_starting_date"
                        position={true}
                        required={true}
                        disablePast={true}
                        placeholder="Select not before"
                      />
                    )}
                  </div>
                </div>
              </div>
              <span className="text-red-600 absolute top-10 2xl:top:24 sm_ss:top-36 sm_s:top-30 left-1 text-xs">
                {error && errMsg?.desired_starting}
              </span>
            </div>
          </div>
          <div className="flex flex-col py-3">
            <p className="text-sectext pb-4 font-semibold">{t("Preference")}</p>
            <div className="flex flex-row justify-start">
              <div className="flex  flex-row items-center w-44 ">
                <input
                  type="radio"
                  value={"woman"}
                  checked={state.prefer_gender === "woman"}
                  name="Preference for a"
                  id="prefer_gender"
                  className="h-5 w-5"
                  onChange={(e) => handleChange(e)}
                />
                <span className="pl-1 text-sectext">{t("Woman")}</span>
              </div>
              <div className="flex  flex-row items-center w-44">
                <input
                  type="radio"
                  value={"man"}
                  name="Preference for a"
                  id="prefer_gender"
                  className="h-5 w-5"
                  checked={state.prefer_gender === "man"}
                  onChange={(e) => handleChange(e)}
                />
                <span className="pl-1 text-sectext">{t("Man")}</span>
              </div>
              <div className="flex  flex-row items-center w-44 ">
                <input
                  type="radio"
                  value={"indifferent"}
                  checked={state.prefer_gender === "indifferent"}
                  name="Preference for a"
                  id="prefer_gender"
                  className="h-5 w-5"
                  onChange={(e) => handleChange(e)}
                />
                <span className="pl-1 text-sectext">{t("Indifferent")}</span>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col py-8 w-7/12 sm_ss:w-full sm_s:w-full">
            <p className="text-sectext font-semibold py-3">
              {t("HideNationality")}
            </p>
            <Select
              isMulti={true}
              options={countries.map((val) => {
                return { value: val.name, label: val.name, id: val.id };
              })}
              defaultValue={blockedCountries}
              onChange={(e) => setBlocketCountries(e)}
            />
          </div> */}
        </ItemContainer>
        <ItemContainer bg={true}>
          <p className="text-sectext text-sm">{t("EMPLOYER_AGREETERMS")}</p>
          <div className="py-3" />
          <p className="text-sectext text-sm"> {t("EMPLOYER_AGREETERMS2")}</p>
          <div className="py-4" />
          <div className="flex items-center">
            <input
              onClick={(e) => setAgree(!agree)}
              type={"checkbox"}
              className="h-6 w-6"
              value={agree}
              checked={agree === false}
            />
            <span className="text-sectext pl-2">{t("Terms")}</span>
          </div>
        </ItemContainer>
        <div className="flex flex-col py-7 sm_ss:py-5 sm_s:py-4 justify-star sm_ss:w-full sm_s:w-full sm_s:justify-center sm_s:items-center sm_ss:justify-center sm_ss:items-center">
          {!!serverError?.error?.email && (
            <span className="text-red-600">{serverError?.error?.email[0]}</span>
          )}
          {!!serverError?.error?.mobile_number && (
            <span className="text-red-600">
              {serverError?.error?.mobile_number[0]}
            </span>
          )}
          <div className="my-5 sm_s:pb-2">
            <button
              disabled={agree || error}
              style={{
                backgroundColor: `${agree ? "#CECECE" : "#004993"}`,
              }}
              type="submit"
              className=" uppercase text-white font-bold text-sm md:py-2 w-60 rounded-md sm:p-2 sm_ss:p-2 sm_s:p-2 md:pb-2"
            >
              {pending ? (
                <CircularProgress size="sm" color="primary" />
              ) : (
                t("Send")
              )}
            </button>
          </div>
          <p className="text-sectext text-xs sm_ss:text-base sm_s:text-base sm_ss:text-center sm_s:text-center sm_ss:p-4 sm_s:px-10 sm_s:py-6">
            {t("EMPLOYER_RESPOSIBILITYTEXT")}
          </p>
        </div>
      </form>
    </Container>
  );
}

export default NewRequestFormEmployer;
