import { createSlice } from "@reduxjs/toolkit"

const SuspendUserAcc = createSlice({
  name: "SuspendUserAcc",
  initialState: {
    reason: "",
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    SuspendUserAccStart: (state) => {
      state.pending = true
      state.error = false
      state.success = false
      state.reason = ""
    },
    SuspendUserAccSuccess: (state, action) => {
      state.pending = false
      state.reason = action.payload
      state.error = false
      state.success = true
    },
    SuspendUserAccError: (state) => {
      state.pending = false
      state.error = true
      state.success = false
      state.reason = ""
    },
    SuspendUserAccReset: (state) => {
      state.reason = ""
      state.error = false
      state.success = false
      state.pending = false
    },
  },
})

export const {
  SuspendUserAccStart,
  SuspendUserAccSuccess,
  SuspendUserAccError,
  SuspendUserAccReset,
} = SuspendUserAcc.actions
export default SuspendUserAcc.reducer
