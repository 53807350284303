import React, { useState } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

function DatePickerComp({
  name,
  label,
  value,
  handleDate,
  position,
  required,
  error,
  disableFuture,
  disablePast,
  placeholder,
}) {
  // const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div
        className={`min-w-40 w-full ${
          position ? "absolute top-8 -left-2" : ""
        }`}
      >
        <div className="mb-2 text-sectext">{label}</div>
        <DatePicker
          // open={open}
          // onOpen={() => setOpen(true)}
          // onClose={() => setOpen(false)}
          value={value}
          inputFormat="DD/MM/yyyy"
          onChange={(newValue) => handleDate(newValue)}
          disableFuture={disableFuture}
          disablePast={disablePast}
          allowSameDateSelection
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              className={`bg-white rounded-lg w-full ${
                error ? "border border-red-600" : ""
              }`}
              size="small"
              id={name}
              required={required}
              inputProps={{
                ...params.inputProps,
                placeholder: placeholder,
              }}
              // onClick={() => setOpen(true)}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  )
}
export default DatePickerComp
