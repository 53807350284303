import { createSlice } from "@reduxjs/toolkit";

export const employeeSignUp = createSlice({
  name: "employeeSignUp",
  initialState: {
    pending: false,
    error: [],
    success: false,
  },
  reducers: {
    employeeSignUpStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    employeeSignUpSuccess: (state, action) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    employeeSignUpError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
    },
    resetEmployeeSignup: (state) => {
      state.success = false;
    },
  },
});

export const {
  employeeSignUpError,
  employeeSignUpStart,
  employeeSignUpSuccess,
  resetEmployeeSignup,
} = employeeSignUp.actions;
export default employeeSignUp.reducer;
