import { createSlice } from "@reduxjs/toolkit";

export const userSession = createSlice({
  name: "userSession",
  initialState: {
    pending: false,
    error: false,
    success: false,
    isAuthenticated: "",
  },
  reducers: {
    userSessionStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    userSessionSuccess: (state, action) => {
      state.isAuthenticated = action.payload;
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    userSessionError: (state, action) => {
      state.pending = false;
      state.error = true;
      state.success = false;
      state.email = "";
      state.isAuthenticated = action.payload;
    },
  },
});

export const { userSessionError, userSessionStart, userSessionSuccess } =
  userSession.actions;
export default userSession.reducer;
