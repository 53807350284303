import React from "react";

const ItemContainer = ({ children, bg, className }) => {
  return (
    <div
      className={`py-8 px-10 border ${
        bg && "sm_ss:bg-white" && "sm_s:bg-white && bg-white"
      } bg-transparent relative sm_ss:px-6 sm_s:px-6 sm_ss:border-0 sm_s:border-0 sm_ss:w-full sm_s:w-full border-textGray flex justify-start flex-col my-4 rounded-lg ${className}`}
    >
      {children}
    </div>
  );
};

export default ItemContainer;
