export const appRoutes = {
  Home: "/",
  EmployerLoginStep1: "/login/employer",
  EmployeeLoginStep1: "/login/employee",
  EmployerLoginStep2: "/login-step2/employer",
  EmployeeLoginStep2: "/login-step2/employee",
  EmployerLoginStep3: "/login",
  EmployeeLoginStep3: "/login",
  EmployerSignUp: "/employer-signup",
  SignUpSuccess: "/signup-success",
  EmployerHome: "/home",
  EmployeeSignup: "/employee-signup",
  EmployerAbout: "/employer-about",
  EmployeeAbout: "/employee-about",
  ResetPasswordEmployer: "/reset-password-employer",
  ResetPasswordEmployee: "/reset-password-employee",
  SetPassword: "/reset-password/:id",
  SetPasswordSuccess: "/reset-password-success",
  SetPasswordSuccessEmployee: "/reset-password-success-employee",
  RESETPASSWORDSUBMIT: "/reset-password-submit",
  EmployerDashboard: "/employer-dashboard",
  EmployeeDashboard: "/employee-dashboard",
  pendingActivation: "/pending-activation",
  NEWEMPLOYEMENTREQUEST: "/new-request/employer",
  NEWEMPLOYEMENTREQUESTEMPLOYEE: "/new-request/employee",

  termsandconditionen: "/en/terms-condition",
  termsandconditionfn: "/fr/terms-condition",

  privacypolicyen: "/en/privacy-policy",
  privacypolicyfn: "/fr/privacy-policy",
};
