import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import TextInput from "../components/TextInput";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { cardTypes } from "../data";
import moment from "moment";
import {
  Canton,
  CheckUserSession,
  Countries,
  employeeProfileUpdate,
} from "../redux/apiCalls";
import Container from "../components/Container";
import ItemContainer from "../components/ItemContainer";
import FieldContainer from "../components/FieldContainer";
import Separater from "../components/Separater";
import { fetchUserProfile } from "../redux/apiCalls";
import SelectInput from "../components/SelectInput";
import BackButton from "../components/BackButton";
import { appRoutes } from "../constants/routeConstants/Routes";
import { useNavigate } from "react-router-dom";
import { employeeProfileUpdateReset } from "../redux/slices/EmployeeProfileUpdate";
import Loader from "../components/Loader";
import DatePickerComp from "../components/DatePicker";
import { employeeNextPageFormValidations } from "../utils.js/FormValidations";
import { EmployeeAbout } from "../utils.js/utils";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { titleOptions, titleOptionsfr } from "../constants/Common/Common";
import ImageModal from "../components/ImageModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import MobilePhoneInput from "../components/MobilePhoneInput";
const AboutEmployee = () => {
  const date = new Date();
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const success = useSelector((state) => state.employeeProfileUpdate.success);
  const Error = useSelector((state) => state.employeeProfileUpdate.error);
  const pending = useSelector((state) => state.employeeProfileUpdate.pending);
  const is_new_request_created = useSelector(
    (state) => state.employeeProfileUpdate.is_new_request_created
  );
  const isAuthenticated = useSelector(
    (state) => state.userSession.isAuthenticated
  );
  const AuthError = useSelector((state) => state.userSession.error);
  const userProfile = useSelector((state) => state.fetchUserProfile.userInfo);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [Nationality, SetNationality] = useState([]);
  const [showNextForm, setShowNextForm] = useState(false);
  const [title, setTitle] = useState("");
  const [countries, setCountries] = useState([]);
  const [legitimation_card, setLegitimation_card_type] = useState("");
  const [cantons, setCanton] = useState([]);
  const [cantonsData, setCantonData] = useState([]);
  const [blockedCountries, setBlocketCountries] = useState([]);
  const [blocked_countries, setblocked_countries] = useState([]);
  const [images, setImages] = useState([]);
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(false);
  const [legitimation_card_images, setLegitimation_card_images] = useState([]);
  const [required_languages, setRequired_languages] = useState([]);
  const [work_types, setWorkType] = useState([]);
  const [work_places, setWork_places] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [confPasswordShown, setConfPasswordShown] = useState(false);
  const [otherRequiredLanguage, setOtherRequiredLanguage] = useState();
  const [otherWorkWorkType, setOtherWorkWorkType] = useState();
  const [desiredStartingDate, setDesiredStartingDate] = useState(date);
  const [dob, setDob] = useState();
  const [errMsg, setErrMsg] = useState();
  const [scrollState, setScrollState] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [nextPageError, setNextPageError] = useState(false);
  const [nextPageErrorCheck, setNextPageErrorCheck] = useState(false);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    country_code: "+91",
    old_password: "",
    password: "",
    confirmpassword: "",
    message_prefer_language: "",
    legitimation_card_number: "",
    accommodation_provided: "",
    street_number: "",
    zip_code: "",
    city: "",
    desired_starting: "",
    country: "Switzerland",
  });

  const user_type = "employee";
  const scrollTo = useRef();
  const noScroll = useRef();
  const language_selected = Cookies.get("i18next");

  useLayoutEffect(() => {
    fetchUserProfile(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (scrollState && scrollTo?.current?.scrollIntoView) {
      scrollTo.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [scrollState, scrollTo?.current?.scrollIntoView]);

  useEffect(() => {
    const renew_status = true;
    let canton = cantonsData?.id;
    let nationality =
      Nationality?.length > 0 ? Nationality?.map((el) => el?.id) : [];
    const date_of_birth = moment(dob).format("DD-MM-YYYY");
    const legitimation_card_type = legitimation_card?.value;
    const other_required_language = otherRequiredLanguage;
    const other_work_type = otherWorkWorkType;
    const desired_starting_date =
      moment(desiredStartingDate).format("DD-MM-YYYY");
    setUserData({
      ...state,
      date_of_birth,
      user_type,
      title,
      legitimation_card_type,
      canton,
      blocked_countries,
      work_types,
      work_places,
      required_languages,
      legitimation_card_images,
      nationality,
      dob,
      other_required_language,
      other_work_type,
      desired_starting_date,
      renew_status,
      language_selected,
    });
  }, [
    blockedCountries,
    state,
    work_types,
    required_languages,
    cantonsData,
    Nationality,
    title,
    work_places,
    images,
    legitimation_card,
    dob,
    blocked_countries,
    legitimation_card_images,
    otherRequiredLanguage,
    otherWorkWorkType,
    desiredStartingDate,
    language_selected,
  ]);

  useEffect(() => {
    if (isSubmitted) {
      let formErrors = EmployeeAbout(userData, cantonsData);
      if (Object.keys(formErrors).length) {
        setError(true);
        setErrMsg(formErrors);
      } else {
        setError(false);
      }
    }
  }, [isSubmitted, state, userData, dob, cantonsData]);

  useEffect(() => {
    if (nextPageErrorCheck) {
      let NextPageformErrors = employeeNextPageFormValidations(userData);
      if (Object.keys(NextPageformErrors).length) {
        setNextPageError(true);
        setErrMsg(NextPageformErrors);
        setScrollState(Object.keys(NextPageformErrors)[0]);
      } else {
        setNextPageError(false);
      }
    }
  }, [
    required_languages,
    work_types,
    work_places,
    state.accommodation_provided,
    userData,
    state,
    work_places,
    nextPageErrorCheck,
  ]);

  useEffect(() => {
    if (success === true) {
      navigate(appRoutes.pendingActivation);
    } else if (is_new_request_created === false) {
      setError(true);
    }
  }, [navigate, success, is_new_request_created]);

  useEffect(() => {
    if (is_new_request_created === false) {
      MySwal.fire({
        title: (
          <p style={{ fontFamily: "museo-sans, sans-serif", fontSize: "16px" }}>
            {t("NoUpdation")}
          </p>
        ),
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          navigate(appRoutes.EmployeeDashboard);
        }
      });
      setError(false);
      dispatch(employeeProfileUpdateReset());
    }
  }, [MySwal, dispatch, is_new_request_created, navigate, t]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, [showNextForm]);

  useEffect(() => {
    CheckUserSession(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (AuthError === true) {
      localStorage.clear();
    }
  }, [AuthError]);

  useEffect(() => {
    Countries(setCountries, language_selected);
    Canton(212, setCanton, language_selected);
  }, [language_selected]);

  useEffect(() => {
    const data = blockedCountries?.map((val) => {
      return val.id;
    });
    setblocked_countries(data);
  }, [blockedCountries]);

  useEffect(() => {
    localStorage.setItem("canton_name", userProfile?.canton_data?.name);
    setOtherRequiredLanguage(
      userProfile?.latest_request?.other_required_language
    );
    setOtherWorkWorkType(userProfile?.latest_request?.other_work_type);
    setTitle(userProfile?.title);
    setDob(userProfile?.date_of_birth);
    setDesiredStartingDate(userProfile?.latest_request?.desired_starting_date);
    if (userProfile?.last_request?.required_languages_data) {
      setRequired_languages(userProfile?.last_request?.required_languages_data);
    }
    if (userProfile?.last_request?.work_types_data) {
      setWorkType(userProfile?.last_request?.work_types_data);
    }

    setWork_places(userProfile?.last_request?.work_places_data);

    setState({
      first_name: userProfile?.name ? userProfile.name : "",
      last_name: userProfile?.last_name ? userProfile.last_name : "",
      mobile_number: userProfile?.mobile_number
        ? userProfile?.mobile_number
        : "",
      message_prefer_language: userProfile?.user_details
        ?.message_prefer_language
        ? userProfile?.user_details?.message_prefer_language
        : "",
      email: userProfile?.email ? userProfile?.email : "",
      country_code: "+91",
      legitimation_card_number: userProfile?.user_details
        ?.legitimation_card_number
        ? userProfile?.user_details?.legitimation_card_number
        : "",
      accommodation_provided: userProfile?.latest_request
        ?.is_accommodation_provided
        ? userProfile?.latest_request?.is_accommodation_provided
        : "",
      street_number: userProfile?.user_details?.street_number
        ? userProfile?.user_details?.street_number
        : "",
      zip_code: userProfile?.zip_code ? userProfile?.zip_code : "",
      city: userProfile?.city_id ? userProfile?.city_id : "",
      desired_starting: userProfile?.last_request?.desired_starting
        ? userProfile?.last_request?.desired_starting
        : "",
      desired_starting_date: userProfile?.last_request?.desired_starting_date
        ? userProfile?.last_request?.desired_starting_date
        : "",
      country: "Switzerland",
    });
  }, [
    userProfile,
    // userProfile?.last_request?.required_languages_data,
    // userProfile?.last_request.work_types_data,
  ]);

  useEffect(() => {
    const nationalityValue = userProfile?.nationality?.map((el) => {
      return {
        value: el?.name,
        label: el?.name,
        id: el?.id,
      };
    });
    const legitimation_card_typeValue = {
      value: userProfile?.user_details?.legitimation_card_type,
      label: userProfile?.user_details?.legitimation_card_type,
    };
    const cantonValue = {
      value: userProfile?.canton_data?.name,
      label: userProfile?.canton_data?.name,
      id: userProfile?.canton_data?.id,
    };
    setCantonData(cantonValue);

    const blockedCountriesData =
      userProfile?.latest_request?.blocked_countries_data?.map((country) => {
        return { label: country.name, value: country.name, id: country.id };
      });
    const img = userProfile?.card_images_data?.map((img) => {
      return img;
    });
    setImages(img);
    setLegitimation_card_images(img);
    setBlocketCountries(blockedCountriesData);
    setLegitimation_card_type(legitimation_card_typeValue);
    SetNationality(nationalityValue);
  }, [userProfile]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleOldPassword = () => {
    setOldPasswordShown(!oldPasswordShown);
  };
  const toggleCongPassword = () => {
    setConfPasswordShown(!confPasswordShown);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const RequiredLanguages = (e) => {
    if (e.target.checked) {
      setRequired_languages([...required_languages, e.target.value]);
    } else {
      const Array = [...required_languages];
      Array.splice(required_languages.indexOf(e.target.value), 1);
      setRequired_languages(Array);
    }
  };

  const WorkType = (e) => {
    if (e.target.checked) {
      setWorkType([...work_types, e.target.value]);
    } else {
      const Array = [...work_types];
      Array.splice(work_types.indexOf(e.target.value), 1);
      setWorkType(Array);
    }
  };

  const WorkPlaces = (e) => {
    if (e.target.checked) {
      setWork_places([...work_places, e.target.value]);
    } else {
      const Array = [...work_places];
      Array.splice(work_places.indexOf(e.target.value), 1);
      setWork_places(Array);
    }
  };

  const handlePageError = (e) => {
    e.preventDefault();
    const formErrors = EmployeeAbout(userData, cantonsData);
    if (Object.keys(formErrors).length) {
      setError(true);
      setErrMsg(formErrors);
      setScrollState(Object.keys(formErrors)[0]);
    } else {
      setError(false);
      setErrMsg();
    }
    setIsSubmitted(true);
    if (Object.keys(formErrors).length === 0) {
      setShowNextForm(true);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setNextPageErrorCheck(true);
    if (isAuthenticated === true) {
      localStorage.setItem("canton_name", cantonsData?.label);
      let NextPageformErrors = employeeNextPageFormValidations(userData);
      if (Object.keys(NextPageformErrors).length) {
        setNextPageError(true);
        setErrMsg(NextPageformErrors);
        setScrollState(Object.keys(NextPageformErrors)[0]);
      } else {
        setNextPageError(false);
      }
      if (!Object.keys(NextPageformErrors).length) {
        employeeProfileUpdate(dispatch, userData);
      }
    } else {
      localStorage.clear();
      navigate(appRoutes.Home);
    }
  };

  const cantonName = localStorage.getItem("canton_name");

  const cantonValue = cantonName
    ? cantonName
    : userInfo?.data?.result?.canton_id;

  const Options = language_selected === "en" ? titleOptions : titleOptionsfr;

  return (
    <Container delAcc={true}>
      <form className="flex w-full flex-col" onSubmit={onSubmit}>
        {showNextForm === false && (
          <ItemContainer>
            <span className="text-textGray uppercase font-bold py-2 sm_ss:text-center sm_s:text-center sm_ss:mb-6 sm_s:mb-6">
              {t("PersonalDetails")}
            </span>

            <div
              ref={scrollState === "title" ? scrollTo : noScroll}
              className="w-1/3 py-3 flex flex-col relative mb-2 sm_s:w-1/2 sm_ss:w-1/2"
            >
              <p className="text-sectext py-1">{t("Title")}</p>
              <select
                className="bg-white outline-none p-2 border border-textGray rounded-md"
                name="title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              >
                <option value="">{t("PleaseSelect")}</option>
                {Options?.map((val, index) => {
                  return (
                    <option
                      selected={val.value === title}
                      value={val?.value}
                      key={index}
                    >
                      {val?.label}
                    </option>
                  );
                })}
              </select>
              {error && (
                <span className="text-red-600 absolute top-20 left-3 text-xs py-1">
                  {errMsg?.title}
                </span>
              )}
            </div>
            <FieldContainer>
              <div
                ref={scrollState === "first_name" ? scrollTo : noScroll}
                className="flex justify-center w-full relative flex-1 sm_s:w-full sm_ss:w-full sm_ss:justify-start sm_s:justify-start"
              >
                <TextInput
                  text={t("FName")}
                  textType={"text"}
                  placeholder={t("Fname")}
                  name="first_name"
                  value={state.first_name}
                  handleChange={handleChange}
                  id="first_name"
                  pattern="^[A-Za-z0-9]{3,16}$"
                />
                <span className="text-red-600 absolute top-18 left-3 text-xs">
                  {error && errMsg?.first_name}
                </span>
              </div>
              <Separater />
              <div
                ref={scrollState === "last_name" ? scrollTo : noScroll}
                className="flex justify-center sm_ss:justify-start sm_s:justify-start relative flex-1 sm_s:w-full sm_ss:w-full"
              >
                <TextInput
                  text={t("LName")}
                  textType={"text"}
                  placeholder={t("Lname")}
                  name="last_name"
                  value={state.last_name}
                  handleChange={handleChange}
                  id="last_name"
                  pattern="^[A-Za-z0-9]{3,16}$"
                />
                <span className="text-red-600 absolute top-18 left-3 text-xs">
                  {error && errMsg?.last_name}
                </span>
              </div>
            </FieldContainer>
            <FieldContainer>
              <div
                ref={scrollState === "nationality" ? scrollTo : noScroll}
                className="flex justify-start w-full sm_ss:w-full sm_s:w-full"
              >
                <div className="w-full flex flex-col h-24 flex-1 relative sm_s:w-full sm_ss:w-full sm_ss:justify-start sm_s:justify-start">
                  <p className="text-sectext pb-2">{t("Nationality")}</p>
                  <Select
                    onChange={(e) => SetNationality(e)}
                    options={countries?.map((val) => {
                      return {
                        value: val.name,
                        label: val.name,
                        id: val.id,
                      };
                    })}
                    id="nationality"
                    value={Nationality}
                    placeholder={t("Select Nationality")}
                    isMulti={true}
                  />
                  <span className="text-red-600 absolute top-18 left-3 text-xs ">
                    {error && errMsg?.nationality}
                  </span>
                </div>
              </div>
              <Separater />
              <div
                ref={scrollState === "date_of_birth" ? scrollTo : noScroll}
                className="flex pl-3 sm_ss:pl-0 sm_s:pl-0 w-full sm_ss:w-full sm_s:w-full"
              >
                <div className="flex w-full flex-col flex-1 h-24 relative sm_s:w-full sm_ss:w-full">
                  <div className="w-full">
                    <DatePickerComp
                      handleDate={(e) => setDob(e)}
                      label={t("DOB")}
                      value={dob}
                      name="date_of_birth"
                      disableFuture={true}
                    />
                  </div>
                  <span className="text-red-600 absolute top-18 left-3 text-xs">
                    {error && errMsg?.date_of_birth}
                  </span>
                </div>
              </div>
            </FieldContainer>
            <FieldContainer>
              <div className="flex w-full flex-col relative">
                <TextInput
                  text={t("Email")}
                  textType={"email"}
                  placeholder={t("Email")}
                  value={state.email}
                  handleChange={handleChange}
                  id="email"
                  pattern="^[A-Za-z0-9]{3,16}$"
                  disable={true}
                />
              </div>
              <Separater />
              <Separater />
              <div className="flex w-full flex-col relative">
                <MobilePhoneInput
                  text={t("Mobile")}
                  placeholder={t("Mobile")}
                  country={"ch"}
                  inputStyle={{ height: "40px", width: "inherit" }}
                  value={state.mobile_number}
                  onChange={(e) => setState({ ...state, mobile_number: e })}
                />
              </div>
            </FieldContainer>
          </ItemContainer>
        )}
        {showNextForm === false && (
          <ItemContainer>
            <p className="text-textGray py-3">{t("AccountPassword")}</p>
            <FieldContainer className="flex-col">
              <div
                ref={scrollState === "oldPassword" ? scrollTo : noScroll}
                className="flex justify-start flex-1 w-1/2 relative sm_s:w-full sm_ss:w-full"
              >
                <TextInput
                  text={t("OldPassword")}
                  textType={`${oldPasswordShown ? "text" : "password"}`}
                  placeholder={"*********"}
                  value={state.old_password}
                  handleChange={handleChange}
                  id="old_password"
                  pattern="^[A-Za-z0-9]{3,16}$"
                  showEyeButton={true}
                  togglePassword={toggleOldPassword}
                  oldPasswordShown={passwordShown}
                />
                {error && (
                  <span className="text-red-600 absolute top-18 left-3 text-xs py-1">
                    {errMsg?.oldPassword}
                  </span>
                )}
              </div>
              <div
                ref={scrollState === "password" ? scrollTo : noScroll}
                className="flex justify-start flex-1 w-1/2 relative sm_s:w-full sm_ss:w-full"
              >
                <TextInput
                  text={t("password")}
                  textType={`${passwordShown ? "text" : "password"}`}
                  placeholder={"*********"}
                  value={state.password}
                  handleChange={handleChange}
                  id="password"
                  pattern="^[A-Za-z0-9]{3,16}$"
                  showEyeButton={true}
                  togglePassword={togglePassword}
                  passwordShown={passwordShown}
                />
                {error && (
                  <span className="text-red-600 absolute top-18 left-3 text-xs py-1">
                    {errMsg?.password}
                  </span>
                )}
              </div>
              <Separater />
              <div
                ref={scrollState === "passwordConfirm" ? scrollTo : noScroll}
                className="flex justify-end sm_ss:justify-start sm_s:justify-start flex-1 w-1/2 relative sm_s:w-full sm_ss:w-full"
              >
                <TextInput
                  text={t("repeatPassword")}
                  textType={`${confPasswordShown ? "text" : "password"}`}
                  placeholder={"*********"}
                  value={state.confirmpassword}
                  handleChange={handleChange}
                  id="confirmpassword"
                  pattern="^[A-Za-z0-9]{3,16}$"
                  showEyeButton={true}
                  togglePassword={toggleCongPassword}
                  passwordShown={confPasswordShown}
                />
                {error && (
                  <span className="text-red-600 absolute top-18 left-3 text-xs py-1">
                    {errMsg?.passwordConfirm}
                  </span>
                )}
              </div>
            </FieldContainer>
          </ItemContainer>
        )}
        {showNextForm === false && (
          <ItemContainer>
            <p className="text-textGray py-3">{t("Messages")}</p>

            <p className="text-sectext">{t("PrefferMessageLang")}</p>
            <div className="w-full flex flex-col justify-between py-2 relative sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <div className="flex items-center my-4">
                <input
                  type="radio"
                  name="preferLanguage"
                  className="h-6 w-6"
                  value={"English"}
                  checked={state.message_prefer_language === "English"}
                  id="message_prefer_language"
                  onChange={(e) => handleChange(e)}
                />
                <span className="text-sectext ml-1">{t("English")}</span>
              </div>
              <div className="flex items-center my-1 ">
                <input
                  type="radio"
                  name="preferLanguage"
                  className="h-6 w-6"
                  value={"French"}
                  checked={state.message_prefer_language === "French"}
                  onChange={(e) => handleChange(e)}
                  id="message_prefer_language"
                />
                <span className="text-sectext ml-1">{t("French")}</span>
              </div>
            </div>
          </ItemContainer>
        )}
        {showNextForm === false && (
          <ItemContainer>
            <p className="text-textGray py-1 pb-6">{t("LEGITIMATIONCARD")}</p>
            <div className="flex flex-col sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full">
              <FieldContainer>
                <div className="flex flex-col h-24 relative sm_s:w-full sm_ss:w-full">
                  <p className="text-sectext mb-2">
                    {t("LEGITIMATIONCARDTYPE")}
                  </p>
                  <Select
                    isDisabled={true}
                    options={cardTypes}
                    value={legitimation_card}
                    onChange={(e) => setLegitimation_card_type(e.value)}
                    placeholder={t("Select")}
                  />
                </div>
                <Separater />
                <div className="flex flex-col relative sm_s:w-full sm_ss:w-full">
                  <TextInput
                    disable={true}
                    text={t("LEGITIMATIONCARD")}
                    textType={"number"}
                    placeholder={t("LEGITIMATIONCARD")}
                    value={state.legitimation_card_number}
                    handleChange={handleChange}
                    id="legitimation_card_number"
                  />
                </div>
              </FieldContainer>
              <p className="text-sectext">{t("UploadedImages")}</p>
              <div className="flex flex-wrap justify-start w-full p-2">
                {images &&
                  images?.map((val, index) => (
                    <div key={index} className="flex flex-row rounded-sm mt-6 ">
                      {/* <div className="flex item-center h-28 pr-3 sm_ss:pr-4 sm_s:pr-4">
                        <img
                          src={val}
                          alt="card"
                          width={"176px"}
                          height={"96px"}
                          className="rounded-md object-cover bg-black"
                        />
                      </div> */}
                      <ImageModal url={val} />
                    </div>
                  ))}
              </div>
            </div>
          </ItemContainer>
        )}
        {showNextForm === false && (
          <ItemContainer>
            <span className="text-textGray py-2 uppercase">
              {t("PRIVATE_ADDRESS")}
            </span>

            <div className="w-full flex relative sm_s:w-full sm_ss:w-full">
              <TextInput
                text={t("Street")}
                textType={"text"}
                placeholder={t("Street")}
                value={state.street_number}
                handleChange={handleChange}
                id="street_number"
                pattern="^[A-Za-z0-9]{3,16}$"
              />
            </div>
            <FieldContainer>
              <div className="w-1/2 pr-8 relative">
                <TextInput
                  text={t("ZipCode")}
                  textType={"number"}
                  placeholder={t("ZipCode")}
                  value={state.zip_code}
                  handleChange={handleChange}
                  id="zip_code"
                />
              </div>
              {/* <Separater /> */}
              <div className="w-11/12 sm_s:w-full sm_ss:w-full">
                <TextInput
                  text={t("City")}
                  textType={"text"}
                  placeholder={t("City")}
                  value={state.city}
                  handleChange={handleChange}
                  id="city"
                  pattern="^[A-Za-z0-9]{3,16}$"
                />
              </div>
            </FieldContainer>
            <FieldContainer>
              <div className="flex flex-1 flex-col h-24">
                <TextInput
                  text={t("Country")}
                  value={state.country}
                  disable={true}
                />
              </div>
              <Separater />
              <div className="flex flex-1 flex-col h-24 relative ">
                <SelectInput
                  title={t("Canton")}
                  options={cantons?.map((val) => {
                    return {
                      label: val.name,
                      value: val.name,
                      id: val.id,
                    };
                  })}
                  defaultvalue={cantonValue}
                  value={cantonsData}
                  handleselect={(e, value) => setCantonData(value)}
                  placeholder={t("Select canton")}
                />
                {error && (
                  <span className="text-red-600 absolute top-20 left-3 text-xs py-1">
                    {errMsg?.cantons}
                  </span>
                )}
              </div>
            </FieldContainer>
          </ItemContainer>
        )}
        {showNextForm && (
          <ItemContainer>
            <p className="text-textGray font-bold py-4 pb-8 sm_ss:text-center sm_s:text-center">
              {t("WorkProfile")}
            </p>
            <BackButton
              className={
                "left-0 -top-10 sm_s:left-0 sm_s:right-0 sm_s:m-auto sm_s:top-0 sm_ss:left-0 sm_ss:right-0 sm_ss:m-auto sm_ss:top-0 cursor-pointer absolute"
              }
              handlechangle={() => setShowNextForm(false)}
            />
            <div
              ref={scrollState === "required_languages" ? scrollTo : noScroll}
              className="flex flex-col relative "
            >
              <p className="text-sectext my-3">{t("RequiredLang")}</p>
              <div className="flex flex-row flex-wrap sm_ss:h-28 sm_s:h-28 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
                <span className="w-1/4 flex flex-row items-center my-2 sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={required_languages?.includes("English")}
                    className="h-6 w-6"
                    value={"English"}
                    onChange={(e) => RequiredLanguages(e)}
                    id="required_languages_English"
                    name="English"
                  />
                  <p className="pl-2 text-sectext">{t("english")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1 sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={required_languages?.includes("French")}
                    className="h-6 w-6"
                    value={"French"}
                    id="required_languages_French"
                    onChange={(e) => RequiredLanguages(e)}
                    name="French"
                  />
                  <p className="pl-2 text-sectext">{t("french")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1 sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={required_languages?.includes("German")}
                    className="h-6 w-6"
                    value={"German"}
                    id="required_languages_German"
                    onChange={(e) => RequiredLanguages(e)}
                    name="German"
                  />
                  <p className="pl-2 text-sectext">{t("German")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1 sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={required_languages?.includes("Italian")}
                    className="h-6 w-6"
                    value={"Italian"}
                    id="required_languages_Italian"
                    onChange={(e) => RequiredLanguages(e)}
                    name="Italian"
                  />
                  <p className="pl-2 text-sectext">{t("Italian")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1 sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={required_languages?.includes("Portuguese")}
                    className="h-6 w-6"
                    value={"Portuguese"}
                    id="required_languages_Portuguese"
                    onChange={(e) => RequiredLanguages(e)}
                    name="Portuguese"
                  />
                  <p className="pl-2 text-sectext">{t("Portuguese")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1 sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={required_languages?.includes("Spanish")}
                    className="h-6 w-6"
                    value={"Spanish"}
                    id="required_languages_Spanish"
                    onChange={(e) => RequiredLanguages(e)}
                    name="Spanish"
                  />
                  <p className="pl-2 text-sectext">{t("Spanish")}</p>
                </span>
              </div>
              <div className="w-1/2 mt-5">
                <TextInput
                  textType={"text"}
                  text={t("others")}
                  value={otherRequiredLanguage}
                  handleChange={(e) => setOtherRequiredLanguage(e.target.value)}
                  id="others"
                />
              </div>
              <span className="text-red-600 absolute top-56 2xl:top-56 sm_s:top-[160px] sm_ss:top-[160px] left-1 text-xs">
                {nextPageError && errMsg?.required_languages}
              </span>
            </div>
            <div
              ref={scrollState === "work_types" ? scrollTo : noScroll}
              className="flex flex-col relative"
            >
              <p className="text-sectext my-3">{t("TypeOFWork")}</p>
              <div className="flex flex-row flex-wrap sm_ss:h-28 sm_s:h-28 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={work_types.includes("Housework")}
                    className="h-6 w-6"
                    value={"Housework"}
                    name="Housework"
                    id="work_type_Housework"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Housework")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={work_types.includes("Childcare")}
                    className="h-6 w-6"
                    value={"Childcare"}
                    name="Childcare"
                    id="work_type_Childcare"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Childcare")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"Gardening"}
                    checked={work_types.includes("Gardening")}
                    name="Gardening"
                    id="work_type_Gardening"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Gardening")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={work_types.includes("TableService")}
                    className="h-6 w-6"
                    value={"TableService"}
                    name="TabelService"
                    id="work_type_Tabelservice"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("TableService")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={work_types.includes("Cooking")}
                    className="h-6 w-6"
                    value={"Cooking"}
                    name="Cooking"
                    id="work_type_Cooking"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Cooking")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    checked={work_types.includes("Laundry")}
                    className="h-6 w-6"
                    value={"Laundry"}
                    name="Laundry"
                    id="work_type_Laundry"
                    onChange={(e) => WorkType(e)}
                  />
                  <p className="pl-2 text-sectext">{t("Laundry")}</p>
                </span>
              </div>
              <div className="w-1/2 mt-5">
                <TextInput
                  textType={"text"}
                  text={t("others")}
                  value={otherWorkWorkType}
                  handleChange={(e) => setOtherWorkWorkType(e.target.value)}
                  id="others"
                />
              </div>
              <span className="text-red-600 absolute top-[220px] sm_s:top-[160px] sm_ss:top-[160px] 2xl:top-56 left-1 text-xs">
                {nextPageError && errMsg?.work_type}
              </span>
            </div>
            <div
              ref={scrollState === "work_places" ? scrollTo : noScroll}
              className="flex flex-col relative mb-[22px]"
            >
              <p className="text-sectext my-3">{t("Workplaces")}</p>
              <div className="flex flex-row flex-wrap sm_ss:h-28 sm_s:h-28 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"Geneva"}
                    checked={work_places.includes("Geneva")}
                    name="Housework"
                    id="Geneva_Vaud"
                    onChange={(e) => WorkPlaces(e)}
                  />
                  <p className="pl-2 text-sectext">{t("GenevaVaud")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"Bern"}
                    name="Bern / Fribourg"
                    checked={work_places.includes("Bern")}
                    id="Bern_Fribourg"
                    onChange={(e) => WorkPlaces(e)}
                  />
                  <p className="pl-2 text-sectext">{t("BernFribourg")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"Basel"}
                    checked={work_places.includes("Basel")}
                    name="Basel and area"
                    id="Basel_and_area"
                    onChange={(e) => WorkPlaces(e)}
                  />
                  <p className="pl-2 text-sectext">{t("BaselArea")}</p>
                </span>
                <span className="w-1/4 flex flex-row items-center my-1  sm_s:w-1/2 sm_ss:w-1/2">
                  <input
                    type="checkbox"
                    className="h-6 w-6"
                    value={"anywhere"}
                    checked={work_places.includes("anywhere")}
                    name="Anywhere in CH"
                    id="Anywhere_in_CH"
                    onChange={(e) => WorkPlaces(e)}
                  />
                  <p className="pl-2 text-sectext">{t("anywhere")}</p>
                </span>
              </div>
              <span className="text-red-600 absolute top-30 sm_s:top-[160px] sm_ss:top-[160px] left-1 text-xs">
                {nextPageError && errMsg?.work_places}
              </span>
            </div>
            <div
              ref={
                scrollState === "accommodation_provided" ? scrollTo : noScroll
              }
              className="flex flex-col relative py-4"
            >
              <p className="text-sectext pb-4">{t("NeedOfAccProvided")}</p>
              <div className="flex flex-row justify-start sm_ss:h-24 sm_s:h-24 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
                <div className="flex flex-row items-center py-2 w-44">
                  <input
                    type={"radio"}
                    className="h-5 w-5"
                    name="Accommodation"
                    id="accommodation_provided"
                    onChange={(e) => handleChange(e)}
                    value={"yes"}
                    checked={state.accommodation_provided === "yes"}
                  />
                  <span className="pl-1 text-sectext">{t("Yes")}</span>
                </div>
                <div className="flex flex-row items-center py-2 w-44">
                  <input
                    type={"radio"}
                    className="h-5 w-5"
                    name="Accommodation"
                    id="accommodation_provided"
                    onChange={(e) => handleChange(e)}
                    value={"no"}
                    checked={state.accommodation_provided === "no"}
                  />
                  <span className="pl-1 text-sectext">{t("no")}</span>
                </div>
                <div className="flex flex-row items-center py-2 w-44">
                  <input
                    type={"radio"}
                    className="h-5 w-5"
                    name="Accommodation"
                    id="accommodation_provided"
                    onChange={(e) => handleChange(e)}
                    value={"indifferent"}
                    checked={state.accommodation_provided === "indifferent"}
                  />
                  <span className="pl-1 text-sectext">{t("Indifferent")}</span>
                </div>
                <span className="text-red-600 absolute top-22 2xl:top-24 sm_s:top-40 left-1 2xl:left-1 text-xs">
                  {nextPageError && errMsg?.accommodation_provided}
                </span>
              </div>
              <span className="text-red-600 absolute top-22 2xl:top-24 sm_s:top-40 left-1 2xl:left-1 text-xs"></span>
            </div>
            <div className="flex flex-col py-4 mb-[10px]">
              <p className="text-sectext pb-4">{t("AvailabilityToStart")}</p>
              <div className="flex flex-row justify-start sm_ss:h-24 sm_s:h-24 sm_ss:flex-col sm_s:flex-col sm_s:flex-wrap">
                <div className="flex flex-row">
                  <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                    <input
                      type="radio"
                      value="Immediately"
                      checked={state.desired_starting === "Immediately"}
                      name="Desired starting date"
                      id="desired_starting"
                      className="h-5 w-5"
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="pl-1 text-sectext">
                      {t("Immediately")}
                    </span>
                  </div>
                  <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                    <input
                      type="radio"
                      value="To be agreed"
                      checked={state.desired_starting === "To be agreed"}
                      name="Desired starting date"
                      id="desired_starting"
                      className="h-5 w-5"
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="pl-1 text-sectext">{t("ToBeAgreed")}</span>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-col relative">
                    <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                      <input
                        type="radio"
                        value="Before"
                        checked={state.desired_starting === "Before"}
                        name="Desired starting date"
                        id="desired_starting"
                        className="h-5 w-5"
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="pl-1 text-sectext">{t("Before")}</span>

                      {state.desired_starting === "Before" && (
                        <DatePickerComp
                          handleDate={(e) => setDesiredStartingDate(e)}
                          value={desiredStartingDate}
                          name="desired_starting_date"
                          position={true}
                          disablePast={true}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col relative">
                    <div className="flex flex-row items-center sm_s:my-1 sm_ss:my-1 w-44 ">
                      <input
                        type="radio"
                        value="Not before"
                        checked={state.desired_starting === "Not before"}
                        name="Desired starting date"
                        id="desired_starting"
                        className="h-5 w-5"
                        onChange={handleChange}
                      />
                      <span className="pl-1 text-sectext">
                        {t("NotBefore")}
                      </span>

                      {state.desired_starting === "Not before" && (
                        <DatePickerComp
                          handleDate={(e) => setDesiredStartingDate(e)}
                          value={desiredStartingDate}
                          name="desired_starting_date"
                          required={state.desired_starting_date ? false : true}
                          position={true}
                          disablePast={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col py-8 w-7/12 sm_ss:w-full sm_s:w-full">
              <p className="text-sectext font-semibold py-3">
                {t("HideNationality")}
              </p>
              <Select
                isMulti={true}
                options={countries?.map((val) => {
                  return {
                    value: val.name,
                    label: val.name,
                    id: val.id,
                  };
                })}
                value={blockedCountries}
                onChange={(e) => setBlocketCountries(e)}
                placeholder={t("Select blocked countries")}
              />
            </div>
          </ItemContainer>
        )}
        {showNextForm && (
          <div className="flex flex-col py-7 sm_ss:py-5 sm_s:py-4 justify-star sm_ss:w-full sm_s:w-full sm_s:justify-center sm_s:items-center sm_ss:justify-center sm_ss:items-center">
            {/* {!!Error && (
              <span className="text-red-600">
                Something went wrong, please try again later
              </span>
            )} */}
            {!!Error?.error?.email && (
              <span className="text-red-600">{Error?.error?.email[0]}</span>
            )}
            {!!Error?.error?.mobile_number && (
              <span className="text-red-600">
                {Error?.error?.mobile_number[0]}
              </span>
            )}
            {!!Error?.old_password && (
              <span className="text-red-600">{Error?.old_password[0]}</span>
            )}
            <div className="my-5 sm_s:pb-2">
              <button
                type="submit"
                className=" uppercase bg-blue text-white font-bold text-sm h-12 w-32 rounded-md"
              >
                {pending ? <Loader /> : t("save")}
              </button>
            </div>

            <p className="text-sectext text-xs sm_ss:text-center sm_s:text-center sm:text-center">
              {t("EMPLOYEE_RESPOSIBILITYTEXT")}
            </p>
          </div>
        )}
        <div>
          {showNextForm ? null : (
            <div className="w-full flex flex-col items-center justify-between pb-10">
              <div className="w-full flex justify-start items-center sm_ss:justify-center sm_s:justify-center sm:justify-center md:justify-start">
                <button
                  disabled={error}
                  onClick={(e) => handlePageError(e)}
                  className="bg-blue disabled:bg-textGray disabled:cursor-not-allowed uppercase text-white font-bold text-sm py-2 px-5 mt-5 rounded-md "
                >
                  {t("continue")}
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};

export default AboutEmployee;
