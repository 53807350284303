import { t } from "i18next";
import { CD } from "./FormValidations";

export const EmployeeAbout = (data, canton) => {
  const errors = {};
  if (data.title === "" || data.title === undefined) {
    errors.title = t("titleErr");
  }

  if (data.first_name === "" || data.first_name === undefined) {
    errors.first_name = t("FnameErr");
  } else if (data.first_name.length < 2) {
    errors.first_name = t("FnameErrLnt");
  }

  if (data.last_name === "" || data.last_name === undefined) {
    errors.last_name = t("LnameErr");
  } else if (data.last_name.length < 2) {
    errors.last_name = t("LnameErrLnt");
  }

  if (data.date_of_birth === CD) {
    errors.date_of_birth = t("DOBErr");
  } else if (data.date_of_birth > CD) {
    errors.date_of_birth = t("FutureDOBErr");
  }

  if (!data.nationality) {
    errors.nationality = t("NationalityErr");
  }

  // if (data.mobile_number === "" || data.mobile_number === undefined) {
  //   errors.mobile_number = t("MobileNoErr");
  // } else if (data.mobile_number.length < 8 || data.mobile_number.length > 13) {
  //   errors.mobile_number = t("MobileNoErrLnt");
  // }

  if ((data.password || data.confirmpassword) && !data.old_password) {
    errors.oldPassword = "Please enter your old password";
  }
  if (data.password?.length < 8) {
    errors.password = t("PassLntErr");
  }
  if (data.password !== data.confirmpassword) {
    errors.passwordConfirm = t("ConfirmPassErr");
  }

  if (!canton) {
    errors.cantons = t("cantonErr");
  }

  return errors;
};

export const EmployerAboutErrCheck = (data, canton) => {
  const errors = {};
  if (data.title === "" || data.title === undefined) {
    errors.title = t("titleErr");
  }

  if (data.first_name === "" || data.first_name === undefined) {
    errors.first_name = t("FnameErr");
  } else if (data.first_name.length < 2) {
    errors.first_name = t("FnameErrLnt");
  }

  if (data.last_name === "" || data.last_name === undefined) {
    errors.last_name = t("LnameErr");
  } else if (data.last_name.length < 2) {
    errors.last_name = t("LnameErrLnt");
  }

  if (data.date_of_birth === CD) {
    errors.date_of_birth = t("DOBErr");
  } else if (data.date_of_birth > CD) {
    errors.date_of_birth = t("FutureDOBErr");
  }

  if (!data.nationality) {
    errors.nationality = t("NationalityErr");
  }
  // if (data.mobile_number === "" || data.mobile_number === undefined) {
  //   errors.mobile_number = t("MobileNoErr");
  // } else if (data.mobile_number.length < 8 || data.mobile_number.length > 13) {
  //   errors.mobile_number = t("MobileNoErrLnt");
  // }

  // if (data.organization_name === "" || data.organization_name === undefined) {
  //   errors.organization_name = t("SelectORG");
  // }

  // if (data.official_function === "" || data.official_function === undefined) {
  //   errors.official_function = t("officialfunctionErr");
  // }

  // if (data.work_email === "" || data.work_email === undefined) {
  //   errors.work_email = t("WorkEmailErr");
  // } else if (!validateEmail(data.work_email)) {
  //   errors.work_email = t("ValidEmailErr");
  // }

  if (data.number_of_persons === "" || data.number_of_persons === undefined) {
    errors.number_of_persons = t("NumberOfPersonErr");
  } else if (data.number_of_persons < 0) {
    errors.number_of_persons = t("ValidNumberOfPersonErr");
  }

  // if (
  //   data.legitimation_card_number === "" ||
  //   data.legitimation_card_number === undefined
  // ) {
  //   errors.legitimation_card_number = t("legitimateCardNoErr");
  // }

  if (
    data.legitimation_card_type === "" ||
    data.legitimation_card_type === undefined
  ) {
    errors.legitimation_card_type = t("legitimateCardTypeErr");
  }

  if ((data.password || data.confirmpassword) && !data.old_password) {
    errors.oldPassword = "Please enter your old password";
  }
  if (data.password?.length < 8) {
    errors.password = t("PassLntErr");
  }
  if (data.password !== data.confirmpassword) {
    errors.passwordConfirm = t("ConfirmPassErr");
  }

  if (!data.canton) {
    errors.cantons = t("cantonErr");
  }
  return errors;
};

export function scrollToTop() {
  if (window.scrollY !== 0) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}
