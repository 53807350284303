import { createSlice } from "@reduxjs/toolkit";

export const resetPassword = createSlice({
  name: "fetchUserProfile",
  initialState: {
    resetPassword: {
      status: "",
      statusText: "",
    },
    setPassword: {
      status: "",
      statusText: "",
    },
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    resetPasswordStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    resetPasswordSuccess: (state, action) => {
      state.resetPassword = action.payload;
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    resetPasswordError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
    },
    setPasswordStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    setPasswordSuccess: (state, action) => {
      state.setPassword = action.payload;
      state.pending = false;
      state.success = true;
      state.error = false;
    },
    setPasswordError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordError,
  setPasswordStart,
  setPasswordError,
  setPasswordSuccess,
} = resetPassword.actions;
export default resetPassword.reducer;
