import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "../components/Button";
import { appRoutes } from "../constants/routeConstants/Routes";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { reset } from "../redux/slices/slice";
import { useTranslation } from "react-i18next";

const ProfileConfirmation = ({ name, lastname, dob, nationality, pending }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const initial = lastname?.split("")[0];
  const { t } = useTranslation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const role = user?.data?.result?.role;
    const token = user?.data?.result?.access_token;
    if (user && token && role === "employer") {
      navigate(appRoutes.EmployerDashboard);
    }
    if (user && token && role === "employee") {
      navigate(appRoutes.EmployeeDashboard);
    }
  });

  console.log("nationality->", nationality);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);
  return (
    <>
      {pending ? (
        <div className="flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-full bg-secgray h-full flex justify-center items-center">
          <div className="max-w-4xl md:w-3xl flex flex-col sm_ss:w-full sm_s:w-full sm_s:px-1 smx:w-9/12 sm_ss:item-center">
            <p className="text-gray font-bold text-4xl py-8 sm_s:py-1 sm_s:hidden sm_ss:hidden lg:py-2">
              {t("ProfileConformation")}
            </p>
            <p className="text-sectext mb-1 sm_s:text-center sm_ss:text-center sm_s:font-semibold sm_s:text-base sm_s:py-8 sm_ss:py-8 sm_ss:font-semibold lg:py-1">
              {name && dob && nationality && t("ProfileConformationDesc")}
            </p>
            <div className="bg-white py-6 rounded-xl sm_ss:w-full sm_s:w-full sm_ss:rounded-none sm_s:rounded-none flex flex-col justify-center items-center 2xl:py-12">
              <div className="w-1/3 flex flex-col sm_s:w-full sm_ss:w-full sm_s:justify-center sm_s:items-center md:w-full md:justify-center smx:w-full">
                {name && nationality && dob && (
                  <>
                    <UserInfo title={t("Name")} value={`${name} ${initial}`} />
                    <UserInfo
                      title={t("nationality")}
                      value={nationality?.map((el, i) => {
                        return <p key={i}>{el}</p>;
                      })}
                    />
                    <UserInfo
                      title={t("ProfileConfDOB")}
                      value={moment(dob).format("D MMMM")}
                    />
                  </>
                )}
              </div>

              <div className="mt-5" />
              {name && nationality && dob ? (
                <Button
                  title={t("ThisIsME")}
                  route={
                    location.pathname.includes("employer")
                      ? appRoutes.EmployerLoginStep3
                      : appRoutes.EmployeeLoginStep3
                  }
                />
              ) : (
                <span className="text-sectext">{t("NoAccount")}</span>
              )}
              {name && nationality && dob ? (
                <Button
                  title={t("CreateNewProfile")}
                  route={
                    location.pathname.includes("employer")
                      ? appRoutes.EmployerSignUp
                      : appRoutes.EmployeeSignup
                  }
                />
              ) : (
                <Button
                  title={t("NewProfile")}
                  route={
                    location.pathname.includes("employer")
                      ? appRoutes.EmployerSignUp
                      : appRoutes.EmployeeSignup
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const UserInfo = ({ title, value }) => (
  <div className="flex  justify-center sm_s:justify-evenly py-1 sm_s:w-9/12 sm_ss:w-full w-64  md:self-center smx:text-center smx:w-9/12 smx:self-center">
    <span className="text-sectext  w-1/2 sm_s:text-center  capitalize mr-2">
      {title}:
    </span>
    <span className="text-sectext w-1/2  sm_s:text-center capitalize">
      {value}
    </span>
  </div>
);

export default ProfileConfirmation;
