import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import TextInput from "../components/TextInput";
import { userCheckEmail, userCheckNumber } from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import ProfileConfirmation from "./ProfileConfirmation";
import { reset } from "../redux/slices/slice";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { appRoutes } from "../constants/routeConstants/Routes";
import Cookies from "js-cookie";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import MobilePhoneInput from "../components/MobilePhoneInput";

const EmployerLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState();
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [showNextPage, setShowNextPage] = useState(false);
  const [submit, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.ProfileConformation.profileCon);
  const pending = useSelector((state) => state.ProfileConformation.pending);
  const { name, last_name, nationality, date_of_birth } = userData;

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const role = user?.data?.result?.role;
    const token = user?.data?.result?.access_token;
    if (user && token && role === "employer") {
      navigate(appRoutes.EmployerDashboard);
    }
    if (user && token && role === "employee") {
      navigate(appRoutes.EmployeeDashboard);
    }
  });

  const language_selected = Cookies.get("i18next");

  function validateEmail(email) {
    var re =
      /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/gm;
    return re.test(email);
  }

  useEffect(() => {
    if (email || number) {
      setDisable(false);
    } else if (email === "" || number === "") {
      setDisable(true);
    }
  }, [email, number]);

  const validations = (email) => {
    const errors = {};

    if (!validateEmail(email)) {
      errors.email = t("ValidEmailErr");
    }

    return errors;
  };

  const numberValidation = (number) => {
    const errors = {};
    if (number?.length < 8 || number?.length > 13) {
      errors.number = t("MobileNoErrLnt");
    }
    return errors;
  };

  useEffect(() => {
    const validations = (email) => {
      const errors = {};

      if (!validateEmail(email)) {
        errors.email = t("ValidEmailErr");
      }

      return errors;
    };

    const numberValidation = (number) => {
      const errors = {};
      if (number?.length < 8 || number?.length > 13) {
        errors.number = t("MobileNoErrLnt");
      }
      return errors;
    };

    if (submit) {
      if (email) {
        const err = validations(email);
        if (Object.keys(err).length > 0) {
          setError(true);
          setErrMsg(err);
        } else {
          setError(false);
          setErrMsg();
        }
      }
      if (number) {
        const err = numberValidation(number);
        if (Object.keys(err).length > 0) {
          setError(true);
          setErrMsg(err);
        } else {
          setError(false);
          setErrMsg();
        }
      }
    }
  }, [email, number, submit, t]);

  const Hedingtext = location.pathname.includes("employer")
    ? t("Offer")
    : t("EmploymentApplication");

  const desc = location.pathname.includes("employer")
    ? t("OfferText")
    : t("EmployeeProfileConftext");

  const handleData = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (email) {
      const err = validations(email);
      if (Object.keys(err).length > 0) {
        setError(true);
        setErrMsg(err);
      } else {
        userCheckEmail(email, dispatch);
        setShowNextPage(true);
      }
    } else if (number.length > 0) {
      const err = numberValidation(number);
      if (Object.keys(err).length > 0) {
        setError(true);
        setErrMsg(err);
      } else {
        userCheckNumber(number, dispatch);
        setShowNextPage(true);
      }
    }
  };

  const link =
    language_selected === "en"
      ? "https://www.dfae.admin.ch/missions/mission-onu-geneve/en/home/manual-regime-privileges-and-immunities/introduction/private-household-employees-ordinance.html"
      : "https://www.dfae.admin.ch/missions/mission-onu-geneve/fr/home/manuel-application-regime/introduction/ordonnance-domestiques-prives.html";

  return (
    <>
      {showNextPage === false && (
        <div className="w-full h-full flex flex-col justify-center items-center bg-secgray sm_ss:overflow-hidden">
          <div className="flex flex-col max-w-4xl smx:w-3xl md:w-[600px] 2xl:w-[600px] sm_ss:w-full sm_ss:p-0 sm_s:p-0 sm:w-full sm_s:w-full">
            <p className="text-gray font-extrabold text-3xl pb-3 pt-2 sm_ss:p-3 sm_s:p-3 sm_ss:text-center sm_s:text-center sm_ss:text-base sm_s:text-xl sm_ss:pb-1 sm_s:pb-1">
              {Hedingtext}
            </p>
            <p className="text-black smx:pb-2  sm_ss:text-xs sm_ss:p-3 sm_s:p-3 sm_ss:text-center sm_s:text-center md:pb-4">
              {desc}
              <a
                href={link}
                target="__blank"
                className="text-secblue underline cursor-pointer mb-1 sm_ss:mb-5 sm_s:mb-5"
              >
                {t("OrdinanceText")}
              </a>
            </p>
            <FormProvider {...methods}>
              <div className="bg-white px-8 sm_ss:px-10 sm_s:px-10 py-6 sm_ss:rounded-none sm_s:rounded-none flex flex-col rounded-3xl sm_ss:w-full sm_s:w-full sm_ss:p-4 sm_s:p-4 2xl:py-14">
                <div className="w-full sm_ss:w-full sm_s:w-full flex flex-col justify-center relative">
                  <TextInput
                    text={t("ProfileConfEmail")}
                    textType={"text"}
                    id="email"
                    value={email}
                    handleChange={(e) => setEmail(e.target.value)}
                    required={true}
                    disable={!!number}
                    placeholder={t("ProfileConfEmailPlaceholder")}
                  />
                  {error && (
                    <span className="text-red-600 text-xs absolute bottom-2">
                      {errMsg.email}
                    </span>
                  )}
                </div>
                <span className="self-center text-sectext">{t("OR")}</span>
                <div className="w-full  sm_ss:w-full sm_s:w-full flex flex-col justify-center relative">
                  <PhoneInput
                    country={"ch"}
                    value={number}
                    onChange={(e) => {
                      setNumber(e);
                    }}
                    placeholder={t("ProfileConfNumberPlaceholder")}
                    className="disabled:bg-sectext"
                    inputStyle={{ width: "inherit", height: 40 }}
                  />
                  {error && (
                    <span className="text-red-600 text-xs absolute bottom-2">
                      {errMsg.number}
                    </span>
                  )}
                </div>
                {pending ? (
                  <CircularProgress />
                ) : (
                  <button
                    onClick={(e) => handleData(e)}
                    disabled={disable}
                    className="disabled:bg-textGray self-center mt-4 disabled:cursor-not-allowed bg-blue px-4 py-2 text-white rounded-lg uppercase font-semibold text-sm 2xl:text-lg"
                  >
                    {t("continue")}
                  </button>
                )}
              </div>
            </FormProvider>
          </div>
        </div>
      )}
      {showNextPage === true && (
        <ProfileConfirmation
          pending={pending}
          name={name}
          lastname={last_name}
          dob={date_of_birth}
          nationality={nationality}
        />
      )}
    </>
  );
};

export default EmployerLogin;
