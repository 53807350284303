import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter } from "react-router-dom";
import Routers from "./router/Router";
function App() {
  const toggleUserGuideURL = (val) => {};

  return (
    <BrowserRouter>
      <div className="App flex flex-col justify-between h-screen">
        <Header toggleUserGuideURL={toggleUserGuideURL} />
        <Routers />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
