import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const Select = ({
  title,
  options,
  handleselect,
  defaultvalue,
  placeholder,
}) => {
  return (
    <div className="flex flex-col w-full">
      <span className="text-sectext pb-2">{title}</span>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        className="bg-white rounded-md w-full"
        options={options}
        sx={{ width: "100%" }}
        size="small"
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        onChange={handleselect}
        defaultValue={defaultvalue}
      />
    </div>
  );
};

export default Select;
