import { createSlice } from "@reduxjs/toolkit";

export const setDefaultEmail = createSlice({
  name: "setDefaultEmail",
  initialState: {
    email: "",
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    setDefaultEmailStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    setDefaultEmailSuccess: (state, action) => {
      state.email = action.payload;
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    setDefaultEmailError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
      state.success = false;
      state.email = "";
    },
    resetDefaultEmail: (state) => {
      state.pending = false;
      state.error = false;
      state.success = false;
      state.email = "";
    },
  },
});

export const {
  setDefaultEmailError,
  setDefaultEmailStart,
  setDefaultEmailSuccess,
  resetDefaultEmail,
} = setDefaultEmail.actions;
export default setDefaultEmail.reducer;
