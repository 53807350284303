import axios from "axios";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../constants/apiConstants/API";
import { appRoutes } from "../constants/routeConstants/Routes";

const PendinhActivation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const CheckUserSession = async (dispatch) => {
      const userData = JSON.parse(localStorage.getItem("userInfo"));
      const token = `Bearer ${userData?.data?.result?.access_token}`;
      const role = userData?.data?.result?.role;
      await axios({
        method: "POST",
        url: API.BASE_URL + API.CHECK_USER_SESSIO,
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => {
          if (res.data.result === "pending") {
            navigate(appRoutes.pendingActivation);
          } else if (res.data.result === "cancelled") {
            const link =
              role === "employer"
                ? appRoutes.EmployerDashboard
                : appRoutes.EmployeeDashboard;
            navigate(link);
          } else if (res.data.result === "rejected") {
            navigate(appRoutes.Home);
            localStorage.clear();
          } else {
            const link =
              role === "employer"
                ? appRoutes.EmployerDashboard
                : appRoutes.EmployeeDashboard;
            localStorage.setItem("status", JSON.stringify(res.data.result));
            navigate(link);
          }
        })
        .catch((err) => {
          if (err.response.data.message === "token expired") {
            localStorage.clear();
            window.location.replace("/");
          }
        });
    };
    CheckUserSession();
  }, [dispatch, navigate, location.pathname]);

  return (
    <div className="flex h-fullX flex-col items-center justify-center">
      <div className="w-full flex flex-col items-center justify-center">
        <p className="text-sectext text-center text-lg">
          {t("ApplicationProceedingText")}
        </p>
        <p className="text-sectext text-center text-lg">{t("InformedText")}</p>
      </div>
    </div>
  );
};

export default PendinhActivation;
