import { t } from "i18next";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../constants/routeConstants/Routes";

const FormSubmitted = (props) => {
  const [size, setSize] = useState(window.screen.width);
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setSize(window.screen.width);
    });
  }, []);

  if (size < 700) {
    return <FormSubmittedMobile />;
  } else {
    return <FormSubmittedDesktop />;
  }
};

const FormSubmittedDesktop = () => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(15);
  const [role, setRole] = useState("");

  React.useEffect(() => {
    const userRole = localStorage.getItem("success-role");
    setRole(userRole);
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      navigate(appRoutes.EmployerLoginStep3);
    }
  }, [counter, navigate]);
  const { t } = useTranslation();
  return (
    <div className=" w-full bg-signUpBG flex justify-center items-center h-full">
      <div className="md:w-[600px] h-full max-w-4xl 2xl:w-4xl flex flex-col 2xl:pt-20">
        <span className="font-extrabold text-2xl text-gray sm_ss:text-center sm_s:text-center 2xl:text-4xl 2xl:mb-3">
          {t("Thank you for your application")}
        </span>
        <div className="bg-white w-full flex flex-col p-6 my-6 rounded-3xl text-base lg:mb-14 mb-8">
          <span className="py-2 px-3">{t("ApplicationProceedingText")}</span>
          <span className="py-2 px-3">{t("InformedText")}</span>
        </div>
        <span className="text-base text-sectext">{t("Info")}</span>

        <div className="flex flex-col justify-start mt-4">
          <span className="text-base 2xl:mb-5">{t("Services")}</span>
          {data.map((val, index) => (
            <div key={index}>
              <List title={val.name} index={index} link={val.link} />
            </div>
          ))}
        </div>
        <div className="text-sectext h-10 px-2 flex items-center justify-center rounded-lg my-0">
          {`${t("loginRedirect")} ${counter}`}
        </div>
      </div>
    </div>
  );
};

const FormSubmittedMobile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState(15);
  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      navigate(appRoutes.EmployerLoginStep3);
    }
  }, [counter, navigate]);
  return (
    <div className="w-full h-full pt-20 px-6 flex flex-col justify-start items-center bg-signUpBG">
      <span className="text-gray text-lg font-extrabold text-center mb-4">
        {t("ThankYouText")}
      </span>
      <span className="text-gray text-sm text-center mb-4">
        {t("ApplicationProceedingText")}
      </span>
      <span className="text-gray text-sm text-center">{t("InformedText")}</span>
      <div className="text-sectext h-10 px-2 flex items-center justify-center rounded-lg my-0">
        {`${t("loginRedirect")} ${counter}`}
      </div>
    </div>
  );
};

const List = ({ title, index, link }) => (
  <div
    key={index}
    className="underline text-blue cursor-pointer w-fit text-base 2xl:mb-1"
  >
    <a href={link} target="_blank" rel="noreferrer">
      {title}
    </a>
  </div>
);

const data = [
  {
    name: `> ${t("Link1")}`,
    link: "https://www.cagi.ch/en/services/welcome-programme/",
  },
  {
    name: `> ${t("Link2")}`,
    link: "https://www.cagi.ch/en/services/social-events/",
  },
  {
    name: `> ${t("Link3")}`,
    link: "https://jobs.cagi.ch/",
  },
  {
    name: `> ${t("Link4")}`,
    link: "https://www.cagi.ch/en/services/services-for-ngos/",
  },
  {
    name: `> ${t("Link5")}`,
    link: "https://www.cagi.ch/en/services/accommodation-for-visiting-delegates/",
  },
];

export default FormSubmitted;
