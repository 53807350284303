import { createSlice } from "@reduxjs/toolkit";

const ProfileConformation = createSlice({
  name: "ProfileConformation",
  initialState: {
    profileCon: {
      name: "",
      last_name: "",
      nationality: "",
      date_of_birth: "",
      userEmail: "",
      number: "",
      email: "",
    },
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    profileConStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    profileConSuccess: (state, action) => {
      state.pending = false;
      state.profileCon = action.payload;
      state.error = false;
    },
    profileConError: (state) => {
      state.pending = false;
      state.error = true;
    },
    reset: (state) => {
      state.profileCon = {};
      state.userInfo = {};
      state.error = false;
      state.success = false;
      state.pending = false;
    },
  },
});

export const { profileConError, profileConStart, profileConSuccess, reset } =
  ProfileConformation.actions;
export default ProfileConformation.reducer;
