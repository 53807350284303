import { createSlice } from "@reduxjs/toolkit"

export const employerProfileUpdate = createSlice({
  name: "employerProfileUpdate",
  initialState: {
    pending: false,
    error: false,
    success: false,
    is_new_request_created: "",
  },
  reducers: {
    employerProfileUpdateStart: (state, action) => {
      state.pending = true
      state.error = false
    },
    employerProfileUpdateSuccess: (state, action) => {
      state.success = true
      state.pending = false
      state.error = false
    },
    employerProfileNotUpdate: (state, action) => {
      state.is_new_request_created = action.payload
      state.success = false
      state.pending = false
      state.error = false
    },
    employerProfileUpdateError: (state, action) => {
      state.pending = false
      state.error = action.payload
    },
    employerProfileNotUpdateReset: (state) => {
      state.is_new_request_created = ""
      state.success = false
      state.pending = false
      state.error = false
    },
  },
})

export const {
  employerProfileUpdateError,
  employerProfileUpdateStart,
  employerProfileUpdateSuccess,
  employerProfileNotUpdate,
  employerProfileNotUpdateReset,
} = employerProfileUpdate.actions
export default employerProfileUpdate.reducer
