import { createSlice } from "@reduxjs/toolkit";

export const cancelUserRequest = createSlice({
  name: "cancelUserRequest",
  initialState: {
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    cancelUserRequestStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    cancelUserRequestSuccess: (state, action) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    cancelUserRequestError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
    },
  },
});

export const {
  cancelUserRequestError,
  cancelUserRequestStart,
  cancelUserRequestSuccess,
} = cancelUserRequest.actions;
export default cancelUserRequest.reducer;
