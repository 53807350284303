import LandingPage from "../../pages/LandingPage";
import { appRoutes } from "../../constants/routeConstants/Routes";
import EmployerLogin from "../../pages/EmployerLogin";
import ProfileConfirmation from "../../pages/ProfileConfirmation";
import EmployerFinalLogin from "../../pages/EmployerFinalLogin";
import EmployerSignUp from "../../pages/EmployerSignUp";
import EmployeeSignUp from "../../pages/EmployeeSignup";
import ResetPassword from "../../pages/ResetPassword";
import EmployerAbout from "../../pages/EmployerAbout";
import EmployeeAbout from "../../pages/EmployeeAbout";
import ResetPasswordSubmit from "../../pages/ResetPasswordSubmission";
import SetPasswordSuccess from "../../pages/SetPasswordSuccess";
import SetPasswordSuccessEmployee from "../../pages/SetPasswordSuccessEmployee";
import EmployerDashboard from "../../pages/EmployerDashboard";
import EmployeeDashboard from "../../pages/EmployeeDashboard";
import PendinhActivation from "../../pages/PendinhActivation";
import NewRequestFormEmployer from "../../pages/NewRequestFormEmployer";
import NewRequestFormEmployee from "../../pages/NewRequestFormEmployee";
import FormSubmitted from "../../pages/FormSubmitted";
import Terms from "../../components/termsandpolicy/Terms";
import Privacypolicy from "../../components/termsandpolicy/Privacypolicy";
// ** Document title

// ** Default Route
const DefaultRoute = appRoutes.Home;

// ** Merge Routes
const AppRoutes = [
  {
    path: appRoutes.Home,
    component: LandingPage,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.EmployerLoginStep1,
    authorizedRoles: ["provider"],
    meta: {
      authRoute: true,
    },
    component: EmployerLogin,
  },
  {
    path: appRoutes.EmployerLoginStep2,
    authorizedRoles: ["employer"],
    component: ProfileConfirmation,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.EmployerLoginStep3,
    authorizedRoles: ["employer"],
    component: EmployerFinalLogin,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.EmployerSignUp,
    authorizedRoles: ["employer"],
    component: EmployerSignUp,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.ResetPasswordEmployer,
    authorizedRoles: ["employer"],
    component: ResetPassword,
  },
  {
    path: appRoutes.EmployerAbout,
    authorizedRoles: ["employer"],
    component: EmployerAbout,
  },
  {
    path: appRoutes.NEWEMPLOYEMENTREQUEST,
    authorizedRoles: ["employer"],
    component: NewRequestFormEmployer,
  },
  {
    path: appRoutes.NEWEMPLOYEMENTREQUESTEMPLOYEE,
    authorizedRoles: ["employee"],
    component: NewRequestFormEmployee,
  },
  {
    path: appRoutes.EmployeeSignup,
    authorizedRoles: ["employee"],
    component: EmployeeSignUp,
    meta: {
      authRoute: true,
    },
  },

  {
    path: appRoutes.termsandconditionen,
    authorizedRoles: ["employee"],
    component: Terms,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.termsandconditionfn,
    authorizedRoles: ["employee"],
    component: Terms,
    meta: {
      authRoute: true,
    },
  },

  {
    path: appRoutes.privacypolicyen,
    authorizedRoles: ["employee"],
    component: Privacypolicy,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.privacypolicyfn,
    authorizedRoles: ["employee"],
    component: Privacypolicy,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.EmployeeLoginStep1,
    authorizedRoles: ["employee"],
    meta: {
      authRoute: true,
    },
    component: EmployerLogin,
  },
  {
    path: appRoutes.EmployeeLoginStep2,
    authorizedRoles: ["employee"],
    meta: {
      authRoute: true,
    },
    component: ProfileConfirmation,
  },
  {
    path: appRoutes.EmployeeLoginStep3,
    authorizedRoles: ["employee"],
    meta: {
      authRoute: true,
    },
    component: EmployerFinalLogin,
  },
  {
    path: appRoutes.EmployeeSignUp,
    authorizedRoles: ["employee"],
    component: EmployeeSignUp,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.ResetPasswordEmployer,
    authorizedRoles: ["employer"],
    component: ResetPassword,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.pendingActivation,
    authorizedRoles: ["employer, employee"],
    component: PendinhActivation,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.EmployeeAbout,
    authorizedRoles: ["employee"],
    component: EmployeeAbout,
  },
  {
    path: appRoutes.ResetPasswordEmployee,
    authorizedRoles: ["employee"],
    component: ResetPassword,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.SignUpSuccess,
    authorizedRoles: ["employee,employer"],
    component: FormSubmitted,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.SetPassword,
    authorizedRoles: ["employee", "employer"],
    component: ResetPasswordSubmit,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.SetPasswordSuccess,
    authorizedRoles: ["employer,employee"],
    component: SetPasswordSuccess,
    meta: {
      authRoute: true,
    },
  },
  {
    path: appRoutes.SetPasswordSuccessEmployee,
    authorizedRoles: ["employee"],
    component: SetPasswordSuccessEmployee,
  },
  {
    path: appRoutes.EmployerDashboard,
    authorizedRoles: ["employer"],
    component: EmployerDashboard,
  },
  {
    path: appRoutes.EmployeeDashboard,
    authorizedRoles: ["employee"],
    component: EmployeeDashboard,
  },
];

export { DefaultRoute, AppRoutes };
