import React, { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import "./terms.css"
import {useLocation } from "react-router-dom";
import i18next from "../../components/i18/i18";
export default function Terms() {

  const [state,setState] = useState(1)
  const params = useLocation()
  const language = params.pathname?.split("/")[1]
  useEffect(()=>{
   
  if(language){
    i18next.changeLanguage(language);
    setState((prevVal) => prevVal + 1)
  }
  },[language])
  const { t } = useTranslation();
  return (
    <div className="term-condition-main-container">
      
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%", 
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            overflowX: "hidden",
          }}
        >
          <div style={{ textAlign: "center",marginTop:'20px' }}>
        <h1 className="term-conditon-heading">{t("T&CHead")}</h1>
        <h2 className="term-conditon-sub-heading">{t("T&CSubHead")}</h2>
        <h2 className="term-conditon-txt">{t("T&CSubHead2")}</h2>
        <h2 className="term-conditon-txt-version">{t("GENERALCONDITIONS")}</h2>
        <span>{t("VERSION")}</span>
      </div>

      <div className="sm_s:w-full md:w-[50%]" 
      style={{margin:'auto',padding:'15px'}}>
      <ul>
<li style={{ paddingTop: '0px' }}>
              <li style={{ color: '#004993', paddingBottom: '15px' }}>
                <b>{t('PREAMBLE')}</b>
              </li>
              <span>{t('MAINCONTENT')}</span>
            </li>
            <li className="terms-heading-list">{t(`APPLICATION OF THE GENERAL CONDITIONS`)}</li>
            <li>{t(`SUBCLAUSEONE`)}</li>
            <li>{t(`SUBCLAUSETWO`)}</li>
            
            <li>
              <ul>
                <li className="terms-heading-list">{t(`USER ACCOUNT`)}</li>
                <li>{t(`The registration validation and account creation process`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTONE`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTTWO`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTTHREE`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTB`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTFOUR`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTC`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTFIVE`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTSIX`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTSEVEN`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTD`)}</li>
                <li>{t(`SUBCLAUSETWOPOINTEIGHT`)}</li>
                <ul style={{}}> 
                <li>{t(`POINTONE`)}</li>
                <li>{t(`POINTTWO`)}</li>
                <li>{t(`POINTTHREE`)}</li>
                <li>{t(`POINTFOUR`)}</li>
                <li>{t(`POINTFIVE`)}</li>
                <li>{t(`POINTSIX`)}</li>
                <li>{t(`POINTSEVEN`)}</li>
                </ul>
                <li>{t(`SUBCLAUSETWOPOINTNINE`)}</li>
              </ul>
            </li>
            <li>
              <ul>
                <li className="terms-heading-list">{t(`3. RESTRICTION OF USE OF THE PLATFORM`)}</li>
                <li className="list-clause-alpha">{t(`threeOone`)}</li>
                <li>{t(`threeone`)}</li>
                <li>{t(`threetwo`)}</li>
                <li>{t(`threethree`)}</li>
                <li>{t(`threefour`)}</li>
                <li>{t(`threefive`)}</li>
                <li>{t(`threesix`)}</li>
                <li>{t(`threesev`)}</li>
                <li>{t(`threeeight`)}</li>
                <li>{t(`threenine`)}</li>
                <li>{t(`threeten`)}</li>
                <li>{t(`threeele`)}</li>
                
              
                <li className="terms-heading-list">{t(`4. INTELLECTUAL PROPERTY`)}</li>
                
                 
                <li>{t('fourone')}</li>
                <li>{t('fourtwo')}</li>
                <li>{t('fourthree')}</li>
                <li>{t('fourfour')}</li>
                <li className="terms-heading-list">{t('5. DATA PROTECTION')}</li>
              
                  <li>{t('fiveone')}</li>
                 
               
                <li>
                  <li className="terms-heading-list">{t('six')}</li>
                  <ul>
                    <li>{t('sixone')}</li>
                    <li>{t('sixtwo')}</li>
                    <li>{t('sixthree')}</li>
                  
                  </ul>
                </li>
                <li className="terms-heading-list">{t('seven')}</li>
                <li>{t('sevenone')}</li>
                <li>{t('sevenoneone')}</li>
                <li>{t('sevenonetwo')}</li>
                <li>{t('sevenonethree')}</li>
              
                <li>{t('seventwo')}</li>
                <li>{t('seventhree')}</li>
                <li>{t('sevenfour')}</li>
                <li>
                  <li className="terms-heading-list">{t('eight')}</li>
                 
                    <li>{t('eightone')}</li>
                    <li>{t('eighttwo')}</li>
                    <li>{t('eightthree')}</li>
                    
                 
                 
                </li>
                <li className="terms-heading-list">{t('nine')}</li>
                <li>{t('nineone')}</li>
                <li>{t('ninetwo')}</li>
                
                <li className="terms-heading-list">{t('ten')}</li>
              
                <li>{t('tenone')}  <a href='mailto:communication.cagi@etat.ge.ch' target="_blank" style={{color:"#004993"}} rel="noreferrer">{t('emaillink')}</a>  {t('afteremaillink')}</li>
                <li>{t('tentwo')}</li>

               
              </ul>
            </li>
          </ul>
      </div>

        </div>
      </div>
    </div>
  );
}
