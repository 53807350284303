import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaCheckCircle, FaEllipsisV, FaQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { EmployeeStatus } from "../../redux/apiCalls";
import { useTranslation } from "react-i18next";
import { employeeStatusTypes } from "../../constants/Common/Common";
import Cookies from "js-cookie";
import moment from "moment";

const EmployeeDetails = ({ onGoBack, data }) => {
  const language_selected = Cookies.get("i18next");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.updateStatus.status);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentStatus, setCurrentStatus] = useState();
  const [changestatus, setChangeStatus] = useState(false);
  const [employeeStatus, setEmployeeStatus] = useState(
    employeeStatusTypes.NOT_DECISION
  );
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeStatus = (status) => {
    setEmployeeStatus(status);
    setChangeStatus(true);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (changestatus === true) {
      EmployeeStatus(data?.id, employeeStatus, dispatch);
      setChangeStatus(false);
    }
    setEmployeeStatus(status);
  }, [
    employeeStatus,
    data?.id,
    setCurrentStatus,
    changestatus,
    data?.status_data,
    dispatch,
    status,
  ]);

  useEffect(() => {
    setCurrentStatus(data?.status_data);
  }, [data?.status_data]);

  const {
    required_languages,
    work_places,
    work_types,
    is_accommodation_provided,
    desired_starting,
    desired_starting_date,
  } = data;

  const languages = required_languages?.map((val, i) => {
    return (
      <p key={i} className=" mr-1">
        {t(val.name)}
      </p>
    );
  });

  const placesOfWork = work_places?.map((val) => {
    return <p className=" mr-1">{t(val.name)}</p>;
  });

  const typesOfWork = work_types?.map((val) => {
    return <p className=" mr-1">{t(val.name)}</p>;
  });

  const before =
    language_selected === "en"
      ? data?.desired_starting === "Before"
        ? "Before"
        : ""
      : "Avant le";

  const NotBefore =
    language_selected === "en"
      ? data?.desired_starting === "Not before"
        ? "Not before"
        : ""
      : "Pas avant le";

  const immediately =
    language_selected === "en"
      ? data?.desired_starting === "Immediately"
        ? "Immediately"
        : ""
      : "Immédiatement";

  const toBeAgreed =
    language_selected === "en"
      ? data?.desired_starting === "To be agreed"
        ? "To be agreed"
        : ""
      : "A convenir";

  const date =
    desired_starting === "Before" || desired_starting === "Not before"
      ? `${data?.desired_starting === "Before" ? before : NotBefore} ${moment(
          desired_starting_date
        ).format("DD-MM-YYYY")}`
      : data?.desired_starting === "Immediately"
      ? immediately
      : toBeAgreed;
  const languagesData = [...languages, data?.other_required_language];
  const workTypeData = [...typesOfWork, data?.other_work_type];

  console.log("datadatadata--------->", data);

  return (
    <div className="overflow-hidden">
      <div className="flex flex-col md:flex-row text-center my-4">
        <h2 className="text-2xl md:text-4xl  font-bold pb-2 text-[#6f6f6f]">
          {t("Private household employee")}
        </h2>
        <div
          onClick={onGoBack}
          className="my-auto cursor-pointer mx-2 md:mx-4 text-blue"
        >
          {"<"} {t("Back")}
        </div>
      </div>
      <div className="flex w-full justify-between flex-col-reverse md:flex-row">
        <div className="my-2 md:w-4/5 w-full">
          <div className="grid md:gap-1 mb-6 bg-white md:rounded-xl pb-[16px] pt-[40px] px-7 w-full">
            <div className="flex items-center w-full">
              <div className="flex flex-col md:w-36 w-full">
                <Details title={t("DetailTitle")} value={data?.user?.title} />
                <Details title={t("Firstname")} value={data?.user?.name} />
                <Details title={t("Lastname")} value={data?.user?.last_name} />
                <Details
                  title={t("nationality")}
                  value={data?.nationalities?.map((el, i) => {
                    return (
                      <div className="flex flex-col" key={i}>
                        <p>{t(`${el}`)}</p>
                      </div>
                    );
                  })}
                />
                <Details
                  title={t("ProfileConfDOB")}
                  value={moment(data?.user?.date_of_birth).format("DD-MM-YYYY")}
                />

                <Details
                  title={t("Phone")}
                  value={
                    data?.user?.mobile_number
                      ? `+${data?.user?.mobile_number}`
                      : "_"
                  }
                />

                <a href={`mailto:${data?.user?.email}`}>
                  <Details
                    className="text-blue underline"
                    title={t("ProfileConfEmail")}
                    value={data?.user?.email}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="grid gap-1 mb-6 bg-white md:rounded-xl pb-[16px] pt-[40px] px-7 w-full overflow-hidden">
            <div className="flex items-center w-full">
              <div className="flex flex-col w-auto">
                <Details
                  title={t("spokenLang")}
                  value={languagesData?.map((val) => val)}
                />
                <Details
                  title={t("WorkType")}
                  value={workTypeData?.map((val) => val)}
                />
                <Details title={t("WorkPlaces")} value={placesOfWork} />
                <Details
                  title={t("Accomopdation")}
                  value={t(is_accommodation_provided)}
                />
                {desired_starting_date === null ? (
                  ""
                ) : (
                  <Details title={t("EmplStart")} value={date} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse md:flex-row pt-4">
          <div className="flex items-center ml-4 h-10">
            <div
              className={`my-auto w-44 p-1 text-white text-center font-bold rounded-md uppercase ${
                (employeeStatus?.employee_request_id === data?.id
                  ? employeeStatus?.status
                  : currentStatus) === employeeStatusTypes.INTERESTED
                  ? "bg-green-500"
                  : (employeeStatus?.employee_request_id === data?.id
                      ? employeeStatus?.status
                      : currentStatus) === employeeStatusTypes.NOT_DECISION
                  ? "bg-blue"
                  : "bg-neutral-400 "
              }`}
            >
              {(employeeStatus?.employee_request_id === data?.id
                ? employeeStatus?.status
                : currentStatus) === employeeStatusTypes.INTERESTED
                ? t("Interested")
                : ""
                ? employeeStatusTypes.INTERESTED
                : (employeeStatus?.employee_request_id === data?.id
                    ? employeeStatus?.status
                    : currentStatus) === employeeStatusTypes.NOT_DECISION
                ? t("NoDecision")
                : t("NotInterested")}
            </div>
            <FaEllipsisV
              className="my-auto ml-2 cursor-pointer"
              size={"20px"}
              onClick={handleClick}
            />
          </div>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="w-56 rounded-lg"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            className="w-48"
            onClick={() => {
              changeStatus(employeeStatusTypes.NOT_DECISION);
            }}
          >
            <div className="flex items-center justify-between">
              <FaQuestionCircle size={15} className="text-blue mr-1" />{" "}
              {t("NoDecision")}
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeStatus(employeeStatusTypes.INTERESTED);
            }}
          >
            <div className="flex items-center justify-between">
              <FaCheckCircle size={15} className="text-green-500 mr-1" />
              {t("Interested")}
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              changeStatus(employeeStatusTypes.NOT_INTERESTED);
            }}
          >
            <div className="flex items-center justify-between">
              <AiFillCloseCircle size={17} className="text-neutral-400 mr-1" />
              {t("NotInterested")}
            </div>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

function Details({ title, value, className }) {
  return (
    <div className="flex  items-start h-auto md:w-[500px] w-full text-base md:text-md lg:text-lg">
      <div className="mb-2 w-[120px] md:w-[200px]">{title}</div>
      <div className="w-2 md:w-10" />
      <div className={`mb-2 flex flex-col ${className}`}>{value}</div>
    </div>
  );
}

export default EmployeeDetails;
