import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { appRoutes } from "../constants/routeConstants/Routes";
import { useDispatch } from "react-redux";
import { resetEmployeeSignup } from "../redux/slices/EmployeeSignUp";
import { resetEmployerSignup } from "../redux/slices/EmployerSignUp";
import { reset } from "../redux/slices/slice";
import { useTranslation } from "react-i18next";
import { resetDefaultEmail } from "../redux/slices/DefaultEmail";
import { resetDefaultNumber } from "../redux/slices/DefaultNumber";
import { resetfetchUser } from "../redux/slices/FetchUserProfile";

const LandingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const role = user?.data?.result?.role;
    const token = user?.data?.result?.access_token;
    if (user && token && role === "employer") {
      navigate(appRoutes.EmployerDashboard);
    }
    if (user && token && role === "employee") {
      navigate(appRoutes.EmployeeDashboard);
    }
  });

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetDefaultEmail());
    dispatch(resetDefaultNumber());
    dispatch(resetEmployeeSignup());
    dispatch(resetEmployerSignup());
    dispatch(reset());
  }, [dispatch]);

  return (
    <div
      className={`flex bg-[url("assets/images/background.jpg")] bg-cover bg-no-repeat sm_ss:bg-none sm_s:bg-signUpBG justify-center items-center flex-1 overflow-hidden w-full sm_ss:bg-signUpBG sm_s:bg-none p-10 sm_ss:p-2 sm_s:p-2`}
    >
      <div className="flex flex-col md:w-3xl 2xl:w-4xl 2xl:py-12 py-6 px-3 justify-center items-center bg-white rounded-xl sm_ss:w-full sm:w-full sm_ss:py-0 sm_ss:p-0 sm_s:w-full sm_s:bg-signUpBG sm_s:p-3 sm_s:py-1 smx:w-full smx:mx-10 smx:p-7 sm_ss:bg-signUpBG">
        <div className="flex flex-col px-4 sm_s:px-2">
          <p className="capitalize font-extrabold text-gray text-2xl py-1 sm_s:hidden 2xl:text-4xl sm_ss:hidden ">
            {t("TypeSelector_Title")}
          </p>
          <p className="text-black text-x sm_s:text-base sm_ss:text-sm 2xl:text-xl sm_s:text-center sm_ss:text-center sm_s:pb-3">
            {t("TypeSelectorDesc")}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center w-full px-6 sm_s:px-2">
          <p className="text-gray font-bold py-4 sm_s:py-2 2xl:text-3xl">
            {t("I_am_a")}
          </p>
          <div className="flex w-full items-center justify-between sm_ss:w-full sm_s:justify-center sm_ss:flex-col sm_s:flex-col sm_s:w-full">
            <Button
              text={t("TypeSelectorButtonEmployee")}
              route={appRoutes.EmployeeLoginStep1}
            />
            <div className="w-10" />
            <Button
              text={t("TypeSelectorButtonEmployer")}
              route={appRoutes.EmployerLoginStep1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Button = ({ text, route }) => (
  <Link to={route} className="sm_ss:w-11/12 sm_s:w-11/12 pb-5">
    <span className="h-10 w-50 2xl:w-96 sm_ss:font-bold sm_s:font-bold sm_ss:text-base sm_s:text-base bg-blue text-white uppercase flex justify-center sm_s:my-1 items-center py-3 px-1 sm_ss:w-full sm_ss:text-center sm_ss:px-0 sm_ss:my-1 sm_s:w-full sm_s:text-center sm_s:px-0 rounded-md sm_s:mx-1 sm:py-5 sm:text-xs md:my-0 md:font-semibold md:text-xs md:text-center cursor-pointer 2xl:p-7 2xl:text-xl">
      {text}
    </span>
  </Link>
);

export default LandingPage;
