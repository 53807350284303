/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployerDetails from "../components/EmployeeDashboard/EmployerDetails";
import {
  cancelCurrentUserRequest,
  CheckUserSession,
  fetchUserProfile,
  getEmployerList,
} from "../redux/apiCalls";
import { API } from "../constants/apiConstants/API";
import moment from "moment";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  // TextField,
} from "@mui/material";
import EmployeeRequest from "../components/EmployeeDashboard/NewEmployeeRequest";
import { appRoutes } from "../constants/routeConstants/Routes";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { employerListingReset } from "../redux/slices/EmployerList";
import Cookies from "js-cookie";
import EmployerRequest from "../components/EmployeeDashboard/NewEmployerReq";

const EmployeeDashboard = () => {
  const language_selected = Cookies.get("i18next");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const employerList = useSelector(
    (state) => state.employerListing.employerList
  );
  const lastPage = useSelector((state) => state.employerListing.lastPage);
  const currentPage = useSelector((state) => state.employerListing.currentPage);
  const cancelRequestSuccess = useSelector(
    (state) => state.cancelUserRequest.success
  );
  const isAuthenticated = useSelector(
    (state) => state.userSession.isAuthenticated
  );

  const User = useSelector((state) => state.fetchUserProfile.userInfo);
  // const AuthError = useSelector((state) => state.userSession.error);
  const pending = useSelector((state) => state.employerListing.pending);
  const [showEmployerDetails, setShowEmployerDetails] = useState(false);
  const [EmployerDetailsData, setEmployerDetailsData] = useState({});
  const [reasons, setReasons] = useState();
  const [page, setPage] = useState(1);
  const [request_id, setRequest_id] = useState();
  // const [otherReason, setOtherReason] = useState();
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState([]);
  const user = useSelector((state) => state.fetchUserProfile.userInfo);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    CheckUserSession(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated === false) {
      localStorage.clear();
      window.location.reload();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchUserProfile(dispatch);
  }, [dispatch]);

  const employerCount = async (request_id, employer_id) => {
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    const token = `Bearer ${userData?.data?.result?.access_token}`;
    if (isAuthenticated) {
      await axios({
        method: "POST",
        url: API.BASE_URL + API.UPDATEVIEWCOUNT,
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
        data: {
          request_id,
          employer_id,
        },
      })
        .then((res) => {
          // console.log(res.data);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  };
  const status = user?.latest_request?.status;
  useLayoutEffect(() => {
    if (isAuthenticated) {
      if (status === "accepted" || status === "archived") {
        getEmployerList(dispatch, page);
      } else {
        dispatch(employerListingReset());
      }
    }
  }, [dispatch, page, isAuthenticated, status]);

  useEffect(() => {
    let totalPages = [];
    for (let i = 1; i <= lastPage; i++) {
      totalPages.push(i);
    }
    setTotalNumberOfPages(totalPages);
  }, [lastPage]);

  useEffect(() => {
    if (!reasons) {
      setError(true);
    } else {
      setError(false);
    }
  }, [reasons]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const getRequestID = async () => {
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    const token = `Bearer ${userData.data.result.access_token}`;
    if (isAuthenticated) {
      await axios({
        method: "POST",
        url: API.BASE_URL + API.GET_REQUEST,
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => {
          setRequest_id(res?.data?.result?.list_array[0].id);
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    }
  };
  useEffect(() => {
    getRequestID();
  }, [isAuthenticated]);

  const handleCancelRequest = () => {
    setOpen(false);
    // const reason = reasons.includes("Other") ? otherReason : reasons;
    if (isAuthenticated === true) {
      cancelCurrentUserRequest(request_id, reasons, dispatch, setRefetch);
    }
  };

  const date = moment(User?.latest_request?.published_until).format(
    "DD MMM YYYY"
  );
  const month = t(`${date.split(" ")[1]}`);

  const isRenewable = User?.latest_request?.is_renewable;

  const RenewablrDate = moment(User?.latest_request?.published_until).format(
    "DD MMM YYYY"
  );

  const renewableMonth = t(`${RenewablrDate?.split(" ")[1]}`);
  const showListing = status === "accepted" || status === "archived";

  return (
    <div
      className="h-full bg-secgray w-full overflow-y-auto text-sm md:text-lg"
      id="scroolableDiv"
    >
      {(status === "expired" || refetch) && (
        <div className={`md:w-3/5 max-w-4xl pt-6 m-auto`}>
          <EmployerRequest />
        </div>
      )}
      {pending && !refetch ? (
        <div className="flex justify-center items-center w-full h-fullX">
          <CircularProgress />
        </div>
      ) : (
        showListing &&
        !refetch && (
          <div className={`w-full max-w-4xl md:px-10 py-4 m-auto`}>
            {showEmployerDetails ? (
              <EmployerDetails
                onGoBack={() => {
                  setShowEmployerDetails(false);
                }}
                data={EmployerDetailsData}
              />
            ) : (
              <div className="mx-4 max-w-4xl">
                <div className="mx-8 md:w-full md:mx-0 flex flex-col mt-[30px] justify-between md:items-center lg:items-center text-center md:text-left">
                  <div className={`flex w-full justify-between items-center `}>
                    <div className="sm_ss:hidden sm_s:hidden smx:hidden">
                      <h5 className="font-bold capitalize">
                        {t("Welcome")} {`${User?.name} ${User?.last_name}`}
                      </h5>
                      <p className="w-full">
                        {t("Expiring")}{" "}
                        {`${date?.split(" ")[0]}  ${month}  ${
                          date?.split(" ")[2]
                        }`}{" "}
                        {isRenewable === 1 ? t("Extension") : ""}
                      </p>
                    </div>
                    <div
                      className={`sm_ss:mt-3 sm_ss:mb-0 sm_ss:self-center sm_ss:w-full sm_s:w-full sm_s:self-center`}
                    >
                      <button
                        onClick={handleOpen}
                        className="bg-blue h-10 text-sm lg:text-md w-48 text-white py-2 px-4 rounded-lg font-bold"
                      >
                        {t("RequestCancel")}
                      </button>
                    </div>
                  </div>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <div className="absolute w-full md:w-1/2 md:h-4/6 p-8 text-center bg-white rounded-lg md:inset-x-1/4 top-[10%] ">
                      <h3 className="text-2xl font-bold h-14 text-[#6f6f6f] relative">
                        {t("RequestCancel")}
                        <div className="absolute right-0 top-0 bottom-0 m-auto">
                          <i
                            className="fa-solid fa-xmark  cursor-pointer"
                            onClick={handleClose}
                          />
                        </div>
                      </h3>
                      <FormControl>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          className="text-left"
                        >
                          {t("RequestCancelReason")}
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="1"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value={t("reason1")}
                            control={<Radio />}
                            label={t("reason1")}
                            onChange={(e) => setReasons("4")}
                          />
                          <FormControlLabel
                            value={t("reason2")}
                            control={<Radio />}
                            label={t("reason2")}
                            onChange={(e) => setReasons("5")}
                          />
                          <FormControlLabel
                            value={t("reason3")}
                            control={<Radio />}
                            label={t("reason3")}
                            onChange={(e) => setReasons("6")}
                          />
                          {/* <FormControlLabel
                          value={t("reason4")}
                          control={<Radio />}
                          label={t("reason4")}
                          onChange={(e) => setReasons(e.target.value)}
                        /> */}
                          {/* <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder={t("OtherReasons")}
                          disabled={!reasons?.includes("Other")}
                          focused={reasons === "Other"}
                          onChange={(e) => setOtherReason(e.target.value)}
                        /> */}
                          <button
                            onClick={handleCancelRequest}
                            disabled={error}
                            className="bg-blue disabled:bg-gray h-10 text-sm lg:text-md text-white py-2 my-8 px-4 rounded-lg font-bold"
                          >
                            {t("CANCELLATION")}
                          </button>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Modal>
                  {User?.renew_status === true && (
                    <div className="w-full md:mt-9 flex-row-reverse md:mb-2 sm_ss:mt-4 sm_s:mt-4">
                      <div className=" bg-blue rounded-3xl text-white text-base text-center mt-3 py-7 md:w-[345px] px-3 sm_s:py-10">
                        <p className="px-2 py-1">
                          {t("EmployeeDashBoardM") +
                            " " +
                            RenewablrDate?.split(" ")[0] +
                            " " +
                            renewableMonth +
                            " " +
                            RenewablrDate?.split(" ")[2]}
                        </p>
                        <p className="px-2 py-1">
                          {t("EmployeeDashBoardMExt")}
                        </p>
                        <Link to={appRoutes.NEWEMPLOYEMENTREQUESTEMPLOYEE}>
                          <button className="self-center bg-white rounded-lg text-blue font-bold uppercase mt-3 h-8 w-22">
                            {t("Review")}
                          </button>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <div className="my-3">
                  <h2 className="text-2xl md:text-4xl font-bold py-4 text-[#6f6f6f]">
                    {t("Employers")}
                  </h2>
                  <p className="font-bold">{t("ListingTest")}</p>
                  <p className="font-bold">{t("ListingTest2")}</p>
                </div>
                {employerList?.length > 0 ? (
                  <div className="md:flex flex-col my-6 mx-8 sm_s:mx-0 sm_ss:mx-0 md:mx-0 md:mt-8 md:mb-4 text-center md:text-left">
                    <div className="flex flex-col w-full">
                      {employerList?.map((employer, index) => {
                        const before =
                          language_selected === "en"
                            ? employer?.desired_starting === "Before"
                              ? "Before"
                              : ""
                            : "Avant le";

                        const NotBefore =
                          language_selected === "en"
                            ? employer?.desired_starting === "Not before"
                              ? "Not before"
                              : ""
                            : "Pas avant le";

                        const immediately =
                          language_selected === "en"
                            ? employer?.desired_starting === "Immediately"
                              ? "Immediately"
                              : ""
                            : "Immédiatement";

                        const toBeAgreed =
                          language_selected === "en"
                            ? employer?.desired_starting === "To be agreed"
                              ? "To be agreed"
                              : ""
                            : "A convenir";

                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setShowEmployerDetails(true);
                              setEmployerDetailsData(employer);
                              employerCount(employer?.id, employer?.user_id);
                            }}
                            className={`flex cursor-pointer justify-between shadow-md mb-3 rounded-2xl text-sm md:text-md lg:text-lg sm_ss:p-3 sm_s:p-3  md:py-7 md:px-4 md:my-3
                bg-white
                `}
                          >
                            <div
                              className={`text-blue capitalize md:w-[30%]`}
                            >{`${employer?.user?.title} ${employer?.user?.name} ${employer?.user?.last_name}`}</div>
                            <div className=" capitalize flex justify-center w-[30%]">
                              {t(
                                `${
                                  employer?.nationalities?.length > 1
                                    ? `${employer?.nationalities?.[0]} + ${
                                        employer?.nationalities?.length - 1
                                      }`
                                    : employer?.nationalities?.map((el) => el)
                                }`
                              )}
                            </div>
                            {employer?.desired_starting && (
                              <div className="sm_ss:hidden flex justify-center sm_s:hidden w-[40%]">
                                {t("Availability")} :
                                {employer?.desired_starting === "Before" ||
                                employer?.desired_starting === "Not before"
                                  ? ` ${
                                      employer?.desired_starting === "Before"
                                        ? before
                                        : NotBefore
                                    } ${moment(
                                      employer?.desired_starting_date
                                    ).format("DD-MM-YYYY")}`
                                  : employer?.desired_starting === "Immediately"
                                  ? ` ${immediately}`
                                  : ` ${toBeAgreed}`}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex flex-row gap-2 w-full justify-end">
                      {totalNumberOfPages?.length > 1 &&
                        !showEmployerDetails &&
                        totalNumberOfPages?.map((el, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => setPage(el)}
                              className={`${
                                currentPage === el
                                  ? "bg-blue text-white"
                                  : "bg-white text-black"
                              } shadow-lg w-8 flex items-center justify-center cursor-pointer rounded-full h-8 hover:bg-blue hover:text-white`}
                            >
                              {el}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <p>No data available</p>
                )}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default EmployeeDashboard;
