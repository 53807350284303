import { InputAdornment, OutlinedInput } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import RemoveButton from "./RemoveButton";

function AddChild({
  number,
  handleChild,
  value,
  id,
  title,
  className,
  addChildren,
  handleRemoveChildren,
}) {
  const { t } = useTranslation();
  return (
    <div className={`w-full ${className}`}>
      <label className="text-sectext mb-1">{title}</label>
      <div className="flex items-center">
        <OutlinedInput
          type="number"
          variant="outlined"
          value={value}
          onChange={handleChild}
          size="small"
          id={number}
          sx={{ width: "70%" }}
          className={`bg-white rounded-lg  `}
          endAdornment={
            <InputAdornment position="end">{t("YearsOld")}</InputAdornment>
          }
        />
        {addChildren ? (
          <RemoveButton handleRemoveChildren={handleRemoveChildren} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddChild;
