export const API = {
  BASE_URL: "https://household-dev.cagi.ch/admin/public/",
  REGISTER: "api/register",
  CHECKUSEREXIST: "api/fetch-user",
  COUNTRIES: "api/countries",
  LOGIN: "api/login",
  FORGETPASSWORD: "api/forgot-password",
  FETCHUSERPROFILE: "api/profile/edit",
  UPDATEPROFILE: "api/profile/update",
  RESETPASSWORD: "api/reset-password",
  EMPLOYEESLIST: "api/employees",
  EMPLOYERLIST: "api/employers",
  GET_REQUEST: "api/request/list",
  CANCEL_REQUEST: "api/request/cancel",
  DELETE_ACCOUNT: "api/removeAccount",
  LOGOUT: "api/logout",
  NEW_EMPLOYEMENT_REQUEST: "api/request/create",
  INTEREST: "api/interest/add",
  UPDATE_EMOLOYEE_STATUS: "api/employees/update/status",
  UPDATEVIEWCOUNT: "api/add_request_view",
  CHECK_USER_SESSIO: "api/check/token",
  SUSPEND_USER_ACC: "api/suspendAccount",
};
