import { t } from "i18next";

export const common = {
  HeaderTitle: "private household employees platform",
  HeaderConnexion: "connexion",
  TypeSelectorTitle: "CAGI private household employees platform",
  TypeSelectorDesc:
    "This platform is for authorised private household employees and employers in Switzerland in conformity with the Private Household Employees Ordinance of 6 June 2011 and who have a legitimation card from the Federal Department of Home Affairs (FFA).",
  ProfileConformation: "Profile confirmation",
  TypeSelectorButtonEmployee: "HOUSEHOLD EMPLOYEE",
  TypeSelectorButtonEmployer: "EMPLOYER",
  ProfileConformationDesc:
    "This email address exists in our database. Please confirm your identification.",
  EMPLOYER_DETAILS: "EMPLOYER DETAILS",
  EMPLOYEE_DETAILS: "EMPLOYEE DETAILS",
  HOUSEHOLD: "HOUSEHOLD",
  EMPLOYER_AGREETERMS:
    "I hereby authorise the CAGI to give my contact details to any person looking for a private household employee in conformity with the Private Household Employees Ordinance of 6 June 2011 and to forward for approval my employment application to the Federal Department of Foreign Affairs (FFA)",
  EMPLOYER_RESPOSIBILITYTEXT:
    "The CAGI and the FFA decline all responsibility with regard to this employment application. Responsibility remains entirely with the person who has completed and send this employment application.",
  EMPLOYER_AGREETERMS2:
    "I also undertake to inform the CAGI without delay if I find a new employer.",
  PRIVATE_ADDRESS: "Private Address",
  Employee_Agreement:
    "I hereby authorise the CAGI to give my contact details to any person looking for a private household employee in conformity with the Private Household Employees Ordinance of 6 June 2011 and to forward for approval my employment application to the Federal Department of Foreign Affairs (FFA).",
  Employee_Agreement2:
    "I also undertake to inform the CAGI without delay if I find a new employer.",
  HeaderLogin: "CONNEXION",
  EmployeeDashBoardM: "Your listing is expiring on the",
  EmployeeDashBoardMExt:
    "Review your details if you want to ask for an extension",
  Review: "Review",
  Yes: "Yes",
  No: "No (availability of external accommodation)",
  no: "No",
  Okay: "Okay",
  Cancel: "Cancel",
  yearsOld: "years old",
  CANCELLATION: "CONFIRM CANCELLATION",
  Availability: "Availability",
  RequestCancel: "Cancel the request",
  RequestCancelReason: "Please indicate the reason for cancelling",
  Reason1: "Found an employee through the platform",
  Reason2: "Found an employee by other means",
  Reason3: "No longer needs an employee",
  Reason4: "Other",
  NumberOfPersons: "Number of persons living in the household *",
  AgeOfChildren: "Age of the children(s)",
  AddChildren: "Add children",
  AccountPassword: "ACCOUNT PASSWORD",
  Messages: "MESSAGES",
  PrefferMessageLang: "I prefer to receive messages in *",
  English: "English",
  French: "French",
  German: "German",
  Italian: "Italian",
  Portuguese: "Portuguese",
  Spanish: "Spanish",
  LEGITIMATIONCARD: " LEGITIMATION CARD",
  LEGITIMATIONCARDTYPE: "FDFAE Legitimation card type *",
  BrowseFiles: "Browse files",
  DragDrop: "Drag and drop files here",
  RequiredLang: "Required languages *",
  TypeOFWork: "Type of work *",
  Housework: "Housework",
  Childcare: "Childcare",
  Gardening: "Gardening",
  TableService: "Table service",
  Cooking: "Cooking",
  Laundry: "Laundry",
  AccommodationProvided: "Accommodation provided *",
  DesiredStartingDate: " Desired starting date",
  Immediately: "Immediately",
  ToBeAgreed: "To be agreed",
  Before: "Before",
  NotBefore: "Not before",
  Preference: "Preference for a",
  Woman: "Woman",
  Man: "Man",
  HideNationality:
    "Do not show my profile for household employees of nationality:",
  Terms: " I agree with these terms",
  continue: "continue",
  Send: "send my application",
  PersonalDetails: " Personal Details",
  WorkProfile: "WORK PROFILE",
  SpokenLang: "Language(s) spoken *",
  WorkType: "Type of work *",
  WorkPlaces: "Place(s) of work *",
  GenevaVaud: "Geneva / Vaud",
  BernFribourg: "Bern / Fribourg",
  BaselArea: "Basel and area",
  anywhere: "Anywhere in CH",
  NeedOfAccProvided: "Need accommodation provided by the employer *",
  Indifferent: "Indifferent",
  AvailabilityToStart: "Available to start employment",
  Offer: "Offer of Employment",
  OfferText:
    "This request has to be completed in person by the individual wishing to hire a private household employee in conformity with",
  OrdinanceText: " Private Household Employees Ordinance",
  NoAccount: "No such account exist.",
  CreateNewProfile: "NO, CREATE A NEW PROFILE",
  NewProfile: "CREATE A NEW PROFILE",
  Login: "Login",
  ForgetPWD: "Forgot your password?",
  Welcome: "Welcome",
  EmployeeListingText: "Your household employee request is expiring on the",
  EmployerDashText:
    " Review the household employees below. If a candidate matches your needs, you can mark the profile as “interested” ",
  visible: "(visible only to you).",
  EmployerDashText2:
    "Contact the person by email or by phone to setup a meeting.",
  FlagEmployee: "Flag the employee",
  NoDecision: "No decision",
  Interested: "Interested",
  NotInterested: "Not interested",
  Back: "Back to list",
  SendLink: "SEND LINK",
  PasswordResetText:
    "Enter your email below to receive a link to reset your password.",
  Update: "Update",
};

export const dashBoard = {};

export const employeeStatusTypes = {
  NOT_DECISION: "no_decision",
  INTERESTED: "interested",
  NOT_INTERESTED: "not_interested",
};

export const EmployeeDashBoard = {
  ListingTest: "List of current employers looking for a household employee",
  Expiring: "Your work profile is visible until",
  Extension: "(Extension is possible)",
};

export const headerTexts = {
  Employer: "Employer",
  Employee: "Employee",
  Employers: "Employers",
  Employees: "Employees",
  English: "English",
  French: "French",
  Logout: "Logout",
  Logout_Message: " Are you sure you want to logout",
};

export const ErrorMessages = {
  SomethingWentWrong: "Something went wrong, please try again later",
};

export const Labels = {
  Title: "Title *",
  Firstname: "Firstname",
  Lastname: "Lastname",
  FName: "First name *",
  LName: "Last name *",
  Nationality: "Nationality *",
  DOB: "Date of birth *",
  Mobile: "Mobile phone *",
  PrivateEmail: "Private email * (your login)",
  OrganizationEmp: "Organisation (employer) *",
  OfficialFunc: "Official function *",
  WorkEmail: "Work email *",
  Mr: "Mr.",
  Ms: "Ms.",
  Mrs: "Mrs.",
  PleaseSelect: "Please select",
  Password: "Password *",
  RepeatPassword: "Repeat password *",
  LEGITIMATIONCARD: "FDFAE Legitimation card number *",
  Street: "Street + number",
  ZipCode: "Zip code",
  City: "City",
  Canton: "Canton *",
  Email: "Email *",
  Name: "Name",
  nationality: "Nationality",
  ThisIsME: "YES, THIS IS ME",
  dob: "Date of birth",
  Phone: "Phone",
  SpokenLang: "Languages spoken",
  WorkType: "Type of work",
  WorkPlaces: "Place to work",
  Accomopdation: "Need accommodation",
  EmplStart: "Start employment",
  mobile: "Mobile Phone",
  privateEmail: "Private Email",
  Organisation: "Organisation",
  Official: "Official function",
  Age: "Age of children",
  Address: "Home address",
  Country: "Country",
  accomodation: "Accommodation",
  Repeat: "Repeat",
};

export const Placeholders = {
  Fname: "Enter your first name",
  Lname: "Enter your last name",
  Mobile: "Enter your mobile phone",
  PrivateEmail: "Enter your private email",
  OrganizationEmp: "Enter your organisation (employer) name",
  OfficialFunc: "Enter your official function",
  WorkEmail: "Enter your work email",
  LEGITIMATIONCARD: "Enter FDFAE Legitimation card number",
  Street: "Enter street + number",
  ZipCode: "Enter zip code",
  City: "Enter city",
  Email: "Enter your  email",
  OtherReasons: "Write other reason here",
};

export const FormSubmittedText = {
  ThankYouText: "Thank you for your offer of employment",
  ApplicationProceedingText:
    " Your application will be processed by the CAGI and validated by the FFA",
  InformedText:
    "You will be informed about the decision by email within 2 to 5 working days.",
  Info: "The CAGI Private Household Employees Platform is a service provided by the International Geneva Welcome Center which offer practical assistance for settling in to newcomers and their family to Geneva and the Lemanic region",
  Services: "More services by CAGI",
};

export const ResetPasswordSubmissionTexts = {
  newPWDText: " Enter your new password below",
};

export const PageNotFoundTexts = {
  NotFoundText: "We couldn't find this page",
  Home: "Home",
};

export const titleOptions = [
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Ms.",
    label: "Ms.",
  },
];

export const titleOptionsfr = [
  {
    value: "Mr.",
    label: "M.",
  },
  {
    value: "Mme.",
    label: "Mme",
  },
];

export const adminRoles = ["superadmin", "admin"];
