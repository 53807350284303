import { createSlice } from "@reduxjs/toolkit";

export const deleteMyAccount = createSlice({
  name: "deleteMyAccount",
  initialState: {
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    deleteMyAccountStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    deleteMyAccountSuccess: (state, action) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    deleteMyAccountError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
    },
  },
});

export const {
  deleteMyAccountError,
  deleteMyAccountStart,
  deleteMyAccountSuccess,
} = deleteMyAccount.actions;
export default deleteMyAccount.reducer;
