import { createSlice } from "@reduxjs/toolkit";

export const employeeProfileUpdate = createSlice({
  name: "employeeProfileUpdate",
  initialState: {
    pending: false,
    error: false,
    success: false,
    errorMessage: {
      message: "",
      statusCode: "",
    },
    is_new_request_created: "",
  },
  reducers: {
    employeeProfileUpdateStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    employeeProfileUpdateSuccess: (state, action) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    employeeProfileNotUpdate: (state, action) => {
      state.is_new_request_created = action.payload;
      state.success = false;
      state.pending = false;
      state.error = false;
    },
    employeeProfileUpdateError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
      state.errorMessage = action.payload;
    },
    employeeProfileUpdateReset: (state) => {
      state.is_new_request_created = "";
      state.success = false;
      state.pending = false;
      state.error = false;
    },
  },
});

export const {
  employeeProfileUpdateError,
  employeeProfileUpdateStart,
  employeeProfileUpdateSuccess,
  employeeProfileUpdateReset,
  employeeProfileNotUpdate,
} = employeeProfileUpdate.actions;
export default employeeProfileUpdate.reducer;
