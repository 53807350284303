import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import { appRoutes } from "../constants/routeConstants/Routes";
import { useDispatch, useSelector } from "react-redux";
import { ResetPassword } from "../redux/apiCalls";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const ResetPasswordSubmission = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state) => state.resetPassword.setPassword);
  const pending = useSelector((state) => state.resetPassword.pending);
  const success = useSelector((state) => state.resetPassword.success);
  const token = location.pathname.split("/")[2];
  const email = location.search.split("=")[1];
  const UserEmail = email.split("?")[0];
  const role = location.search.split("role=")[1];
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const role = user?.data?.result?.role;
    const token = user?.data?.result?.access_token;
    if (user && token && role === "employer") {
      navigate(appRoutes.EmployerDashboard);
    }
    if (user && token && role === "employee") {
      navigate(appRoutes.EmployeeDashboard);
    }
  });

  useEffect(() => {
    if (success === true) {
      navigate(appRoutes.SetPasswordSuccess);
    }
  }, [success, navigate]);

  const toogleshowPassword = (id) => {
    if (id === "password") {
      setShowPassword(!showPassword);
    }
    if (id === "confirmPassword") {
      setShowconfirmPassword(!showconfirmPassword);
    }
  };

  const validations = (data) => {
    const errors = {};

    if (!data.password) {
      errors.password = t("PasswordErr");
    } else if (data.password.length < 8) {
      errors.password = t("PassLntErr");
    }

    if (!data.confirmPassword) {
      errors.confirmPassword = t("PasswordErr");
    } else if (data.confirmPassword !== data.password) {
      errors.confirmPassword = t("ConfirmPassErr");
    }
    return errors;
  };

  useEffect(() => {
    if (password || confirmPassword) {
      setDisable(false);
    } else if (password === "" || confirmPassword === "") {
      setDisable(true);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (isSubmitting) {
      const data = {
        password,
        confirmPassword,
      };
      const err = validations(data);
      if (Object.keys(err).length) {
        setError(true);
        setErrMsg(err);
      } else {
        setError(false);
        setErrMsg(false);
      }
    }
  }, [isSubmitting, password, confirmPassword]);

  const handleData = (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    const data = {
      password,
      confirmPassword,
    };
    const err = validations(data);
    if (Object.keys(err).length) {
      setError(true);
      setErrMsg(err);
    } else {
      const language_selected = Cookies.get("i18next");
      ResetPassword(password, language_selected, token, UserEmail, dispatch);
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center bg-secgray h-screen sm_ss:overflow-hidden">
      <div className="flex flex-col py-5 w-1/2 max-w-4xl sm_ss:w-full sm_ss:p-3 sm:w-7/12  sm_s:w-10/12 2xl:w-1/2">
        <p className="text-gray text-center py-3 sm_ss:text-base sm_ss:text-center sm_s:text-xl 2xl:text-4xl">
          {t("newPWDText")}
        </p>
        <div className="bg-white w-[500px] 2xl:w-[700px] self-center py-7 flex flex-col justify-center items-center my-5 p-4 rounded-xl sm_ss:p-4 2xl:py-20">
          <div className="w-full px-6 sm_ss:w-full sm_s:w-full flex flex-col relative">
            <TextInput
              text={t("Password")}
              textType={showPassword ? "text" : "password"}
              id="password"
              value={password}
              handleChange={(e) => setPassword(e.target.value)}
              required={true}
              showEyeButton={true}
              passwordShown={showPassword}
              togglePassword={() => toogleshowPassword("password")}
            />
            {error && (
              <span className="text-red-600 text-sm absolute bottom-0 left-6">
                {errMsg.password}
              </span>
            )}
          </div>
          <div className="w-full px-6 sm_ss:w-full sm_s:w-full flex flex-col relative">
            <TextInput
              text={t("Repeat *")}
              textType={showconfirmPassword ? "text" : "password"}
              id="mobile_number"
              value={confirmPassword}
              handleChange={(e) => setConfirmPassword(e.target.value)}
              showEyeButton={true}
              passwordShown={showconfirmPassword}
              togglePassword={() => toogleshowPassword("confirmPassword")}
            />
            {error && (
              <span className="text-red-600 text-sm absolute bottom-0 left-6">
                {errMsg.confirmPassword}
              </span>
            )}
          </div>

          <button
            onClick={(e) => handleData(e)}
            disabled={disable}
            className="disabled:bg-textGray mt-2 disabled:cursor-not-allowed bg-blue h-10 w-max px-2 text-white rounded-lg uppercase font-semibold text-sm 2xl:text-lg"
          >
            {pending ? <Loader /> : t("Update")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSubmission;
