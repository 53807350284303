import { createSlice } from "@reduxjs/toolkit";

export const updateStatus = createSlice({
  name: "updateStatus",
  initialState: {
    pending: false,
    error: false,
    success: false,
    status: "",
  },
  reducers: {
    updateStatusStart: (state, action) => {
      state.pending = true;
      state.error = false;
      state.success = false;
      state.status = "";
    },
    updateStatusSuccess: (state, action) => {
      state.status = action.payload;
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    updateStatusError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
      state.success = false;
      state.status = "";
    },
  },
});

export const { updateStatusError, updateStatusStart, updateStatusSuccess } =
  updateStatus.actions;
export default updateStatus.reducer;
