import React from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

const FileUpload = ({ handleUploadImages, value, disable }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dropzone
        disabled={disable}
        onDrop={(acceptedFiles) => {
          console.log("triggred");
          handleUploadImages(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              htmlFor="file-upload"
              className={` bg-white disabled:cursor-not-allowed rounded-lg border-2 border-dashed border-textGray flex items-center justify-center h-36 ${
                disable ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div className="flex flex-col items-center">
                <i className="fa-solid fa-cloud-arrow-up text-textGray text-3xl" />
                <p className="text-sectext">{t("BrowseFiles")}</p>
                <p className="text-textGray">{t("DragDrop")}</p>
                <p className="text-textGray text-sm">{t("Max")}</p>
              </div>
              <input {...getInputProps()} />
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default FileUpload;
