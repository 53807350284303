import { createSlice } from "@reduxjs/toolkit";

export const newEmployementRequest = createSlice({
  name: "newEmployementRequest",
  initialState: {
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    newEmployementRequestStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    newEmployementRequestSuccess: (state, action) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    newEmployementRequestError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
    },
  },
});

export const {
  newEmployementRequestError,
  newEmployementRequestStart,
  newEmployementRequestSuccess,
} = newEmployementRequest.actions;
export default newEmployementRequest.reducer;
