import React, {useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    backgroundColor:'#fff',
    // border: '2px solid #000',
    boxShadow: 24,
    // borderRadius: 5,
    p: 1,
  };

const ImageModal = ({url}) => {
const [open, setOpen] = useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

  return (
    <>
    <div>
     
        <div className="flex item-center h-28 pr-3 sm_ss:pr-4 sm_s:pr-4"
            onClick={handleOpen}
        >
            <img
                src={url}
                alt="card"
                width={"176px"}
                height={"96px"}
                className="rounded-md object-cover bg-black"
            />
        </div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {/* <CloseIcon/> */}
            <Box sx={style}>
                <div style={{display:'flex', flexDirection:'row'}}>
                    {/* <div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Image View
                        </Typography>
                    </div> */}
                    <div 
                        style={{    
                            alignSelf: "center",
                            marginLeft: "auto",
                            cursor:'pointer'
                        }} 
                        onClick={handleClose}
                    > 
                        <CloseIcon size={100}/>
                    </div>
                </div>

                <img
                    src={url}
                    alt="card"
                    width={"100%"}
                    height={"100%"}
                    className="rounded-md object-cover bg-black"
                />
        
            
            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}
            </Box>
        </Modal>
    </div>
        {/* <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
           <h2 style={{backgroundColor:'blue'}}>{subtitle}</h2>
            <button onClick={closeModal}>close</button>
            <div>I am a modal</div>
            <form>
            <input />
            <button>tab navigation</button>
            <button>stays</button>
            <button>inside</button>
            <button>the modal</button>
            </form>
        </Modal> */}
    </>
  );
};

export default ImageModal;
