import { createSlice } from "@reduxjs/toolkit";

export const fetchUserProfile = createSlice({
  name: "fetchUserProfile",
  initialState: {
    userInfo: {},
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    fetchUserProfileStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    fetchUserProfileSucess: (state, action) => {
      state.userInfo = action.payload;
      state.pending = false;
      state.error = false;
    },
    fetchUserProfileError: (state, action) => {
      state.pending = false;
      state.error = true;
    },
    ResetfetchUserProfile: (state) => {
      state.userInfo = {};
      state.pending = false;
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  fetchUserProfileStart,
  fetchUserProfileSucess,
  fetchUserProfileError,
  ResetfetchUserProfile,
} = fetchUserProfile.actions;
export default fetchUserProfile.reducer;
