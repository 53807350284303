import { createSlice } from "@reduxjs/toolkit";

export const employerListing = createSlice({
  name: "employerListing",
  initialState: {
    employerList: [],
    employerListLimitedResult: [],
    pending: true,
    error: false,
    success: false,
    lastPage: 1,
    currentPage: null,
  },
  reducers: {
    employerListingStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    employerListingSuccess: (state, action) => {
      state.success = true;
      state.employerList = action.payload;
      state.pending = false;
      state.error = false;
    },
    employerListingLoadMore: (state, action) => {
      state.success = true;
      state.employerList = [...state.employerList, ...action.payload];
      state.pending = false;
      state.error = false;
    },
    employerListingError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
    },
    setTotalPagesEmployer: (state, action) => {
      state.lastPage = action.payload;
    },
    setCurrentPageEmployer: (state, action) => {
      state.currentPage = action.payload;
    },
    employerListingReset: (state) => {
      state.pending = false;
      state.error = false;
      state.success = false;
      state.employerList = [];
      state.employerListLimitedResult = [];
    },
  },
});

export const {
  employerListingError,
  employerListingStart,
  employerListingSuccess,
  employerListingLoadMore,
  employerListingReset,
  setTotalPagesEmployer,
  setCurrentPageEmployer,
} = employerListing.actions;
export default employerListing.reducer;
