import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TextInput from "../components/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { loginStart, loginSuccess, reset } from "../redux/slices/slice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appRoutes } from "../constants/routeConstants/Routes";
import Loader from "../components/Loader";
import { loginError } from "../redux/slices/slice";
import { API } from "../constants/apiConstants/API";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { adminRoles } from "../constants/Common/Common";

const EmployerFinalLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pending = useSelector((state) => state.user.pending);
  const serverError = useSelector((state) => state.user.error);
  const userData = useSelector((state) => state.ProfileConformation.profileCon);
  const { userEmail } = userData;
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: userEmail ? userEmail : "",
    password: "",
    language_selected: Cookies.get("i18next"),
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const role = user?.data?.result?.role;
    const token = user?.data?.result?.access_token;
    if (user && token && role === "employer") {
      navigate(appRoutes.EmployerDashboard);
    }
    if (user && token && role === "employee") {
      navigate(appRoutes.EmployeeDashboard);
    }
  });

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const MySwal = withReactContent(Swal);

  function validateEmail(email) {
    var re =
      /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/gm;
    return re.test(email);
  }

  useEffect(() => {
    if (submitting) {
      if (userInfo.email.length === 0) {
        setError(true);
        setEmailErr({ err: true, message: t("EmailErr") });
      } else {
        setError(false);
        setEmailErr();
      }
      if (userInfo.password.length === 0) {
        setError(true);
        setPasswordErr({ err: true, message: t("PasswordErr") });
      } else {
        setError(false);
        setPasswordErr();
      }
      if (userInfo.email) {
        const res = validateEmail(userInfo.email);
        if (!res) {
          setError(true);
          setEmailErr({ err: true, message: t("ValidEmailErr") });
        } else {
          setError(false);
          setEmailErr();
        }
      }
    }
  }, [userInfo.email, userInfo.password, submitting, t]);

  useEffect(() => {
    if (!!serverError) {
      MySwal.fire({
        title: t("InvalidEmail&Pass"),
        icon: "error",
        buttonsStyling: { backgroundColor: "#f44336" },
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(loginError(false));
        }
      });
    }
  }, [serverError, MySwal, dispatch, t]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const Login = async (e) => {
    e.preventDefault();
    localStorage.clear();
    setSubmitting(true);
    if (!userInfo.email || !userInfo.password || error) {
      setError(true);
    } else if (userInfo.email) {
      const res = validateEmail(userInfo.email);
      if (!res) {
        setError(true);
        setEmailErr({ err: true, message: t("ValidEmailErr") });
      } else {
        setError(false);
        setEmailErr();
        dispatch(loginStart());
        await axios({
          method: "post",
          url: `${API.BASE_URL}${API.LOGIN}`,
          data: {
            ...userInfo,
          },
        })
          .then((res) => {
            if (
              res.data.result.role === "superadmin" ||
              res.data.result.role === "admin"
            ) {
              window.open(res.data.result.temporary_login_link, "_blank");
            }
            localStorage.setItem("userInfo", JSON.stringify(res));
            const result = res.data.result;
            dispatch(loginSuccess(result));
            const { role } = result;
            let Status = JSON.parse(localStorage.getItem("userInfo"));
            let activation = Status?.data?.result?.latest_request?.status;
            const count = Status?.data?.result?.total_requests_count;
            if (activation === "pending") {
              navigate(appRoutes.pendingActivation);
            }

            if (
              role === "employer" &&
              (activation === "accepted" ||
                activation === "cancelled" ||
                activation === "archived" ||
                activation === "expired" ||
                (activation === "rejected" && count > 1))
            ) {
              navigate(appRoutes.EmployerDashboard);
            }
            if (
              role === "employee" &&
              (activation === "accepted" ||
                activation === "cancelled" ||
                activation === "archived" ||
                activation === "expired" ||
                (activation === "rejected" && count > 1))
            ) {
              navigate(appRoutes.EmployeeDashboard);
            }
          })
          .catch((err) => {
            dispatch(loginError(err.response.data.message));
          });
      }
    }
  };

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.id]: e.target.value });
  };

  const link = location.pathname.includes("employer")
    ? appRoutes.ResetPasswordEmployer
    : appRoutes.ResetPasswordEmployee;

  return (
    <div
      className={`w-full bg-[url("assets/images/background.jpg")] relative bg-cover bg-no-repeat flex justify-center items-center h-full`}
    >
      <form
        onSubmit={Login}
        className="w-4xl h-full flex justify-center items-center"
      >
        <div className="flex w-full 2xl:w-3xl md:w-3xl flex-col p-13 justify-center items-center bg-white rounded-2xl smx:w-7/12 mt-5 sm:w-1/3  sm_ss:w-full sm_s:w-full sm_ss:rounded-none sm_s:rounded-none sm_s:p-10 sm_ss:p-10">
          <div className="flex w-full flex-col justify-center items-center h-full smx:w-full">
            <div className="w-full flex flex-col relative">
              <TextInput
                text={t("ProfileConfEmail")}
                textType={"text"}
                placeholder={t("ProfileConfEmailPlaceholder")}
                value={userInfo.email}
                handleChange={handleChange}
                id="email"
              />

              {emailErr?.err && (
                <span className="text-red-600 text-sm absolute bottom-1">
                  {emailErr?.message}
                </span>
              )}
            </div>
            <div className="w-full flex flex-col relative">
              <TextInput
                text={t("LoginPasswordLabel")}
                textType={passwordShown ? "text" : "password"}
                placeholder={"********"}
                value={userInfo.password}
                handleChange={handleChange}
                id="password"
                showEyeButton={true}
                togglePassword={togglePassword}
              />
              {passwordErr?.err && (
                <span className="text-red-600 text-sm absolute bottom-1">
                  {passwordErr?.message}
                </span>
              )}
            </div>
          </div>
          <div className="flex w-full py-3 flex-col items-center">
            <div className="flex w-full lg:pl-2 flex-row items-center justify-between">
              {pending ? (
                <Button text={<Loader />} />
              ) : (
                <Button text={t("Login")} disable={error} />
              )}

              <Link to={link}>
                <span className="text-blue underline cursor-pointer sm_ss:text-sm sm_s:text-sm">
                  {t("ForgetPWD")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const Button = ({ text, route, disable }) => (
  <button
    disabled={disable}
    type="submit"
    className="h-10 disabled:bg-gray disabled:cursor-not-allowed bg-blue text-white uppercase flex justify-center items-center w-32 py-2 rounded-md cursor-pointer"
  >
    {text}
  </button>
);

export default EmployerFinalLogin;
