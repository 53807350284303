import { createSlice } from "@reduxjs/toolkit";

export const employerSignUp = createSlice({
  name: "employerSignUp",
  initialState: {
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    employerSignUpStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    employerSignUpSuccess: (state, action) => {
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    employerSignUpError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
    },
    resetEmployerSignup: (state) => {
      state.success = false;
    },
  },
});

export const {
  employerSignUpError,
  employerSignUpStart,
  employerSignUpSuccess,
  resetEmployerSignup,
} = employerSignUp.actions;
export default employerSignUp.reducer;
