/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmployeeBar from "../components/EmployerDashboard/EmployeeBar";
import EmployeeDetails from "../components/EmployerDashboard/EmployeeDetails";
import EmployeeRequest from "../components/EmployerDashboard/EmployeeRequest";
import { API } from "../constants/apiConstants/API";
import {
  cancelCurrentUserRequest,
  CheckUserSession,
  fetchUserProfile,
  getEmployeeList,
} from "../redux/apiCalls";
import { employeeListingReset } from "../redux/slices/EmployeeListing";

const EmployerDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const employeeList = useSelector(
    (state) => state.employeeListing.employeeList
  );
  const lastPage = useSelector((state) => state.employeeListing.lastPage);
  const currentPage = useSelector((state) => state.employeeListing.currentPage);
  const pending = useSelector((state) => state.employeeListing.pending);
  const cancelRequestSuccess = useSelector(
    (state) => state.cancelUserRequest.success
  );
  const isAuthenticated = useSelector(
    (state) => state.userSession.isAuthenticated
  );

  const User = useSelector((state) => state.fetchUserProfile.userInfo);
  // const AuthError = useSelector((state) => state.userSession.error);
  const [open, setOpen] = useState(false);
  const [request_id, setRequest_id] = useState();
  const [reasons, setReasons] = useState();
  // const [otherReason, setOtherReason] = useState();
  const [error, setError] = useState();
  const handleOpen = () => setOpen(true);
  const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
  const [EmployeeDetailsData, setEmployeeDetailsData] = useState({});
  const [totalNumberOfPages, setTotalNumberOfPages] = useState([]);
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  useEffect(() => {
    CheckUserSession(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated === false) {
      localStorage.clear();
      window.location.reload();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchUserProfile(dispatch);
  }, [dispatch]);

  const getRequestID = async () => {
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    const token = `Bearer ${userData.data.result.access_token}`;
    if (isAuthenticated) {
      await Axios({
        method: "POST",
        url: API.BASE_URL + API.GET_REQUEST,
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => {
          setRequest_id(res?.data?.result?.list_array[0].id);
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    getRequestID();
  }, [isAuthenticated]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!reasons) {
      setError(true);
    } else {
      setError(false);
    }
  }, [reasons]);

  useEffect(() => {
    const status = User?.latest_request?.status;
    if (isAuthenticated && status) {
      if (status === "accepted" || status === "archived") {
        getEmployeeList(dispatch, page);
      } else {
        dispatch(employeeListingReset());
      }
    }
    if (status === "cancelled") {
      dispatch(employeeListingReset());
    }
  }, [page, isAuthenticated, User]);

  useEffect(() => {
    let totalPages = [];
    for (let i = 1; i <= lastPage; i++) {
      totalPages.push(i);
    }
    setTotalNumberOfPages(totalPages);
  }, [lastPage]);

  const handleCancelRequest = () => {
    setCancelLoading(true);
    // const reason = reasons.includes("Other") ? otherReason : reasons;
    if (isAuthenticated === true) {
      cancelCurrentUserRequest(request_id, reasons, dispatch, setRefetch).then(
        () => {
          setCancelLoading(false);
          setOpen(false);
          getEmployeeList(dispatch, page);
        }
      );
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const date = moment(User?.latest_request?.published_until).format(
    "DD MMM YYYY"
  );
  const month = t(`${date.split(" ")[1]}`);

  return (
    <div className="h-full bg-secgray overflow-y-auto text-sm md:text-lg">
      {cancelRequestSuccess || refetch ? (
        <div className="py-4">
          <EmployeeRequest />
        </div>
      ) : (
        <div className="md:w-4/5 lg:w-3/5 py-4 w-full m-auto flex justify-center">
          {employeeList?.length > 0 ? (
            <>
              {showEmployeeDetails ? (
                <EmployeeDetails
                  onGoBack={() => {
                    setShowEmployeeDetails(false);
                  }}
                  data={EmployeeDetailsData}
                />
              ) : (
                <div
                  className="mx-4 sm_ss:w-full sm_s:w-full max-w-[1100px] md:w-[950px] pt-3"
                  id="scrollableDiv"
                >
                  <div className="flex mx-8 md:mx-0 sm_ss:flex-col flex-col-reverse md:flex-row justify-between">
                    <div className="text-center md:text-left mt-[25px]">
                      <h5 className="font-extrabold">
                        {t("Welcome")} {`${User?.name} ${User?.last_name}`}
                      </h5>
                      <p>
                        {t("EmployeeListingText")}{" "}
                        {`${date?.split(" ")[0]}  ${month}  ${
                          date?.split(" ")[2]
                        }`}
                      </p>
                    </div>
                    <div className="flex flex-row-reverse mb-2 sm_ss:mt-3 sm_ss:mb-0 self-center">
                      <button
                        onClick={handleOpen}
                        className="bg-blue h-10 text-sm lg:text-md w-48 text-white py-2 px-4 rounded-lg font-bold"
                      >
                        {t("RequestCancel")}
                      </button>
                    </div>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <div className="absolute w-full md:w-1/2 md:h-4/6 p-8 text-center bg-white rounded-lg md:inset-x-1/4 top-[10%] ">
                        <h3 className="text-2xl font-bold h-14 text-[#6f6f6f] relative">
                          {t("RequestCancel")}
                          <div className="absolute right-0 top-0 bottom-0 m-auto">
                            <i
                              className="fa-solid fa-xmark  cursor-pointer"
                              onClick={handleClose}
                            />
                          </div>
                        </h3>
                        <FormControl>
                          <FormLabel
                            id="demo-radio-buttons-group-label"
                            className="text-left"
                          >
                            {t("RequestCancelReason")}
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="1"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value={t("Reason1")}
                              control={<Radio />}
                              label={t("Reason1")}
                              onChange={(e) => setReasons("1")}
                            />
                            <FormControlLabel
                              value={t("Reason2")}
                              control={<Radio />}
                              label={t("Reason2")}
                              onChange={(e) => setReasons("2")}
                            />
                            <FormControlLabel
                              value={t("Reason3")}
                              control={<Radio />}
                              label={t("Reason3")}
                              onChange={(e) => setReasons("3")}
                            />
                            {/* <FormControlLabel
                              value={t("Reason4")}
                              control={<Radio />}
                              label={t("Reason4")}
                              onChange={(e) => setReasons(e.target.value)}
                            /> */}
                            {/* <TextField
                              id="outlined-basic"
                              variant="outlined"
                              placeholder={t("OtherReasons")}
                              disabled={!reasons?.includes("Other")}
                              focused={reasons === "Other"}
                              onChange={(e) => setOtherReason(e.target.value)}
                            /> */}

                            <button
                              onClick={handleCancelRequest}
                              disabled={error}
                              className="bg-blue disabled:bg-gray h-10 text-sm lg:text-md text-white py-2 my-8 px-4 rounded-lg font-bold"
                            >
                              {cancelLoading ? (
                                <CircularProgress
                                  className=""
                                  size={25}
                                  color="inherit"
                                />
                              ) : (
                                t("CANCELLATION")
                              )}
                            </button>
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </Modal>
                  </div>
                  <div className="md:flex mt-8 mb-2 text-center md:text-left mx-8 md:mx-0">
                    <div className="md:w-4/5 ">
                      <h2 className="text-2xl md:text-4xl font-bold py-4 text-[#6f6f6f]">
                        {t("Private household employee")}
                      </h2>
                      <div className="flex flex-col w-full overflow-hidden ">
                        <p>{t("EmployerDashText")}</p>
                        {t("EmployerDashText1")}
                        <br /> {t("visible")}
                        <p className="font-extrabold">
                          {t("EmployerDashText2")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    {employeeList.map((employee, index) => (
                      <div key={index}>
                        <EmployeeBar
                          onClick={() => {
                            setShowEmployeeDetails(true);
                            setEmployeeDetailsData(employee);
                            index = { index };
                            scrollToTop();
                          }}
                          data={employee}
                          setPage={setPage}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="mt-2 flex flex-row gap-2 justify-end w-[70%]">
                    {totalNumberOfPages?.length > 1 &&
                      !showEmployeeDetails &&
                      totalNumberOfPages?.map((el, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => setPage(el)}
                            className={`${
                              currentPage === el
                                ? "bg-blue text-white"
                                : "bg-white text-black"
                            } shadow-lg w-8 flex items-center justify-center cursor-pointer rounded-full h-8 hover:bg-blue hover:text-white`}
                          >
                            {el}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </>
          ) : pending ? (
            <div className="flex justify-center items-center w-full h-fullX">
              <CircularProgress />
            </div>
          ) : (
            <EmployeeRequest />
          )}
        </div>
      )}
    </div>
  );
};

export default EmployerDashboard;
