import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/slice";
import fetchUserProfileReducer from "../slices/FetchUserProfile";
import resetPasswordReducer from "../slices/ResetPassword";
import employerProfileUpdateReducer from "../slices/EmployerProfileUpdate";
import employeeProfileUpdateReducer from "../slices/EmployeeProfileUpdate";
import employerSignUpReducer from "../slices/EmployerSignUp";
import employeeSignUpReducer from "../slices/EmployeeSignUp";
import employeeListingReducer from "../slices/EmployeeListing";
import employerListingReducer from "../slices/EmployerList";
import cancelUserRequest from "../slices/CancelUserRequest";
import deleteMyAccount from "../slices/DeleteAccount";
import newEmployementRequest from "../slices/NewEmployementRequest";
import setDefaultEmail from "../slices/DefaultEmail";
import setDefaultNumber from "../slices/DefaultNumber";
import userSession from "../slices/UserSession";
import updateStatus from "../slices/UpdateStatus";
import ProfileConformation from "../slices/ProfileConformation";
import fetchUserReducer from "../slices/FetchUserProfile";

export default configureStore({
  reducer: {
    user: userReducer,
    fetchUserProfile: fetchUserProfileReducer,
    resetPassword: resetPasswordReducer,
    employerProfileUpdate: employerProfileUpdateReducer,
    employeeProfileUpdate: employeeProfileUpdateReducer,
    employerSignUp: employerSignUpReducer,
    employeeSignUp: employeeSignUpReducer,
    employeeListing: employeeListingReducer,
    employerListing: employerListingReducer,
    cancelUserRequest: cancelUserRequest,
    deleteMyAccount: deleteMyAccount,
    newEmployementRequest: newEmployementRequest,
    setDefaultEmail: setDefaultEmail,
    setDefaultNumber: setDefaultNumber,
    userSession: userSession,
    updateStatus: updateStatus,
    ProfileConformation: ProfileConformation,
  },
});
