import React from "react";
import { Link } from "react-router-dom";
import errorIcon from "../assets/images/404.png";
import { useTranslation } from "react-i18next";

function PageNotFound() {
  const { t } = useTranslation();
  return (
    <div className="w-full h-screen bg-signUpBG flex items-center justify-center">
      <div className="max-w-4xl w-[500px] flex flex-col items-center p-5 bg-white rounded-lg">
        <img src={errorIcon} alt="page not found" className="w-32 h-32" />
        <span className="text-sectext text-2xl">{t("NotFoundText")}</span>
        <Link to="/">
          <button className="bg-blue my-2 py-3 px-4 text-white rounded-lg">
            {t("Home")}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default PageNotFound;
