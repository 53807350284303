import React, { useEffect, useLayoutEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appRoutes } from "../constants/routeConstants/Routes";
import MainHeaderLogo from "../assets/images/icons/logo-cagi-dark.svg";
import SecondaryHeaderLogo from "../assets/images/icons/logo-cagi-white.svg";
import flag from "../assets/images/icons/flag-fr.svg";
import EmployeeIcon from "../assets/images/icons/awesome-id-badge.svg";
import UserProfileIcon from "../assets/images/icons/awesome-user.svg";
import LogOutIcon from "../assets/images/icons/ionic-ios-exit.svg";
import englishFlagIcon from "../assets/images/icons/english.jpg";
import frenchFlagIcon from "../assets/images/icons/french.png";
import menu_mobile from "../assets/images/icons/menu_mobile.svg";
import { FaUser, FaIdBadge } from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";
import { logout } from "../redux/apiCalls";
import i18next from "../components/i18/i18";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
const MySwal = withReactContent(Swal);

const Header = ({ toggleUserGuideURL }) => {
  const location = useLocation();

  switch (location.pathname) {
    case appRoutes.EmployerSignUp:
      return <EmployerSignUpHeader toggleUserGuideURL={toggleUserGuideURL} />;
    case appRoutes.EmployeeSignup:
      return <EmployeeSignUpHeader toggleUserGuideURL={toggleUserGuideURL} />;
    case appRoutes.EmployerDashboard:
      return <AuthHeaderEmployer toggleUserGuideURL={toggleUserGuideURL} />;
    case appRoutes.EmployeeDashboard:
      return <AuthHeaderEmployee toggleUserGuideURL={toggleUserGuideURL} />;
    case appRoutes.NEWEMPLOYEMENTREQUEST:
      return <AuthHeaderEmployer toggleUserGuideURL={toggleUserGuideURL} />;
    case appRoutes.NEWEMPLOYEMENTREQUESTEMPLOYEE:
      return <AuthHeaderEmployee toggleUserGuideURL={toggleUserGuideURL} />;
    case appRoutes.EmployeeAbout:
      return <AuthHeaderEmployee toggleUserGuideURL={toggleUserGuideURL} />;
    case appRoutes.EmployerAbout:
      return <AuthHeaderEmployer toggleUserGuideURL={toggleUserGuideURL} />;
    case appRoutes.pendingActivation:
      return <PendingHeader toggleUserGuideURL={toggleUserGuideURL} />;
    default:
      return <DefaultHeader toggleUserGuideURL={toggleUserGuideURL} />;
  }
};

const EmployerSignUpHeader = ({ toggleUserGuideURL }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [size, setSize] = useState(window.screen.width);
  const [language, setLanguage] = useState(false);
  const language_selected = Cookies.get("i18next");
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setSize(window.screen.width);
    });
  }, []);

  return (
    <div className="flex flex-shrink-0 justify-between w-full h-24 2xl:h-32 sm_ss:bg-blue sm_s:bg-blue">
      <div className="flex items-center w-full">
        <Link to={"/"} className="h-full w-32 min-w-[82px] cursor-pointer">
          <img
            src={size < 650 ? MainHeaderLogo : SecondaryHeaderLogo}
            alt="Icon"
            className="h-full w-full p-4 2xl:p-6 cursor-pointer bg-blue sm_ss:bg-white sm_s:bg-white"
          />
        </Link>
        <p className="uppercase smx:text-blue smx:text-2xl md:text-blue lg:text-blue 2xl:text-blue ml-5 sm_ss:ml-3 sm_s:ml-3 sm_s:font-extrabold sm_ss:font-extrabold sm_ss:text-base sm_s:text-base mdx:font-extrabold 2lg:text-4xl text-white 2xl:text-5xl 2xl:ml-10 lg:tracking-wide md:text-[23px] md:font-extrabold font-bold py-4">
          {t("HeaderTitle")}
        </p>
        <p className="uppercase sm:hidden sm_ss:hidden sm_s:hidden md:mt-0 2xl:text-4xl md:text-3xl text-gray mt-1 2xl:mt-1">
          {t("Employer")}
        </p>
      </div>
      <div className="flex items-center justify-end p-5 w-1/5 sm:w-1/5 sm_ss:w-1/7 sm_s:w-2/12">
        <div className="md:w-36 flex items-center text-white mr-3 ">
          <Link to={appRoutes.EmployerLoginStep3}>
            <MdLockOutline
              size="24px"
              className="mb-1 sm_ss:text-white sm_s:text-white text-black"
            />
          </Link>
          <Link
            to={
              location.pathname.includes("employer")
                ? appRoutes.EmployerLoginStep3
                : appRoutes.EmployeeLoginStep3
            }
          >
            <p className="cursor-pointer sm_ss:hidden sm_s:hidden text-sectext">
              {t("HeaderLogin")}
            </p>
          </Link>
        </div>
        <div className="h-5 w-5 min-w-[20px] rounded-xl cursor-pointer mr-4 sm_ss:mr-0 sm_s:mr-0 relative">
          {/* <img
            src={language_selected === "en" ? englishFlagIcon : flag}
            alt=""
            className="w-full h-full rounded-full object-cover"
            onClick={() => setLanguage(!language)}
          /> */}
          <span
            onClick={() => setLanguage(!language)}
            className="w-full h-full rounded-full object-cover"
          >
            {language_selected === "en" ? "EN" : "FR"}
          </span>
          {language && (
            <div className="z-50 rounded-sm h-16 w-16 2xl:h-18 2xl:w-16 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
              <div
                onClick={() => {
                  i18next.changeLanguage("en");
                  setLanguage(false);
                  toggleUserGuideURL(false);
                }}
                className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
              >
                {/* <img
                  src={englishFlagIcon}
                  alt=""
                  className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                /> */}
                <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                  English
                </span>
              </div>
              <div
                onClick={() => {
                  i18next.changeLanguage("fr");
                  setLanguage(false);
                  toggleUserGuideURL(true);
                }}
                className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
              >
                {/* <img
                  src={frenchFlagIcon}
                  alt=""
                  className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                /> */}
                <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                  Français
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EmployeeSignUpHeader = ({ toggleUserGuideURL }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [language, setLanguage] = useState(false);
  const [size, setSize] = useState(window.screen.width);
  const language_selected = Cookies.get("i18next");
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setSize(window.screen.width);
    });
  }, []);
  return (
    <div className="flex flex-shrink-0 justify-between w-full h-24 2xl:h-32 sm_ss:bg-blue sm_s:bg-blue">
      <div className="flex items-center w-11/12">
        <Link to={"/"} className="h-full w-32 min-w-[82px] cursor-pointer">
          <img
            src={size < 650 ? MainHeaderLogo : SecondaryHeaderLogo}
            alt="Icon"
            className="h-full w-full p-4 2xl:p-6 cursor-pointer bg-blue sm_ss:bg-white sm_s:bg-white"
          />
        </Link>
        <p className="uppercase md:text-blue lg:text-blue 2xl:text-blue ml-5 sm_ss:ml-3 sm_s:ml-3 sm_s:font-extrabold sm_ss:font-extrabold sm_ss:text-base sm_s:text-base mdx:font-extrabold 2lg:text-4xl text-white 2xl:text-5xl 2xl:ml-10 lg:tracking-wide md:text-[28px] md:font-extrabold font-bold py-4">
          {t("HeaderTitle")}
        </p>
        <p className="uppercase sm:hidden sm_ss:hidden sm_s:hidden md:mt-0 2xl:text-4xl md:text-3xl text-gray mt-1 2xl:mt-1">
          {t("Employee")}
        </p>
      </div>
      <div className="flex items-center justify-end p-5 w-1/5 sm:w-1/5 sm_ss:w-1/7 sm_s:w-2/12">
        <div className="md:w-36 flex items-center text-white mr-3 ">
          <Link to={appRoutes.EmployerLoginStep3}>
            <MdLockOutline
              size="24px"
              className="mb-1 sm_ss:text-white sm_s:text-white text-black"
            />
          </Link>
          <Link
            to={
              location.pathname.includes("employer")
                ? appRoutes.EmployerLoginStep3
                : appRoutes.EmployeeLoginStep3
            }
          >
            <p className="cursor-pointer sm_ss:hidden sm_s:hidden text-sectext">
              {t("HeaderLogin")}
            </p>
          </Link>
        </div>
        <div className="h-5 w-5 min-w-[20px] rounded-xl cursor-pointer mr-4 sm_ss:mr-0 sm_s:mr-0 relative">
          {/* <img
            src={language_selected === "en" ? englishFlagIcon : flag}
            alt=""
            className="w-full h-full rounded-full object-cover"
            onClick={() => setLanguage(!language)}
          /> */}
          <span
            onClick={() => setLanguage(!language)}
            className="w-full h-full rounded-full object-cover bg-white p-1"
          >
            {language_selected === "en" ? "EN" : "FR"}
          </span>
          {language && (
            <div className="z-50 rounded-sm h-16 w-16 2xl:h-18 2xl:w-16 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
              <div
                onClick={() => {
                  i18next.changeLanguage("en");
                  setLanguage(false);
                  toggleUserGuideURL(false);
                }}
                className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
              >
                {/* <img
                  src={englishFlagIcon}
                  alt=""
                  className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                /> */}
                <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                  English
                </span>
              </div>
              <div
                onClick={() => {
                  i18next.changeLanguage("fr");
                  setLanguage(false);
                  toggleUserGuideURL(true);
                }}
                className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
              >
                {/* <img
                  src={frenchFlagIcon}
                  alt=""
                  className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                /> */}
                <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                  Français
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AuthHeaderEmployer = ({ toggleUserGuideURL }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [language, setLanguage] = useState(false);
  const [menu, setshowMenu] = useState(false);
  const [show, setShow] = useState(false);
  const language_selected = Cookies.get("i18next");
  const name = JSON.parse(localStorage.getItem("userInfo"))?.data?.result?.name;
  const last_name = JSON.parse(localStorage.getItem("userInfo"))?.data?.result
    ?.last_name;
  useEffect(() => {
    if (show === true) {
      MySwal.fire({
        title: (
          <p style={{ fontFamily: "museo-sans, sans-serif", fontSize: "18px" }}>
            {t("Logout_Message")}
          </p>
        ),
        icon: "info",
        showCancelButton: true,
        confirmButtonText: t("Yes"),
        cancelButtonText: t("Cancel"),
      }).then((res) => {
        if (res.isConfirmed) {
          logout(navigate);
        }
      });
      setShow(false);
    }
  }, [show, t, navigate]);

  return (
    <div className="flex flex-shrink-0 2xl:h-32 justify-between w-full h-24 relative">
      <div className="flex items-center w-11/12 sm_ss:w-10/12 sm_s:w-10/12">
        <div className="h-full w-32 min-w-[82px] cursor-pointer">
          <Link to={appRoutes.EmployerDashboard}>
            <img
              src={SecondaryHeaderLogo}
              alt="Icon"
              className="h-full w-full p-4 2xl:p-6 cursor-pointer bg-blue "
            />
          </Link>
        </div>

        <p className="uppercase text-blue sm_s:text-blue sm_ss:text-blue 2lg:text-3xl 2xl:text-4xl 2xl:ml-3 md:text-2xl sm:text-xl sm_ss:text-xs sm_s:text-sm font-extrabold p-4">
          {t("HeaderTitle")}
        </p>
        <p className="uppercase sm_ss:hidden sm_s:hidden md:mt-0 2xl:text-4xl md:text-2xl text-gray mt-1 2xl:mt-1">
          {t("Employer")}
        </p>
      </div>
      <div className="flex items-center justify-end w-1/5 ">
        <div className="flex items-center w-full justify-evenly">
          <div className="flex flex-col justify-start w-max sm_ss:hidden sm_s:hidden">
            <div className="flex h-7">
              <img src={EmployeeIcon} alt="" className="h-5 w-5 mr-1" />
              <Link
                reloadDocument={
                  location.pathname === appRoutes.EmployerDashboard
                }
                to={appRoutes.EmployerDashboard}
              >
                <span className="text-blue cursor-pointer capitalize">
                  {t("Employees")}
                </span>
              </Link>
            </div>
            <div className="flex h-7">
              <img src={UserProfileIcon} alt="" className="h-5 w-5 mr-1" />
              <span
                className="text-blue cursor-pointer capitalize"
                onClick={() => {
                  navigate(appRoutes.EmployerAbout);
                }}
              >
                {`${name} ${last_name}`}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center sm_ss:hidden sm_s:hidden h-full">
            <div className=" w-[20px] h-[20px] rounded-xl cursor-pointer mr-3 sm_ss:mr-0 sm_s:mr-0 relative">
              {/* <img
                src={language_selected === "en" ? englishFlagIcon : flag}
                alt=""
                className="w-full h-full rounded-full object-cover"
                onClick={() => setLanguage(!language)}
              /> */}
              <span
                onClick={() => setLanguage(!language)}
                className="w-full h-full rounded-full object-cover bg-blue p-1 text-white"
              >
                {language_selected === "en" ? "EN" : "FR"}
              </span>
              {language && (
                <div className="z-50 rounded-sm h-16 w-16 2xl:h-18 2xl:w-16 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
                  <div
                    onClick={() => {
                      i18next.changeLanguage("en");
                      setLanguage(false);
                      toggleUserGuideURL(false);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    {/* <img
                      src={englishFlagIcon}
                      alt=""
                      className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                    /> */}
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      English
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      i18next.changeLanguage("fr");
                      setLanguage(false);
                      toggleUserGuideURL(true);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    {/* <img
                      src={frenchFlagIcon}
                      alt=""
                      className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                    /> */}
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      Français
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="md:hidden lg:hidden smx:hidden h-full flex items-center">
          <div className="w-[50px] h-full flex items-center justify-center relative">
            <img
              src={menu_mobile}
              alt=""
              className="w-[25px] h-[20px]"
              onClick={() => setshowMenu(!menu)}
            />
          </div>
          <div className="flex items-center justify-center p-5 sm_ss:p-0 sm_s:p-0 w-1/5 sm_ss:w-[40px] sm_s:w-[50px] h-full">
            <div className=" w-[20px] rounded-xl cursor-pointer mr-4 sm_ss:mr-0 sm_s:mr-0 relative">
              {/* <img
                src={language_selected === "en" ? englishFlagIcon : flag}
                alt=""
                className="w-full h-full rounded-full object-cover"
                onClick={() => setLanguage(!language)}
              /> */}
              <span
                onClick={() => setLanguage(!language)}
                className="w-full h-full rounded-full object-cover bg-blue p-1 text-white"
              >
                {language_selected === "en" ? "EN" : "FR"}
              </span>
              {language && (
                <div className=" rounded-sm h-12 w-22 2xl:h-18 2xl:w-24 flex flex-col absolute justify-around top-8 -left-16 2xl:-left-14 bg-white">
                  <div
                    onClick={() => {
                      i18next.changeLanguage("en");
                      setLanguage(false);
                      toggleUserGuideURL(false);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    {/* <img
                      src={englishFlagIcon}
                      alt=""
                      className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                    /> */}
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      English
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      i18next.changeLanguage("fr");
                      setLanguage(false);
                      toggleUserGuideURL(true);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    {/* <img
                      src={frenchFlagIcon}
                      alt=""
                      className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                    /> */}
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      Français
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-blue w-10 h-full flex justify-center items-center sm_ss:hidden sm_s:hidden">
          <img
            src={LogOutIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => setShow(true)}
          />
        </div>
      </div>
      {menu && <MobileMenu menu={menu} setshowMenu={setshowMenu} />}
    </div>
  );
};

const PendingHeader = ({ toggleUserGuideURL }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (show === true) {
      MySwal.fire({
        title: (
          <p style={{ fontFamily: "museo-sans, sans-serif", fontSize: "18px" }}>
            {t("Logout_Message")}
          </p>
        ),
        icon: "info",
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
        confirmButtonText: t("Yes"),
      }).then((res) => {
        if (res.isConfirmed) {
          logout(navigate);
        }
      });
      setShow(false);
    }
  }, [show, t, navigate]);

  const userRole = JSON.parse(localStorage.getItem("userInfo"))?.data?.result
    ?.role;

  return (
    <div className="flex flex-shrink-0 2xl:h-32 justify-between w-full h-24">
      <div className="flex items-center w-11/12">
        <div className="h-full w-32 min-w-[82px] cursor-pointer">
          <img
            src={SecondaryHeaderLogo}
            alt="Icon"
            className="h-full w-full p-4 2xl:p-6 cursor-pointer bg-blue "
          />
        </div>
        <p className="uppercase text-blue sm_s:text-blue sm_ss:text-blue 2lg:text-3xl 2xl:text-4xl 2xl:ml-3 md:text-xl sm:text-xl sm_ss:text-xs sm_s:text-sm font-extrabold p-4">
          {t("HeaderTitle")}
        </p>
        <p className="uppercase sm_ss:hidden sm_s:hidden md:mt-0 2xl:text-4xl md:text-xl text-gray mt-1 2xl:mt-1">
          {userRole === "employer" ? t("Employer") : t("Employee")}
        </p>
      </div>
      <div className="flex items-center justify-end  w-1/4 ">
        <div className="flex flex-col justify-start w-full pl-10"></div>
        <div className="bg-blue w-10 h-full flex justify-center items-center">
          <img
            src={LogOutIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => setShow(true)}
          />
        </div>
      </div>
    </div>
  );
};

const AuthHeaderEmployee = ({ toggleUserGuideURL }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [menu, setshowMenu] = useState(false);
  const [language, setLanguage] = useState(false);
  const [show, setShow] = useState(false);
  const language_selected = Cookies.get("i18next");
  const name = JSON.parse(localStorage.getItem("userInfo"))?.data?.result?.name;
  const lastName = JSON.parse(localStorage.getItem("userInfo"))?.data?.result
    ?.last_name;
  // const role = JSON.parse(localStorage.getItem("userInfo"))?.data?.result?.role;
  useEffect(() => {
    if (show === true) {
      MySwal.fire({
        title: (
          <p style={{ fontFamily: "museo-sans, sans-serif", fontSize: "18px" }}>
            {t("Logout_Message")}
          </p>
        ),
        icon: "info",
        showCancelButton: true,
        confirmButtonText: t("Yes"),
        cancelButtonText: t("Cancel"),
      }).then((res) => {
        if (res.isConfirmed) {
          logout(navigate);
        }
      });
      setShow(false);
    }
  }, [show, t, navigate]);

  return (
    <div className="flex flex-shrink-0 2xl:h-32 justify-between w-full h-24">
      <div className="flex items-center w-11/12">
        <div className="h-full w-32 min-w-[82px] cursor-pointer">
          <Link to={appRoutes.EmployeeDashboard}>
            <img
              src={SecondaryHeaderLogo}
              alt="Icon"
              className="h-full w-full p-4 2xl:p-6 cursor-pointer bg-blue "
            />
          </Link>
        </div>
        <p className="uppercase text-blue sm_s:text-blue sm_ss:text-blue 2lg:text-3xl 2xl:text-4xl 2xl:ml-3 md:text-2xl sm:text-xl sm_ss:text-xs sm_s:text-sm font-extrabold p-4">
          {t("HeaderTitle")}
        </p>
        <p className="uppercase sm_ss:hidden sm_s:hidden md:mt-0 2xl:text-4xl md:text-2xl text-gray mt-1 2xl:mt-1">
          {t("Employee")}
        </p>
      </div>
      <div className="flex items-center justify-end  w-1/4 ">
        <div className="flex items-center w-full justify-evenly">
          <div className="flex flex-col justify-start pl-10 sm_ss:hidden sm_s:hidden">
            <div className="flex h-7">
              <img src={EmployeeIcon} alt="" className="h-5 w-5 mr-2" />
              <Link
                reloadDocument={
                  location.pathname === appRoutes.EmployeeDashboard
                }
                to={appRoutes.EmployeeDashboard}
              >
                <span className="text-blue cursor-pointer capitalize">
                  {t("Employers")}
                </span>
              </Link>
            </div>
            <div className="flex h-7">
              <img src={UserProfileIcon} alt="" className="h-5 w-5 mr-2" />
              <span
                onClick={() => navigate(appRoutes.EmployeeAbout)}
                className="text-blue cursor-pointer capitalize"
              >
                {`${name} ${lastName}`}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center sm_ss:hidden sm_s:hidden h-full">
            <div className=" w-[20px] h-[20px] rounded-xl cursor-pointer mr-3 sm_ss:mr-0 sm_s:mr-0 relative">
              {/* <img
                src={language_selected === "en" ? englishFlagIcon : flag}
                alt=""
                className="w-full h-full rounded-full object-cover"
                onClick={() => setLanguage(!language)}
              /> */}
              <span
                onClick={() => setLanguage(!language)}
                className="w-full h-full rounded-full object-cover bg-blue p-1 text-white"
              >
                {language_selected === "en" ? "EN" : "FR"}
              </span>
              {language && (
                <div className="z-50 rounded-sm h-16 w-16 2xl:h-18 2xl:w-16 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
                  <div
                    onClick={() => {
                      i18next.changeLanguage("en");
                      setLanguage(false);
                      toggleUserGuideURL(false);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    {/* <img
                      src={englishFlagIcon}
                      alt=""
                      className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                    /> */}
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      English
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      i18next.changeLanguage("fr");
                      setLanguage(false);
                      toggleUserGuideURL(true);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    {/* <img
                      src={frenchFlagIcon}
                      alt=""
                      className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                    /> */}
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      Français
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="md:hidden lg:hidden smx:hidden h-full flex items-center">
          <div className="w-[50px] h-full flex items-center justify-center relative">
            <img
              src={menu_mobile}
              alt=""
              className="w-[25px] h-[20px]"
              onClick={() => setshowMenu(!menu)}
            />
          </div>
          <div className="flex items-center justify-center p-5 sm_ss:p-0 sm_s:p-0 w-1/5 sm_ss:w-[40px] sm_s:w-[50px] h-full">
            <div className=" w-[20px] rounded-xl cursor-pointer mr-4 sm_ss:mr-0 sm_s:mr-0 relative">
              {/* <img
                src={language_selected === "en" ? englishFlagIcon : flag}
                alt=""
                className="w-full h-full rounded-full object-cover"
                onClick={() => setLanguage(!language)}
              /> */}
              <span
                onClick={() => setLanguage(!language)}
                className="w-full h-full rounded-full object-cover bg-blue p-1 text-white"
              >
                {language_selected === "en" ? "EN" : "FR"}
              </span>

              {language && (
                <div className=" rounded-sm h-12 w-22 2xl:h-18 2xl:w-24 flex flex-col absolute justify-around top-8 -left-16 2xl:-left-14 bg-white">
                  <div
                    onClick={() => {
                      i18next.changeLanguage("en");
                      setLanguage(false);
                      toggleUserGuideURL(false);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    {/* <img
                      src={englishFlagIcon}
                      alt=""
                      className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                    /> */}
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      English
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      i18next.changeLanguage("fr");
                      setLanguage(false);
                      toggleUserGuideURL(true);
                    }}
                    className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
                  >
                    {/* <img
                      src={frenchFlagIcon}
                      alt=""
                      className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                    /> */}
                    <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                      Français
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-blue w-10 h-full flex justify-center items-center sm_ss:hidden sm_s:hidden">
          <img
            src={LogOutIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => setShow(true)}
          />
        </div>
      </div>
      {menu && <MobileMenu menu={menu} setshowMenu={setshowMenu} />}
    </div>
  );
};

const DefaultHeader = ({ toggleUserGuideURL }) => {
  const location = useLocation();
  const [language, setLanguage] = useState(false);
  const { t } = useTranslation();
  const [size, setSize] = useState(window.screen.width);
  const language_selected = Cookies.get("i18next");
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setSize(window.screen.width);
    });
  }, [size]);
  return (
    <div className="flex bg-blue flex-shrink-0 justify-between w-full h-24 2xl:h-32">
      <div className="flex items-center w-full sm_ss:w-10/12 sm_s:w-10/12">
        <Link to={"/"} className="h-full w-32 min-w-[82px] cursor-pointer">
          <img
            src={MainHeaderLogo}
            alt="Icon"
            className="h-full w-full p-4 sm_s:p-4 sm_ss:p-4 2xl:p-5 cursor-pointer bg-white"
          />
        </Link>
        <p className="uppercase ml-5 sm_ss:ml-0 sm_s:ml-0 sm_s:font-extrabold sm_ss:font-extrabold sm_ss:text-base sm_s:text-base mdx:font-extrabold 2lg:text-4xl text-white 2xl:text-5xl 2xl:ml-10 lg:tracking-wide md:text-[28px] md:font-extrabold sm:text-xl font-bold p-4">
          {t("HeaderTitle")}
        </p>
      </div>
      <div className="flex items-center justify-end p-5 w-1/5 sm:w-1/5 sm_ss:w-1/7 sm_s:w-2/12">
        <div className="md:w-36 flex items-center text-white mr-3 ">
          <Link to={appRoutes.EmployerLoginStep3}>
            <MdLockOutline color="white" size="24px" className="mb-1" />
          </Link>
          <Link
            to={
              location.pathname.includes("employer")
                ? appRoutes.EmployerLoginStep3
                : appRoutes.EmployeeLoginStep3
            }
          >
            <p className="cursor-pointer sm_ss:hidden sm_s:hidden">
              {t("HeaderLogin")}
            </p>
          </Link>
        </div>
        <div className="h-auto min-w-[20px] rounded-xl cursor-pointer mr-4 sm_ss:mr-0 sm_s:mr-0 relative">
          {/* <img
            src={language_selected === "en" ? englishFlagIcon : flag}
            alt=""
            className="w-full h-full rounded-full object-cover"
            onClick={() => setLanguage(!language)}
          /> */}
          <span
            onClick={() => setLanguage(!language)}
            className="w-full h-full rounded-full object-cover bg-white p-1"
          >
            {language_selected === "en" ? "EN" : "FR"}
          </span>
          {language && (
            <div className="z-50 rounded-sm h-16 w-16 2xl:h-18 2xl:w-18 flex flex-col absolute justify-around top-8 -left-13 2xl:-left-14 bg-white">
              <div
                onClick={() => {
                  i18next.changeLanguage("en");
                  setLanguage(false);
                  toggleUserGuideURL(false);
                }}
                className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
              >
                {/* <img
                  src={englishFlagIcon}
                  alt=""
                  className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                /> */}
                <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                  English
                </span>
              </div>
              <div
                onClick={() => {
                  i18next.changeLanguage("fr");
                  setLanguage(false);
                  toggleUserGuideURL(true);
                }}
                className="flex group items-center justify-evenly hover:text-white hover:bg-blue flex-1"
              >
                {/* <img
                  src={frenchFlagIcon}
                  alt=""
                  className="h-5 w-5 2xl:h-8 2xl:w-8 rounded-full"
                /> */}
                <span className="text-sectext text-sm group-hover:text-white text-center 2xl:text-base">
                  Français
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MobileMenu = ({ menu, setshowMenu }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const name = JSON.parse(localStorage.getItem("userInfo"))?.data?.result?.name;
  const location = useLocation();
  const navigate = useNavigate();
  const handleLogout = () => {
    setShow(true);
  };
  useEffect(() => {
    if (show === true) {
      MySwal.fire({
        title: (
          <p style={{ fontFamily: "museo-sans, sans-serif", fontSize: "18px" }}>
            {t("Logout_Message")}
          </p>
        ),
        icon: "info",
        showCancelButton: true,
        confirmButtonText: t("Yes"),
        cancelButtonText: t("Cancel"),
      }).then((res) => {
        if (res.isConfirmed) {
          logout(navigate);
        }
      });
      setShow(false);
    }
  }, [show, t, navigate]);
  const handleRoute = (id) => {
    setshowMenu(false);
    if (id === "listing") {
      const path = location.pathname.includes("employer")
        ? appRoutes.EmployerDashboard
        : appRoutes.EmployeeDashboard;
      navigate(path);
    }
    if (id === "about") {
      const path = location.pathname.includes("employer")
        ? appRoutes.EmployerAbout
        : appRoutes.EmployeeAbout;
      navigate(path);
    }
  };
  return (
    <>
      {menu && (
        <div className="w-full z-50 overflow-hidden absolute top-0 left-0 h-screen bg-blue  items-start">
          <div className="h-24 flex justify-between">
            <div className="flex items-center w-11/12 sm_ss:w-10/12 sm_s:w-10/12">
              <div className="h-full w-32 min-w-[82px] cursor-pointer">
                <img
                  src={SecondaryHeaderLogo}
                  alt="Icon"
                  className="h-full w-full p-4 2xl:p-6 cursor-pointer bg-blue "
                />
              </div>
              <p className="uppercase text-blue sm_s:text-blue sm_ss:text-blue 2lg:text-4xl 2xl:text-5xl 2xl:ml-3 md:text-3xl sm:text-xl sm_ss:text-xs sm_s:text-sm font-extrabold p-4">
                {t("HeaderTitle")}
              </p>
            </div>
            <div className="md:hidden lg:hidden flex items-center justify-center">
              <div className="h-full flex items-center justify-center relative mr-6">
                <i
                  className="fa-solid fa-xmark text-3xl text-white"
                  onClick={() => setshowMenu(false)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col p-4">
            <div
              className="flex cursor-pointer h-[30px] items-center mb-2"
              onClick={() => handleRoute("listing")}
            >
              <FaUser className="text-white text-xl mr-2" />
              <p className="text-white">
                {location.pathname.includes("employer")
                  ? t("Employers")
                  : t("Employees")}
              </p>
            </div>
            <div
              className="flex cursor-pointer h-[30px] items-center mb-2"
              onClick={() => handleRoute("about")}
            >
              <FaIdBadge className="text-white text-xl mr-2" />
              <p className="text-white">{name}</p>
            </div>
            <div
              className="flex cursor-pointer h-[30px] items-center mb-2"
              onClick={handleLogout}
            >
              <img src={LogOutIcon} alt="" className="-ml-[6px] mr-2" />
              <p className="text-white">{t("Logout")}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Header;
