import Cookies from "js-cookie";
import moment from "moment/moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const EmployeeDetails = ({ onGoBack, data }) => {
  const language_selected = Cookies.get("i18next");
  const { t } = useTranslation();
  const AuthError = useSelector((state) => state.userSession.error);
  useEffect(() => {
    if (AuthError === true) {
      localStorage.clear();
    }
  }, [AuthError]);

  const {
    required_languages,
    work_types,
    is_accommodation_provided,
    prefer_gender,
  } = data;

  console.log("data", data);

  const languages = required_languages?.map((val, i) => {
    return (
      <p key={i} className=" mr-1">
        {t(val.name)}
      </p>
    );
  });

  const typesOfWork = work_types?.map((val) => {
    return <p className=" mr-1">{t(val.name)}</p>;
  });

  const before =
    language_selected === "en"
      ? data?.desired_starting === "Before"
        ? "Before"
        : ""
      : "Avant le";

  const NotBefore =
    language_selected === "en"
      ? data?.desired_starting === "Not before"
        ? "Not before"
        : ""
      : "Pas avant le";

  const immediately =
    language_selected === "en"
      ? data?.desired_starting === "Immediately"
        ? "Immediately"
        : ""
      : "Immédiatement";

  const toBeAgreed =
    language_selected === "en"
      ? data?.desired_starting === "To be agreed"
        ? "To be agreed"
        : ""
      : "A convenir";

  const date =
    data?.desired_starting === "Before" ||
    data?.desired_starting === "Not before"
      ? `${data?.desired_starting === "Before" ? before : NotBefore} ${moment(
          data?.desired_starting_date
        ).format("DD-MM-YYYY")}`
      : data?.desired_starting === "Immediately"
      ? immediately
      : toBeAgreed;

  const languagesData = [...languages, data?.other_required_language];
  const workTypeData = [...typesOfWork, data?.other_work_type];

  return (
    <>
      <div className="flex flex-col md:flex-row text-center my-4">
        <h2 className="text-2xl md:text-4xl  font-bold pb-2 text-[#6f6f6f]">
          {t("Employer")}
        </h2>
        <div
          onClick={onGoBack}
          className="my-auto cursor-pointer mx-2 md:mx-4 text-blue"
        >
          {"<"} {t("Back")}
        </div>
      </div>
      <div className="flex w-full justify-between flex-col-reverse md:flex-row">
        <div className="my-2 md:w-4/5 w-full">
          <div className="grid gap-1 mb-6 bg-white overflow-hidden md:rounded-xl pb-[16px] pt-[40px] px-7 w-full">
            <div className="flex items-center">
              <div className="flex flex-col md:w-36 w-full">
                <Details title={t("DetailTitle")} value={data?.user?.title} />
                <Details title={t("Firstname")} value={data?.user?.name} />
                <Details title={t("Lastname")} value={data?.user?.last_name} />
                <Details
                  title={t("nationality")}
                  value={data?.nationalities?.map((el, i) => {
                    return <div className="flex flex-col">{el}</div>;
                  })}
                />
                <Details
                  title={t("mobile")}
                  value={
                    data?.user?.mobile_number
                      ? `+${data?.user?.mobile_number}`
                      : "_"
                  }
                />
                <a href={`mailto:${data?.user?.email}`}>
                  <Details
                    className="text-blue underline"
                    title={t("privateEmail")}
                    value={data?.user?.email}
                  />
                </a>
                <Details
                  title={t("Organisation")}
                  value={data?.user?.organization_name}
                />
                <Details
                  title={t("Official")}
                  value={data?.user?.official_function}
                />

                <Details
                  title={t("Household")}
                  value={`${data?.user?.user_details?.number_of_persons} ${t(
                    "persons"
                  )}`}
                />

                {data?.user?.child_details && (
                  <Details
                    title={t("Age")}
                    className="flex"
                    value={data?.user?.child_details?.map((val, i) => (
                      <p className="ml-1">{`${val?.age} ${t("years")},`}</p>
                    ))}
                  />
                )}
                <Details
                  title={t("Address")}
                  value={data?.user?.user_details?.street_number}
                />
                <Details title={t("zipCode")} value={data?.user?.zip_code} />
                <Details title={t("city")} value={data?.user?.city_id} />
                <Details title={t("Country")} value={t("Switzerland")} />
              </div>
            </div>
          </div>
          <div className="grid gap-1 mb-6 bg-white md:rounded-xl pb-[16px] pt-[40px]  px-7 w-full overflow-hidden">
            <div className="flex items-center">
              <div className="flex flex-col w-auto">
                <Details
                  title={t("Language/s required")}
                  value={languagesData?.map((val) => val)}
                />
                <Details
                  title={t("WorkType")}
                  value={workTypeData?.map((val) => val)}
                />
                <Details
                  title={t("Accomodation provided")}
                  value={
                    is_accommodation_provided === "no"
                      ? t("No (availability of external accomodation)")
                      : t(is_accommodation_provided)
                  }
                />
                <Details title={t("EmplStart")} value={date} />
                {prefer_gender !== "indifferent" && (
                  <Details title={t("Preference")} value={t(prefer_gender)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function Details({ title, value, className }) {
  return (
    <div className="flex md:justify-between lg:justify-between items-start h-auto md:w-[500px] w-[600px] text-base md:text-md lg:text-lg">
      <div className="mb-2 md:min-w-[200px] w-[120px] md:w-[200px]">
        {title}
      </div>
      <div className="w-2 md:w-10" />
      <div
        className={`mb-2 flex flex-col sm_s:w-50 sm_ss:w-50 md:w-[300px] lg:w-[300px] ${className}`}
      >
        {value}{" "}
      </div>
    </div>
  );
}

export default EmployeeDetails;
