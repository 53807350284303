import { t } from "i18next";
import moment from "moment";

function validateEmail(email) {
  var re =
    /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/gm;
  return re.test(email);
}

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();

export const maxDate = `${dd}-${mm}-${yyyy}`;
export const currentDate = yyyy + "-" + mm + "-" + dd;
export const CD = moment(currentDate).format("YYYY-MM-DD");

export const validations = (data, images, email, number) => {
  const errors = {};
  if (data.title === "" || data.title === undefined) {
    errors.title = t("titleErr");
  }

  if (data.first_name === "" || data.first_name === undefined) {
    errors.first_name = t("FnameErr");
  } else if (data.first_name.length < 2) {
    errors.first_name = t("FnameErrLnt");
  }

  if (data.last_name === "" || data.last_name === undefined) {
    errors.last_name = t("LnameErr");
  } else if (data.last_name.length < 2) {
    errors.last_name = t("LnameErrLnt");
  }

  if (data.email === "" || data.email === undefined) {
    errors.email = t("WorkEmailErr");
  } else if (!validateEmail(data.email)) {
    errors.email = t("ValidEmailErr");
  }

  if (!email) {
    if (data.mobile_number === "" || data.mobile_number === undefined) {
      errors.mobile_number = t("MobileNoErr");
    } else if (
      data.mobile_number.length < 8 ||
      data.mobile_number.length > 13
    ) {
      errors.mobile_number = t("MobileNoErrLnt");
    }
  }

  if (
    data.message_prefer_language === "" ||
    data.message_prefer_language === undefined
  ) {
    errors.message_prefer_language = t("MessagePreferErr");
  }

  if (data.password === "" || data.password === undefined) {
    errors.password = t("PasswordErr");
  } else if (data.password.length < 8) {
    errors.password = t("PassLntErr");
  } else if (data.password !== data.confirmpassword) {
    errors.passwordConfirm = t("ConfirmPassErr");
  }
  if (data.nationality?.length === 0) {
    errors.nationality = t("NationalityErr");
  }

  if (data.date_of_birth === CD) {
    errors.date_of_birth = t("DOBErr");
  } else if (data.date_of_birth > CD) {
    errors.date_of_birth = t("DOBErr");
  }

  // if (data.organization_name === "" || data.organization_name === undefined) {
  //   errors.organization_name = t("SelectORG");
  // }

  // if (data.official_function === "" || data.official_function === undefined) {
  //   errors.official_function = t("officialfunctionErr");
  // }

  if (data.number_of_persons === "" || data.number_of_persons === undefined) {
    errors.number_of_persons = t("NumberOfPersonErr");
  } else if (data.number_of_persons < 0) {
    errors.number_of_persons = t("ValidNumberOfPersonErr");
  }

  // if (
  //   data.legitimation_card_number === "" ||
  //   data.legitimation_card_number === undefined
  // ) {
  //   errors.legitimation_card_number = t("legitimateCardNoErr");
  // }

  // if (images?.length < 1) {
  //   errors.legitimation_card_images = t("ImageSErr");
  // }

  if (
    data.legitimation_card_type === "" ||
    data.legitimation_card_type === undefined
  ) {
    errors.legitimation_card_type = t("legitimateCardTypeErr");
  }

  if (!data.canton) {
    errors.cantons = t("cantonErr");
  }
  return errors;
};

export const employeeValidations = (data, images, email, number) => {
  const errors = {};

  if (data.title === "" || data.title === undefined) {
    errors.title = t("titleErr");
  }

  if (data.first_name === "" || data.first_name === undefined) {
    errors.first_name = t("FnameErr");
  } else if (data.first_name.length < 2) {
    errors.first_name = t("FnameErrLnt");
  }

  if (data.last_name === "" || data.last_name === undefined) {
    errors.last_name = t("LnameErr");
  } else if (data.last_name.length < 2) {
    errors.last_name = t("LnameErrLnt");
  }

  if (data.email === "" || data.email === undefined) {
    errors.email = t("WorkEmailErr");
  } else if (!validateEmail(data.email)) {
    errors.email = t("ValidEmailErr");
  }

  if (!email || email === undefined) {
    if (data.mobile_number === "" || data.mobile_number === undefined) {
      errors.mobile_number = t("MobileNoErr");
    } else if (
      data.mobile_number.length < 8 ||
      data.mobile_number.length > 13
    ) {
      errors.mobile_number = t("MobileNoErrLnt");
    }
  }

  // if (data.mobile_number === "" || data.mobile_number === undefined) {
  //   errors.mobile_number = t("MobileNoErr");
  // } else if (data.mobile_number.length < 8 || data.mobile_number.length > 13) {
  //   errors.mobile_number = t("MobileNoErrLnt");
  // }

  if (
    data.message_prefer_language === "" ||
    data.message_prefer_language === undefined
  ) {
    errors.message_prefer_language = t("MessagePreferErr");
  }

  if (data.password === "" || data.password === undefined) {
    errors.password = t("PasswordErr");
  } else if (data.password.length < 8) {
    errors.password = t("PassLntErr");
  } else if (data.password !== data.confirmpassword) {
    errors.passwordConfirm = t("ConfirmPassErr");
  }

  if (data?.nationality?.length === 0) {
    errors.nationality = t("NationalityErr");
  } else {
  }

  if (data.date_of_birth === CD) {
    errors.date_of_birth = t("DOBErr");
  } else if (data.date_of_birth > CD) {
    errors.date_of_birth = t("DOBErr");
  }

  // if (
  //   data.legitimation_card_number === "" ||
  //   data.legitimation_card_number === undefined
  // ) {
  //   errors.legitimation_card_number = t("legitimateCardNoErr");
  // }

  if (
    data.legitimation_card_type === "" ||
    data.legitimation_card_type === undefined
  ) {
    errors.legitimation_card_type = t("legitimateCardTypeErr");
  }

  // if (images?.length === 0) {
  //   errors.legitimation_card_images = t("ImageSErr")
  // }

  if (!data.canton) {
    errors.cantons = t("cantonErr");
  }

  if (data.desired_starting_date === maxDate) {
    errors.desired_starting_date = true;
  }

  return errors;
};

export const NextPageFormValidations = (data) => {
  const error = {};
  if (data.required_languages?.length === 0) {
    error.required_languages = t("RequiredLangErr");
  }

  if (data.work_types?.length === 0) {
    error.work_type = t("WorkTypeErr");
  }

  if (!data.accommodation_provided) {
    error.accommodation_provided = t("AccommodationErr");
  }
  if (!data.desired_starting) {
    error.desired_starting = t("AvailabilityErr");
  }

  return error;
};

export const employeeNextPageFormValidations = (data) => {
  const error = {};
  if (data.required_languages.length === 0) {
    error.required_languages = t("RequiredLangErr");
  }

  if (data.work_types.length === 0) {
    error.work_type = t("WorkTypeErr");
  }

  if (data.work_places.length === 0) {
    error.work_places = t("WorkPlacesErr");
  }

  if (!data.accommodation_provided) {
    error.accommodation_provided = t("AccommodationErr");
  }

  if (!data.desired_starting) {
    error.desired_starting = t("AvailabilityErr");
  }
  return error;
};
