import React from "react";
import TextField from "@mui/material/TextField";

import { currentDate } from "../utils.js/FormValidations";

const TextInput = React.forwardRef(
  (
    {
      textType,
      text,
      subHeading,
      placeholder,
      sideText,
      textAfter,
      name,
      value,
      handleChange,
      id,
      disable,
      showEyeButton,
      togglePassword,
      passwordShown,
      maxDate,
      pattern,
    },
    ref
  ) => {
    return (
      <div className="flex flex-col w-full h-24 relative sm_ss:pr-0 sm_s:pr-0">
        {textAfter === true ? (
          ""
        ) : (
          <label className="text-sectext w-max mb-2">{text}</label>
        )}
        <TextField
          type={textType}
          id={id}
          variant="outlined"
          value={value}
          onChange={handleChange}
          disabled={disable}
          itemID={id}
          placeholder={placeholder}
          name={name}
          size="small"
          inputProps={{ max: `${currentDate}` }}
          className={`bg-white rounded-lg ${disable ? "bg-templategray" : ""} `}
        />

        {textAfter && <label className="text-sectext mb-1">{text}</label>}
        {sideText && (
          <p className="text-textGray absolute top-11 right-4">years old</p>
        )}
        {subHeading && (
          <p className="text-textGray py-1  mb-5">Format: +00 00 000 00 00</p>
        )}
        {showEyeButton &&
          (passwordShown ? (
            <i
              onClick={togglePassword}
              className="fa-solid fa-eye-slash absolute right-4 top-11 cursor-pointer text-sectext text-sm"
            ></i>
          ) : (
            <i
              onClick={togglePassword}
              className="fa-solid fa-eye absolute right-4 top-11 cursor-pointer text-sectext text-sm"
            ></i>
          ))}
      </div>
    );
  }
);

export default TextInput;
