import React from "react";
import RemoveIcon from "../assets/images/remove.png";

const RemoveButton = ({ handleRemoveChildren }) => {
  return (
    <div onClick={handleRemoveChildren} className="ml-2 cursor-pointer">
      <img src={RemoveIcon} alt={"remove-icon"} />
    </div>
  );
};

export default RemoveButton;
