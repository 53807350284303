import { createSlice } from "@reduxjs/toolkit";

export const employeeListing = createSlice({
  name: "employeeListing",
  initialState: {
    employeeList: [],
    employeeListLimitedResult: [],
    pending: true,
    error: false,
    success: false,
    lastPage: 1,
    currentPage: null,
  },
  reducers: {
    employeeListingStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    employeeListingSuccess: (state, action) => {
      state.success = true;
      state.employeeList = action.payload;
      state.pending = false;
      state.error = false;
    },
    employeeListingLoadMore: (state, action) => {
      state.success = true;
      state.employeeList = [...state.employeeList, ...action.payload];
      state.pending = false;
      state.error = false;
    },
    employeeListingError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
      state.success = false;
      state.employeeList = [];
    },
    setTotalPages: (state, action) => {
      state.lastPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    employeeListingReset: (state) => {
      state.pending = false;
    },
  },
});

export const {
  employeeListingError,
  employeeListingStart,
  employeeListingSuccess,
  employeeListingLoadMore,
  employeeListingReset,
  setTotalPages,
  setCurrentPage,
} = employeeListing.actions;
export default employeeListing.reducer;
