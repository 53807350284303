import { Menu, MenuItem } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaCheckCircle, FaEllipsisV, FaQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { employeeStatusTypes } from "../../constants/Common/Common";
import { EmployeeStatus, getEmployeeList } from "../../redux/apiCalls";

const EmployeeBar = ({ onClick, data, index, setPage }) => {
  const language_selected = Cookies.get("i18next");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.updateStatus.status);
  const [anchorEl, setAnchorEl] = useState(null);
  const [changestatus, setChangeStatus] = useState(false);
  const [employeeStatus, setEmployeeStatus] = useState(data?.status_data);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeStatus = (status) => {
    setEmployeeStatus(status);
    setChangeStatus(true);
    setPage(1);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (changestatus === true) {
      EmployeeStatus(data?.id, employeeStatus, dispatch);
      getEmployeeList(dispatch);
      setChangeStatus(false);
    }
  }, [
    employeeStatus,
    data?.id,
    changestatus,
    data?.status_data,
    dispatch,
    status,
  ]);

  const before =
    language_selected === "en"
      ? data?.desired_starting === "Before"
        ? "Before"
        : ""
      : "Avant le";

  const NotBefore =
    language_selected === "en"
      ? data?.desired_starting === "Not before"
        ? "Not before"
        : ""
      : "Pas avant le";

  const immediately =
    language_selected === "en"
      ? data?.desired_starting === "Immediately"
        ? "Immediately"
        : ""
      : "Immédiatement";

  const toBeAgreed =
    language_selected === "en"
      ? data?.desired_starting === "To be agreed"
        ? "To be agreed"
        : ""
      : "A convenir";

  const Availability =
    data?.desired_starting === "Before" ||
    data?.desired_starting === "Not before"
      ? `${data?.desired_starting === "Before" ? before : NotBefore} ${
          data?.desired_starting_date
        }`
      : data?.desired_starting === "Immediately"
      ? immediately
      : toBeAgreed;

  const nationality = [];
  data?.nationalities?.map((el) => nationality.push(el));
  const truncateLastName = data?.user?.last_name?.substring(0, 6);

  return (
    <div
      className={`flex sm_ss:relative sm_s:relative justify-between w-full shadow-md md:shadow-none md:bg-transparent rounded-xl p-4 md:p-0 md:my-0 my-2 flex-col-reverse md:flex-row  ${
        (status?.employee_request_id === data?.id
          ? status?.status
          : employeeStatus) === employeeStatusTypes.INTERESTED
          ? "bg-[#E1FADD]"
          : (status?.employee_request_id === data?.id
              ? status?.status
              : employeeStatus) === employeeStatusTypes.NOT_INTERESTED
          ? "bg-[#F2F2F2]"
          : "bg-white "
      }`}
    >
      <div
        key={index}
        onClick={() => onClick()}
        className={`cursor-pointer ${
          Availability ? "flex" : "flex"
        } shadow-none md:shadow-md w-full rounded-2xl text-sm md:text-md lg:text-lg p-0 md:py-7 md:px-4 md:my-3 md:w-4/5 ${
          (status?.employee_request_id === data?.id
            ? status?.status
            : employeeStatus) === employeeStatusTypes.INTERESTED
            ? "bg-[#E1FADD]"
            : (status?.employee_request_id === data?.id
                ? status?.status
                : employeeStatus) === employeeStatusTypes.NOT_INTERESTED
            ? "bg-[#F2F2F2]"
            : "bg-white "
        }`}
      >
        <div className="text-blue w-[30%] sm_ss:w-[40%] sm_s:w-[50%]">{`${t(
          data?.user?.title
        )} ${data?.user?.name} ${
          data?.user?.last_name?.length > 6
            ? `${truncateLastName}..`
            : data?.user?.last_name
        } `}</div>
        <div className="sm_ss:hidden sm_s:hidden w-[25%] text-center">
          {nationality.length > 1
            ? `${nationality[0]} + ${nationality.length - 1}`
            : nationality?.map((el) => t(`${el}`))}
        </div>
        {Availability && (
          <div className="sm_ss:hidden sm_s:hidden w-[50%] text-center">
            {t("Availability")} : {Availability}
          </div>
        )}
      </div>
      <div className="flex flex-row-reverse md:flex-row sm_ss:absolute sm_ss:bottom-0 sm_ss:top-0 sm_ss:m-auto sm_ss:right-0 sm_s:absolute sm_s:bottom-0 sm_s:top-0 sm_s:m-auto sm_s:right-0">
        <div className="flex ml-4 sm_ss:ml-0 sm_s:ml-0">
          <div
            className={`my-auto w-24 md:w-40 text-white text-xs md:text-md md:py-1 text-center font-bold rounded-md ${
              (status?.employee_request_id === data?.id
                ? status?.status
                : employeeStatus) === employeeStatusTypes.INTERESTED
                ? "bg-green-500"
                : (status?.employee_request_id === data?.id
                    ? status?.status
                    : employeeStatus) === employeeStatusTypes.NOT_DECISION
                ? "bg-blue"
                : "bg-neutral-400 "
            }`}
          >
            {(status?.employee_request_id === data?.id
              ? status?.status
              : employeeStatus) === employeeStatusTypes.INTERESTED
              ? t("Interested")
              : (status?.employee_request_id === data?.id
                  ? status?.status
                  : employeeStatus) === employeeStatusTypes.NOT_DECISION
              ? t("NoDecision")
              : t("NotInterested")}
          </div>
          <FaEllipsisV
            className="my-auto ml-2 cursor-pointer"
            size={"20px"}
            onClick={handleClick}
          />
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="w-56 rounded-lg"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          className=""
          onClick={() => {
            changeStatus(employeeStatusTypes.NOT_DECISION);
          }}
        >
          <div className="flex items-center justify-between">
            <FaQuestionCircle size={15} className="text-blue mr-1" />{" "}
            {t("NoDecision")}
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeStatus(employeeStatusTypes.INTERESTED);
          }}
        >
          <div className="flex items-center justify-between">
            <FaCheckCircle size={15} className="text-green-500 mr-1" />
            {t("Interested")}
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeStatus(employeeStatusTypes.NOT_INTERESTED);
          }}
        >
          <div className="flex items-center justify-between">
            <AiFillCloseCircle size={17} className="text-neutral-400 mr-1" />
            {t("NotInterested")}
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default EmployeeBar;
