import { API } from "../constants/apiConstants/API";
import Axios from "axios";
import { loginError, loginStart, loginSuccess } from "./slices/slice";
import {
  fetchUserProfileStart,
  fetchUserProfileSucess,
  fetchUserProfileError,
} from "./slices/FetchUserProfile";
import {
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordError,
  setPasswordError,
  setPasswordStart,
  setPasswordSuccess,
} from "./slices/ResetPassword";

import {
  employerProfileUpdateSuccess,
  employerProfileUpdateError,
  employerProfileUpdateStart,
  employerProfileNotUpdate,
} from "./slices/EmployerProfileUpdate";

import {
  employeeProfileUpdateSuccess,
  employeeProfileUpdateError,
  employeeProfileUpdateStart,
  employeeProfileNotUpdate,
} from "./slices/EmployeeProfileUpdate";

import {
  employeeListingError,
  employeeListingLoadMore,
  employeeListingStart,
  employeeListingSuccess,
  setCurrentPage,
  setTotalPages,
} from "./slices/EmployeeListing";

import axios from "axios";
import { appRoutes } from "../constants/routeConstants/Routes";
import {
  employerListingError,
  employerListingLoadMore,
  employerListingReset,
  employerListingStart,
  employerListingSuccess,
  setCurrentPageEmployer,
  setTotalPagesEmployer,
} from "./slices/EmployerList";
import {
  cancelUserRequestError,
  cancelUserRequestStart,
  cancelUserRequestSuccess,
} from "./slices/CancelUserRequest";
import {
  deleteMyAccountError,
  deleteMyAccountStart,
  deleteMyAccountSuccess,
} from "./slices/DeleteAccount";
import {
  newEmployementRequestError,
  newEmployementRequestStart,
  newEmployementRequestSuccess,
} from "./slices/NewEmployementRequest";

import { setDefaultEmailSuccess } from "./slices/DefaultEmail";
import { setDefaultNumberSuccess } from "./slices/DefaultNumber";
import {
  userSessionError,
  userSessionStart,
  userSessionSuccess,
} from "./slices/UserSession";

import {
  updateStatusError,
  updateStatusStart,
  updateStatusSuccess,
} from "./slices/UpdateStatus";
import {
  profileConError,
  profileConStart,
  profileConSuccess,
  reset,
} from "./slices/ProfileConformation";
import {
  SuspendUserAccError,
  SuspendUserAccStart,
  SuspendUserAccSuccess,
} from "./slices/SuspendUserAcc";

export const employer_login = async (credentials, dispatch, navigate) => {
  dispatch(loginStart());
  axios({
    method: "post",
    url: `${API.BASE_URL}${API.LOGIN}`,
    data: {
      ...credentials,
    },
  })
    .then((res) => {
      localStorage.setItem("userInfo", JSON.stringify(res));
      const isAuthenticated = res.data.isAuthenticated;
      const result = res.data.result;
      let Status = JSON.parse(localStorage.getItem("userInfo"));
      let activation = Status?.latest_request?.status;
      const data = {
        isAuthenticated,
        activation,
        ...result,
      };
      dispatch(loginSuccess(data));
    })
    .catch((err) => {
      dispatch(loginError(err.response.data.message));
    });
};

export const userCheckEmail = async (email, dispatch) => {
  dispatch(profileConStart());
  try {
    const data = await Axios.post(API.BASE_URL + API.CHECKUSEREXIST, { email });
    const {
      name,
      last_name,
      nationality,
      date_of_birth,
      email: userEmail,
    } = data?.data?.result;
    const res = {
      name,
      last_name,
      nationality,
      date_of_birth,
      userEmail,
    };
    dispatch(profileConSuccess(res));
  } catch (error) {
    dispatch(reset());
    dispatch(setDefaultEmailSuccess(email));
    dispatch(profileConError());
  }
};

export const userCheckNumber = async (mobile_number, dispatch) => {
  dispatch(profileConStart());
  try {
    const data = await Axios.post(API.BASE_URL + API.CHECKUSEREXIST, {
      mobile_number,
    });
    const { name, last_name, nationality, date_of_birth } = data.data.result;
    dispatch(
      profileConSuccess({ name, last_name, nationality, date_of_birth })
    );
  } catch (error) {
    dispatch(reset());
    dispatch(setDefaultNumberSuccess(mobile_number));
    dispatch(profileConError());
  }
};

export const fetchUserProfile = async (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData?.data?.result?.access_token}`;
  const email = userData.email;
  dispatch(fetchUserProfileStart());
  try {
    const res = await Axios({
      method: "GET",
      url: API.BASE_URL + API.FETCHUSERPROFILE,
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
      data: {
        email,
      },
    });
    dispatch(fetchUserProfileSucess(res.data.result));
  } catch (error) {
    dispatch(fetchUserProfileError());
  }
};

export const Countries = async (setCountries, language_selected) => {
  try {
    const response = await Axios.get(API.BASE_URL + API.COUNTRIES);
    if (response.data.result) {
      if (language_selected === "en") {
        setCountries(response.data.result.countries);
      } else {
        setCountries(response.data.result.french_countries);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const Canton = async (id, setCanton, language_selected) => {
  try {
    const response = await Axios.get(
      API.BASE_URL + `api/countries/${id}/states`
    );
    if (response.data.result) {
      if (language_selected === "en") {
        setCanton(response.data.result.english_states);
      } else {
        setCanton(response.data.result.french_states);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const resetPasswordSendLink = async (
  email,
  language_selected,
  dispatch,
  setSubmitted
) => {
  dispatch(resetPasswordStart());
  await axios({
    method: "POST",
    url: API.BASE_URL + API.FORGETPASSWORD,
    data: { email, language_selected },
  })
    .then((res) => {
      const { status, statusText } = res;
      const data = { status, statusText };
      if (status === 200) {
        setSubmitted(true);
      }
      dispatch(resetPasswordSuccess(data));
    })
    .catch((err) => {
      dispatch(resetPasswordError(err.response.data.message));
    });
};

export const ResetPassword = async (
  password,
  language_selected,
  token,
  email,
  dispatch
) => {
  dispatch(setPasswordStart());
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.RESETPASSWORD,
    data: {
      email,
      token,
      password,
      language_selected,
    },
  })
    .then((res) => {
      const { status, statusText } = res;
      const data = { status, statusText };
      dispatch(setPasswordSuccess(data));
    })
    .catch((err) => {
      dispatch(setPasswordError(err.response.data.message));
    });
};

export const employerProfileUpdate = async (dispatch, data) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData?.data?.result?.access_token}`;
  dispatch(employerProfileUpdateStart());
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.UPDATEPROFILE,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    data: {
      ...data,
    },
  })
    .then((res) => {
      if (res.data.result.is_new_request_created === false) {
        dispatch(
          employerProfileNotUpdate(res.data.result.is_new_request_created)
        );
      } else {
        dispatch(employerProfileUpdateSuccess());
        // localStorage.setItem("canton_name", res.data.result.)
      }
    })
    .catch((err) => {
      dispatch(employerProfileUpdateError(err.response.data.message));
    });
};

export const employeeProfileUpdate = async (dispatch, data) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData.data.result.access_token}`;
  dispatch(employeeProfileUpdateStart());

  await Axios({
    method: "POST",
    url: API.BASE_URL + API.UPDATEPROFILE,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    data: {
      ...data,
    },
  })
    .then((res) => {
      if (res.data.result.is_new_request_created === false) {
        dispatch(
          employeeProfileNotUpdate(res.data.result.is_new_request_created)
        );
      } else {
        dispatch(employeeProfileUpdateSuccess());
      }
    })
    .catch((err) => {
      dispatch(employeeProfileUpdateError(err.response.data.message));
    });
};

export const getEmployeeList = async (dispatch, page) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData.data.result.access_token}`;
  dispatch(employeeListingStart());
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.EMPLOYEESLIST,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    data: {
      page,
    },
  })
    .then((res) => {
      dispatch(employeeListingSuccess(res.data.result.employees));
      dispatch(setTotalPages(res.data.result.last_page));
      dispatch(setCurrentPage(res.data.result.current_page));
    })
    .catch((err) => {
      dispatch(employeeListingError(err.response));
    });
};

export const getEmployerList = async (dispatch, page) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData?.data?.result?.access_token}`;
  dispatch(employerListingStart());
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.EMPLOYERLIST,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    data: {
      page,
    },
  })
    .then((res) => {
      dispatch(employerListingSuccess(res.data.result.employers));
      dispatch(setTotalPagesEmployer(res.data.result.last_page));
      dispatch(setCurrentPageEmployer(res.data.result.current_page));
    })
    .catch((err) => {
      dispatch(employerListingError(err.response));
    });
};

export const logout = async (navigate) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData.data.result.access_token}`;
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.LOGOUT,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
  })
    .then((res) => {
      if (res.data.isAuthenticated === false) {
        localStorage.clear();
        navigate(appRoutes.Home);
      }
    })
    .catch((err) => {
      localStorage.clear();
      navigate(appRoutes.Home);
    });
};

export const cancelCurrentUserRequest = async (
  request_id,
  reason,
  dispatch,
  setRefetch
) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData.data.result.access_token}`;
  dispatch(cancelUserRequestStart());
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.CANCEL_REQUEST,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    data: {
      request_id,
      reason,
    },
  })
    .then((res) => {
      if (res.data.statusCode === 200) {
        localStorage.setItem(
          "status",
          JSON.stringify(res?.data?.result?.status)
        );
        setRefetch(true);
        dispatch(employerListingReset());
      }
      dispatch(cancelUserRequestSuccess());
    })
    .catch((err) => {
      dispatch(cancelUserRequestError());
    });
};

export const deleteMyAccount = async (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData.data.result.access_token}`;
  dispatch(deleteMyAccountStart());
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.DELETE_ACCOUNT,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
  })
    .then((res) => {
      dispatch(deleteMyAccountSuccess());
    })
    .catch((err) => {
      dispatch(deleteMyAccountError(err.response.data.message));
    });
  localStorage.clear();
  window.location.reload();
};

export const suspendMyAcc = async (dispatch, reason) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData.data.result.access_token}`;
  dispatch(SuspendUserAccStart());
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.SUSPEND_USER_ACC,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    data: {
      reason: reason,
    },
  })
    .then((res) => {
      dispatch(SuspendUserAccSuccess(res.data));
    })
    .catch((err) => {
      dispatch(SuspendUserAccError(err.response.data.message));
    });
  localStorage.clear();
  window.location.reload();
};

export const EmployeeNewRequest = async (dispatch, data) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData.data.result.access_token}`;
  dispatch(newEmployementRequestStart());
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.NEW_EMPLOYEMENT_REQUEST,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    data: {
      ...data,
    },
  })
    .then(() => dispatch(newEmployementRequestSuccess()))
    .catch((err) => {
      let error = err.response.data.message;
      dispatch(newEmployementRequestError(error));
    });
};

export const CheckUserSession = async (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData.data.result.access_token}`;
  dispatch(userSessionStart());
  await Axios({
    method: "POST",
    url: API.BASE_URL + API.CHECK_USER_SESSIO,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
  })
    .then((res) => {
      if (res.data.isAuthenticated) {
        dispatch(userSessionSuccess(res.data.isAuthenticated));
      }
    })
    .catch((err) => {
      console.log(err.response.data.isAuthenticated);
      dispatch(userSessionError(err.response.data.isAuthenticated));
    });
};

export const EmployeeStatus = async (id, status, dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const token = `Bearer ${userData.data.result.access_token}`;
  dispatch(updateStatusStart());
  await axios({
    method: "POST",
    url: API.BASE_URL + API.UPDATE_EMOLOYEE_STATUS,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    data: {
      employee_request_id: id,
      status: status,
    },
  })
    .then((res) => {
      dispatch(updateStatusSuccess(res.data.result));
    })
    .catch((err) => {
      dispatch(updateStatusError(err.response.data));
    });
};
