import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import { appRoutes } from "../constants/routeConstants/Routes";

const SetPasswordSuccess = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-signUpBG flex justify-center items-center h-fullX">
      <div className="w-1/2 bg-white py-5 rounded-lg flex flex-col justify-center items-center">
        <p className="text-sectext py-2">
          <p className="text-sectext py-2">{t("ResetPWDSuccess")}</p>
        </p>
        <p className="text-sectext py-2">{t("ResetPWDSuccess")}</p>

        <Button title={"LOGIN"} route={appRoutes.EmployeeLoginStep3} />
      </div>
    </div>
  );
};

export default SetPasswordSuccess;
