export const cardTypes = [
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
  {
    value: "D",
    label: "D",
  },
  {
    value: "in_progress",
    label: "In progress",
  },
];

export const cardTypesfr = [
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
  {
    value: "D",
    label: "D",
  },
  {
    value: "in_progress",
    label: "En cours",
  },
];

export const EmployeeCardTypes = [
  {
    value: "E",
    label: "E",
  },
  {
    value: "F",
    label: "F",
  },
  {
    value: "in_progress",
    label: "In progress",
  },
];

export const EmployeeCardTypesfr = [
  {
    value: "E",
    label: "E",
  },
  {
    value: "F",
    label: "F",
  },
  {
    value: "in_progress",
    label: "En cours",
  },
];
