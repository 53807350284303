import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { appRoutes } from "../constants/routeConstants/Routes";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";

const Footer = () => {
  const [state,setState]=useState(null) 
  const { t } = useTranslation();
  const location = useLocation();
  const language_selected = Cookies.get("i18next")
  const path =
    location.pathname === appRoutes.Home ||
    location.pathname === appRoutes.EmployerLoginStep3 ||
    location.pathname === appRoutes.EmployeeLoginStep3;
  const authPath =
    location.pathname === appRoutes.EmployeeAbout ||
    location.pathname === appRoutes.EmployerAbout;

    useEffect(()=>{
      if(language_selected){
        setState((prevVal)=> prevVal + 1)
      }
    },[language_selected])

  return (
    // <div
    //   className={`${path && "hidden"} w-full ${
    //     authPath ? "py-4 px-3" : "py-1 px-3"
    //   } bg-blue flex flex-row smx:flex-row items-center justify-between sm_ss:flex-col sm_ss:items-start sm_ss:py-3 sm_s:py-3 sm_s:flex-col sm_s:items-start sm:flex-col md:flex-row md:justify-between 2xl:h-18 z-50`}
    // >
    //   <div className="flex flex-row w-1/2 items-center justify-start sm_ss:flex-col sm_ss:items-start sm_s:flex-col sm_s:items-start">
    //     <div className=" sm_s:mr-0 flex items-center smx:w-48 md:w-80 sm_ss:flex-1 sm_s:flex-1 sm_s:justify-start sm_s:items-center sm_ss:mr-0 sm_ss:py-2 sm_s:py-1 sm_ss:w-[300px] sm_s:w-[300px]">
    //       <span>
    //         <i className="fa-solid fa-circle-info text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl"></i>
    //       </span>
    //       <a href="https://www.cagi.ch/en/about/" target="__blank">
    //         <span className="text-sm uppercase text-white cursor-pointer w-full font-semibold">
    //           {t("AboutCAGI")}
    //         </span>
    //       </a>
    //     </div>
    //     <div className="sm_ss:mr-0 sm_ss:py-2 sm_s:py-1 sm_ss:flex-1 sm_s:flex-1 w-full flex items-center sm_ss:w-[300px] sm_s:w-[300px]">
    //       <span>
    //         <i className="fa-solid fa-file-lines text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl"></i>
    //       </span>
    //       <a
    //         href="https://www.cagi.ch/en/terms-and-conditions/"
    //         target="__blank"
    //       >
    //         <span className="text-sm uppercase text-white w-[144px] cursor-pointer font-semibold">
    //           {t("TermsOfUse")}
    //         </span>
    //       </a>
    //     </div>
    //   </div>
    //   <div className="flex flex-row w-1/2 sm_s:py-1 items-center justify-end sm_ss:flex-col sm_ss:items-start sm_s:flex-col sm_s:items-start">
    //     <div className="flex items-center sm_ss:w-[300px] sm_s:w-[300px] smx:justify-end md:justify-end sm_s:justify-start sm_ss:justify-start sm_ss:flex-1 sm_s:flex-1 sm_ss:py-1 w-full md:w-60 lg:w-64">
    //       <span>
    //         <i className="fa-solid fa-circle-question text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl"></i>
    //       </span>
    //       <a href="https://www.cagi.ch/en/privacy-policy/" target="__blank">
    //         <span className="text-sm uppercase text-white cursor-pointer font-semibold">
    //           {t("UserGuide")}
    //         </span>
    //       </a>
    //     </div>
    //   </div>
    // </div>

    <div
    className={`${
      path && 'hidden'
    } w-full py-4 px-3 bg-blue flex flex-row smx:flex-col smx:items-start sm:flex-row items-center justify-between  sm_ss:items-start sm_ss:py-3 sm_s:py-3 sm_s:flex-col sm_s:items-start md:flex-row md:justify-between`}
  >
    <div className="flex flex-row w-1/2 items-center smx:flex-col smx:items-start justify-start sm_ss:flex-col sm_ss:items-start sm_s:flex-col sm_s:items-start">
      <div className=" sm_s:mr-0 flex items-center smx:w-48 sm_ss:flex-1 sm_s:flex-1 sm_s:justify-start sm_s:items-center sm_ss:mr-0 sm_ss:py-2 sm_s:py-1 sm_ss:w-[300px] sm_s:w-[300px] me-5">
        <span>
          <i className="fa-solid fa-circle-info text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl" />
        </span>
        {language_selected==="en"? <a href="https://www.cagi.ch/en/about/" target="__blank" style={{whiteSpace:'nowrap'}}>
          <span className="text-sm uppercase text-white cursor-pointer font-semibold">
            {t('AboutCAGI')}
          </span>
        </a>:<a href="https://www.cagi.ch/fr/a-propos/" target="__blank" style={{whiteSpace:'nowrap'}}>
          <span className="text-sm uppercase text-white cursor-pointer font-semibold">
            {t('AboutCAGI')}
          </span>
        </a>}
      </div>
      <div className="sm_ss:mr-0 sm_ss:py-2 sm_s:py-1 sm_ss:flex-1 sm_s:flex-1 flex items-center sm_ss:w-[300px] sm_s:w-[300px]">
        <span>
          <i className="fa-solid fa-file-lines text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl" />
        </span>

        {/* <span
          className="text-sm uppercase text-white w-[200px] cursor-pointer font-semibold"
          onClick={() => navigate(appRoutes.Terms)}
          style={{ cursor: 'pointer' }}
        >
          {t('TERMS OF USE')} */}
        {language_selected === "en"?
         <a
          href={appRoutes.termsandconditionen}
          target="_blank"
          style={{ cursor: 'pointer', color: '#fff',whiteSpace:'nowrap' }}
          rel="noreferrer"
        >
          <b>{t('TermsOfUse')}</b>
        </a>:
        <a
        href={appRoutes.termsandconditionfn}
        target="_blank"
        style={{ cursor: 'pointer', color: '#fff' }}
        rel="noreferrer"
      >
        <b>{t('TermsOfUse')}</b>
      </a>}
        {/* </span> */}

        {/* </a> */}

         
      </div>
      <div className="sm_ss:mr-0 sm_ss:py-2 sm_s:py-1 sm_ss:flex-1 sm_s:flex-1 flex items-center sm_ss:w-[300px] sm_s:w-[300px]" >
        
        <span >
          <i className="fa-solid fa-file-lines text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl" />
        </span>

        {/* <span
          className="text-sm uppercase text-white w-[240px] cursor-pointer font-semibold"
          onClick={() => navigate(appRoutes.TermsCondition)}
          style={{ cursor: 'pointer' }}
        > */}
        {language_selected ==="en" ?<a
          href={appRoutes.privacypolicyen}
          target="_blank"
          style={{ cursor: 'pointer', color: '#fff' }}
          rel="noreferrer"
        >
          <b>{t('PrivacyPolicy')}</b>
        </a>:
        <a
          href={appRoutes.privacypolicyfn}
          target="_blank"
          style={{ cursor: 'pointer', color: '#fff' ,whiteSpace:'nowrap' }}
          rel="noreferrer"
        >
          <b>{t('PrivacyPolicy')}</b>
        </a>}
        {/* </span> */}
      </div>
    </div>
    <div className="flex flex-row w-1/2 smx:flex-col smx:items-start sm_s:py-1 md:items-center lg:items-center justify-end sm_ss:flex-col sm_ss:items-start sm_s:flex-col sm_s:items-start">
      <div className="flex items-center sm_ss:w-[300px] sm_s:w-[300px] smx:justify-start md:justify-end sm_s:justify-start sm_ss:justify-start sm_ss:flex-1 sm_s:flex-1 sm_ss:py-1 w-full md:w-80">
        <span>
          <i className="fa-solid fa-circle-question text-white text-lg cursor-pointer flex items-center justify-center h-6 w-6 mr-1 lg:text-xl" />
        </span>
        {language_selected === "en" ? (
          <a
            // href={toggleUserGuideURL}
            href="https://www.cagi.ch/en/practical-infos/private-household-employees-online-platform/#notice"
            target="__blank"
          >
            <span className="text-sm uppercase text-white cursor-pointer font-semibold">
              {t('UserGuide')}
            </span>
          </a>
        ) : (
          <a
            // href={toggleUserGuideURL}
            href="https://www.cagi.ch/fr/infos-pratiques/plateforme-emploi-domestiques-prives/#guide"
            target="__blank"
          >
            <span className="text-sm uppercase text-white cursor-pointer font-semibold">
              {t('UserGuide')}
            </span>
          </a> 
        )}

      </div>
    </div>
  </div>
  );
};

export default Footer;
