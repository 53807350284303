import React from "react";

const FieldContainer = ({ children, className }) => {
  return (
    <div
      className={`w-full flex justify-between sm_ss:flex-col sm_s:flex-col sm_s:w-full sm_ss:w-full ${className}`}
    >
      {children}
    </div>
  );
};

export default FieldContainer;
