import { createSlice } from "@reduxjs/toolkit";

export const setDefaultNumber = createSlice({
  name: "setDefaultNumber",
  initialState: {
    mobile_number: "",
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    setDefaultNumberStart: (state, action) => {
      state.pending = true;
      state.error = false;
    },
    setDefaultNumberSuccess: (state, action) => {
      state.mobile_number = action.payload;
      state.success = true;
      state.pending = false;
      state.error = false;
    },
    setDefaultNumberError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
      state.success = false;
      state.mobile_number = "";
    },
    resetDefaultNumber: (state) => {
      state.pending = false;
      state.error = false;
      state.success = false;
      state.mobile_number = "";
    },
  },
});

export const {
  setDefaultNumberError,
  setDefaultNumberStart,
  setDefaultNumberSuccess,
  resetDefaultNumber,
} = setDefaultNumber.actions;
export default setDefaultNumber.reducer;
