import React, { useEffect } from "react";
import "../App.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { suspendMyAcc } from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Container = ({
  children,
  error,
  errMsg,
  className,
  delAcc,
  success,
  role,
}) => {
  return (
    <div className={`w-full bg-signUpBG flex justify-center`}>
      <div className="max-w-4xl md:mx-3 sm_ss:w-full sm_s:w-full h-full p-6 sm_ss:p-0 sm_s:p-0 mt-5 flex flex-col justify-evenly items-center overflow-hidden relative">
        {delAcc && <DeleteAccount successVal={success} role={role} />}
        {children}
      </div>
    </div>
  );
};

const DeleteAccount = ({ successVal, role }) => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const success = useSelector((state) => state.deleteMyAccount.success);
  const [show, setShow] = React.useState(false);

  const reason1 = role === "employer" ? t("Reason1") : t("reason1");
  const reason2 = role === "employer" ? t("Reason2") : t("reason2");
  const reason3 = role === "employer" ? t("Reason3") : t("reason3");

  useEffect(() => {
    if (show) {
      MySwal.fire({
        title: (
          <span className="flex flex-col text-base">
            <p style={{ fontFamily: "museo-sans, sans-serif" }}>
              {t("DeleteAccMsg")}
            </p>
          </span>
        ),
        input: "select",
        inputOptions: {
          1: reason1,
          2: reason2,
          3: reason3,
        },
        confirmButtonText: t("Confirm"),
        inputPlaceholder: t("SuspensionReason"),
        icon: "warning",
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value !== "") {
              resolve();
            } else {
              resolve("Please select reason of suspension");
            }
          });
        },
      }).then((res) => {
        if (res.isConfirmed && res.value) {
          suspendMyAcc(dispatch, res.value);
        }
      });
      setShow(false);
    }
  }, [show, MySwal, dispatch, t, reason1, reason2, reason3]);

  useEffect(() => {
    if (success) {
      window.location.replace("/");
    }
  }, [success]);
  return (
    <>
      {successVal ? (
        ""
      ) : (
        <div className="">
          <div
            className="text-red-600 cursor-pointer underline"
            onClick={() => setShow(true)}
          >
            {t("DeleteAcc")}
          </div>
        </div>
      )}
    </>
  );
};

export default Container;
