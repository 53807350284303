import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../../constants/routeConstants/Routes";

const EmployeeRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOpen = () => navigate(appRoutes.NEWEMPLOYEMENTREQUEST);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <>
      <div className="lg:w-3/5 mx-auto px-4 md:px-0">
        <div className="flex mx-8 md:mx-0  flex-col-reverse md:flex-row justify-between">
          <div className="text-center md:text-left">
            <h5 className="font-bold">
              {t("Welcome")} {userInfo?.data?.result?.name}
            </h5>
            <p>{t("NoRequest")}</p>
          </div>
          <div className="flex justify-around md:justify-start md:flex-row-reverse mb-2">
            <button
              onClick={handleOpen}
              className="bg-blue md:h-10 text-sm lg:text-md w-64 text-white py-2 px-4 rounded-lg font-bold"
            >
              {t("NewRequest")}
            </button>
          </div>
        </div>
        <div className="bg-white rounded-xl p-4 text-center m-auto mt-16 ">
          <p className="mt-12">{t("LookingFor")}</p>
          <button
            onClick={handleOpen}
            className="bg-blue mt-4 mb-12 md:h-10 text-sm lg:text-md flex-1 text-white py-2 px-4 rounded-lg font-bold"
          >
            {t("NewRequest")}
          </button>
        </div>
      </div>
    </>
  );
};
export default EmployeeRequest;
