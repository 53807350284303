import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "UserSlice",
  initialState: {
    userInfo: {},
    pending: false,
    error: false,
    success: false,
  },
  reducers: {
    loginStart: (state) => {
      state.pending = true;
      state.error = false;
    },
    loginSuccess: (state, action) => {
      state.userInfo = action.payload;
      state.pending = false;
      state.error = false;
      state.success = true;
    },
    loginError: (state, action) => {
      state.pending = false;
      state.error = action.payload;
    },
    reset: (state) => {
      state.userInfo = {};
      state.error = false;
      state.success = false;
      state.pending = false;
    },
  },
});

export const { loginError, loginStart, loginSuccess, reset } =
  userSlice.actions;
export default userSlice.reducer;
