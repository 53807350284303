import React from "react";
import BackIcon from "../assets/images/icons/arrow-left.svg";

const BackButton = ({ className, handlechangle }) => {
  return (
    <div
      className={`h-8 w-8 flex items-center absolute justify-center ${className}`}
    >
      <img src={BackIcon} alt="backIcon" onClick={handlechangle} />
    </div>
  );
};

export default BackButton;
