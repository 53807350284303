import React from "react";

import { Route, Routes } from "react-router-dom";

import { AppRoutes } from "./routes";

import AuthMiddleware from "./middleware/authmiddleware";
import PageNotFound from "../pages/PageNotFound";

const Routers = () => {
  return (
    <>
      <Routes>
        {AppRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={<AuthMiddleware route={route} />}
            />
          );
        })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default Routers;
