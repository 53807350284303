import { useEffect, useState } from "react";
import "./privacypolicy.css"
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import i18next from "../../components/i18/i18";

export default function Privacypolicy() {
  const [state,setState] = useState(1)
  const params = useLocation()
  const language = params.pathname?.split("/")[1]
  useEffect(()=>{
  if(language){
    i18next.changeLanguage(language);
    setState((prevVal) => prevVal + 1)
  }
  },[language])
  const { t } = useTranslation();
  return (
    <div className="main-conatiner-priv-policy"> 
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%', 
          overflowX: 'hidden',
        }}
      >
        <div
          style={{
            width:  "100%",
            overflowX: 'hidden',
          }}
        >
           <div className="sub-conatiner-priv-policy " style={{marginTop:'20px'}}>
        <h1 className="priv-policy-main-head">{t('PPhead')}</h1>
        <h2 className="priv-policy-sub-head">{t('privacypolicysubhead')}</h2>
        <h2 className="priv-policy-pcondition">{t('ppsubheadtwo')}</h2>
        <span className="priv-policy-version">{t('ppverison')}</span>
      </div>
      <div className="sm_s:w-full md:w-[50%]" 
      style={{margin:'auto',padding:'15px'}}
      >
      <ul style={{ marginBottom: '60px' }}>
            <li style={{ paddingTop: '0px' }}>
              <li style={{ color: '#004993', paddingBottom: '15px' }}>
                <b>{t('ppPreamble')}</b>
              </li>
              <span >{t('ppmaincontent')}</span>
            </li>
            <li style={{ paddingTop: '15px' }}>
              <li style={{ color: '#004993', paddingBottom: '0px' }}>
                <b>{t('pppointtwo')}</b>
              </li>
              <span >
                {t('')}
                {t('')}
              </span>
            </li>
            <li style={{ paddingTop: '15px' }}>
              <li style={{ color: '#004993', paddingBottom: '0px' }}>
                <b>{t('')}</b>
              </li>
              <ul>
                {t('')}
                <li >{t('ppointtwoone')}</li>
                <li >{t('ppointtwotwo')}</li>
                <li >{t('ppointtwothree')}</li>
                <li >{t('ppointtwofour')}</li>
                <li >{t('ppointtwofive')}</li>
                {t('')}
                {t('')}
              </ul>
            </li>
            <li style={{ paddingTop: '15px' }}>
              <li style={{ color: '#004993', paddingBottom: '0px' }}>
                <b>{t('ppointthree')}</b>
              </li>
              <li style={{ color: '', paddingBottom: '0px' }}>
              {t('ppointthreeone')}
              </li>
              <ul>
                
                <li >{t('ppointthreeoneone')}</li>
                <li >{t('ppointthreeonetwo')}</li>
                <li >{t('ppointthreeonethree')}</li>
                <li >{t('ppointthreeonefour')}</li>
                <li >
                 {t('ppointthreetwo')}
                </li>
               
              </ul>
            </li>
            <li><b>{t('ppfour')}</b></li>
            <li><b>{t('ppfourA')}</b></li>
            <li>
              <li> {t('ppfourOne')}</li>
              <li> {t('ppfounsubone')}</li>
              <li> {t('ppfoursubtwo')}</li>
              <ul>
              <li><b>{t('ppfourB')}</b></li>
              <li><b>{t('Bone')}</b></li>
                <li>{t('fouroneTwo')}</li>
                <li>{t('fouronethree')}</li>
                <li>{t('fouronefour')}</li>
                <li>{t('fouronefoursubone')}</li>
                <li>{t('fouronefoursubtwo')}</li>
                <li>{t('fouronefoursubthree')}</li>
                <li>{t('fouronefoursubfour')}</li>
                <li>{t('fouronefoursubfive')}</li>
                <li>{t('fouronefoursubsix')}</li>
                <li>{t('fouronefoursubseven')}</li>
              </ul>
            </li>
            <li>
              <li>{t('fouronefive')}</li>
              <ul>
                <li>{t('fouronefivesubone')}</li>
                <li>{t('fouronefivesubtwo')}</li>
              </ul>
            </li>

            <li>{t('tablehead')}</li>
            <li>{t('fouronesix')}</li>
          </ul>
          <table className="mb-14">
            <thead>
              <tr>
                <th>{t('tablerowone')}</th>
                <th>{t('tablerowtwo')}</th>
                <th>{t('tablerowthree')}</th>
                <th>{t('tablerowfour')}</th>
                <th>{t('tablerowfive')}</th>
                <th>{t('tablerowsix')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('tabledataone')}</td>
                <td>{t('tabledatatwo')}</td>
                <td>{t('tabledatathree')}</td>
                <td>{t('tabledatafour')}</td>
                <td>{t('tabledatafive')}</td>
                <td>{t('tabledatasix')}</td>
              </tr>
              <tr>
                <td>{t('tabledatamis')}</td>
                <td>{t('tabledatatwo')}</td>
                <td>{t('tabledatamisone')}</td>
                <td>{t('tabledatafour')}</td>
                <td>{t('tabledatafive')}</td>
                <td>{t('tabledatasix')}</td>
              </tr>
              <tr>
                <td>{t('tabledatamistwo')}</td>
                <td>{t('tabledatatwo')}</td>
                <td>{t('tabledatamisthree')}</td>
                <td>{t('tabledatafour')}</td>
                <td>{t('tabledatafive')}</td>
                <td>{t('tabledatasix')}</td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>
              <b>{t('aftertablepointtwo')}</b>
            </li>
            <li>
              <li> {t('fouroneseven')}</li>
              <ul>
                <li>{t('foursevensubone')}</li>
                <li>{t('foursevensubtwo')}</li>
                <li>{t('foursevensubthree')}</li>
              </ul>
              <li>
                <b>{t('aftertablepointthree')}</b>
              </li>
              <li>{t('fouroneeight')}</li>
              <li>
                <b>{t('fouoneC')}</b>
                <ul>
                  <li><b>{t('fouronecone')}</b></li>
                </ul>
                <li>
                  <li>{t('fournine')}</li>
                  <ul>
                    <li>{t('fournineone')}</li>
                    <li>{t('fourninetwo')}</li>
                  </ul>
                </li>
                <li>{t('fouroneten')}</li>
                <li>{t('fouroneele')}</li>
                <li>
                  <li>{t('fouronetweleve')}</li>
                  <ul>
                    <li><b>{t('fouronetwesubtwo')}</b></li>
                  </ul>
                </li>
                <li>
                  <li>{t('fouronethirteen')}</li>
                  <ul>
                    <li>{t('fouronethirteenone')}</li>
                    <li>{t('fouronethirteentwo')}</li>
                    <li>{t('fouronethirteenthree')}</li>
                    <li>{t('fouronethirteenfour')}</li>
                    {/* <li><b>{t('cagilink')}</b></li> */}
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('cagilink')}</li>
                  <ul>
                    <li>{t('fiveoneone')}</li>
                    <li>{t('fiveonetwo')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('ppsix')}</li>
                  <ul>
                    <li>
                      {t('')}
                      <ul>
                        <li>{t('ppsixtwo')}</li>
                        <li>{t('ppsixone')}</li>
                      </ul>
                    </li>
                    <li>{t('')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('ppseven')}</li>
                  <ul>
                    <li> {t('ppsevensubone')}</li>
                    <li> {t('ppsevensuboneone')}</li>
                    <li> {t('ppsevenonetwo')}</li>
                    <li> {t('ppseventwo')}</li>
                    <li> {t('ppseventhree')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('ppeight')}</li>
                  <ul>
                    <li>{t('ppeightone')}</li>

                    <li>
                      <li>{t('ppeighttwo')}</li>
                      <ul>
                        <li>{t('ppeighttwoone')}</li>
                        <li>{t('ppeighttwotwo')}</li>
                        <li>{t('ppeighttwothree')}</li>
                        <li>{t('ppeighttwofour')}</li>
                        <li>{t('ppeighttwofive')}</li>
                      </ul>
                    </li>
                    <li>{t('ppeightthree')}</li>
                    <li>{t('ppeightfour')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('ppnine')}</li>
                  <ul>
                    <li>{t('ppnineone')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('ppten')}</li>
                  <ul>
                    <li>{t('pptenone')}</li>
                    <li>{t('pptentwo')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('ppeleven')}</li>
                  <ul>
                    <li>{t('ppelevenone')}</li>
                    <li>{t('ppeleventwo')}</li>
                    <li>{t('ppeleventhree')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('pptweleve')}</li>
                  <ul>
                    <li>{t('pptweleveone')}</li>
                    <li>{t('pptwelevetwo')}</li>
                    <li>{t('pptwelevethree')}</li>
                    <li>{t('pptwelevefour')}</li>
                    <li>{t('pptwelevefive')}</li>
                    <li>{t('pptwelevesix')}</li>
                    <li>{t('pptweleveseven')}</li>
                    <li>{t('pptweleveeight')}</li>
                    <li>{t('pptwelevenine')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('ppthirteen')}</li>
                  <ul>
                    <li>{t('ppthirteenone')}</li>
                    <li>{t('ppthirteentwo')}</li>
                    <li>{t('ppthirteenthree')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('ppfourteen')}</li>
                  <ul>
                    <li>{t('ppfourteenone')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading"> {t('ppfifteen')}</li>
                  <ul>
                    <li>{t('ppfifteenone')}</li>
                    <li>{t('ppfifteentwo')}</li>
                  </ul>
                </li>
                <li>
                  <li className="li-heading">{t('ppsixteen')}</li>
                  <ul>
                    <li>{t('ppsixteenone')}<a href='mailto:communication.cagi@etat.ge.ch' target="_blank" style={{color:"#004993"}} rel="noreferrer">{t('lastsitelink')}</a>  {t('afterlink')}</li>
                    <li>{t('ppsixteentwo')}</li>
                  </ul>
                </li>
              </li>
            </li>
          </ul>
        </div>
          
        </div>
      </div>
    </div>
  )
}
