import React from "react";
import { Navigate } from "react-router-dom";

const AuthMiddleware = (props) => {
  const userData = JSON.parse(localStorage?.getItem("userInfo"));
  const authState = userData?.data?.isAuthenticated;

  const token = userData?.data?.result?.access_token;

  const userRole = userData?.data?.result?.role;
  const route = props?.route;
  const authRoute = route?.meta?.authRoute;

  // console.log("userRole", userRole);
  // console.log("token", token);
  // console.log("authState", authState);
  // console.log("authRoute", authRoute);

  // ** Assign vars based on route meta

  if (
    authRoute === undefined &&
    authState === undefined &&
    token === undefined &&
    userRole === undefined
  ) {
    return <Navigate to="/" />;
  }

  if (!authRoute && authState === false && !token) {
    console.log("aaya");
    /**
     ** If user is not Logged in & route meta is undefined
     ** OR
     ** If user is not Logged in & route.meta.authRoute, !route.meta.publicRoute are undefined
     ** Then redirect user to login
     */

    return <Navigate to="/" />;
  } else if (
    !authRoute &&
    authState &&
    !route.authorizedRoles.includes(userRole)
  ) {
    // ** If route has meta and authRole and user is Logged in then redirect user to home page (DefaultRoute)
    // return <Redirect to="/" />;
    return <Navigate to="/" />;
  } else {
    // ** If none of the above render component
    return <route.component {...props} />;
  }
};

export default AuthMiddleware;
