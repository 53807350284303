import React from "react";
import PhoneInput from "react-phone-input-2";

const MobilePhoneInput = React.forwardRef(
  (
    {
      text,
      subHeading,
      sideText,
      textAfter,
      value,
      handleChange,
      showEyeButton,
      togglePassword,
      passwordShown,
      placeholder,
    },
    ref
  ) => {
    return (
      <div className="flex flex-col w-full h-24 relative sm_ss:pr-0 sm_s:pr-0">
        {textAfter === true ? (
          ""
        ) : (
          <label className="text-sectext w-max mb-2">{text}</label>
        )}
        <PhoneInput
          placeholder={placeholder}
          country={"ch"}
          inputStyle={{ height: "40px", width: "inherit" }}
          value={value}
          onChange={handleChange}
        />

        {textAfter && <label className="text-sectext mb-1">{text}</label>}
        {sideText && (
          <p className="text-textGray absolute top-11 right-4">years old</p>
        )}
        {subHeading && (
          <p className="text-textGray py-1  mb-5">Format: +00 00 000 00 00</p>
        )}
        {showEyeButton &&
          (passwordShown ? (
            <i
              onClick={togglePassword}
              className="fa-solid fa-eye-slash absolute right-4 top-11 cursor-pointer text-sectext text-sm"
            ></i>
          ) : (
            <i
              onClick={togglePassword}
              className="fa-solid fa-eye absolute right-4 top-11 cursor-pointer text-sectext text-sm"
            ></i>
          ))}
      </div>
    );
  }
);

export default MobilePhoneInput;
